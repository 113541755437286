<div class="container-fixed-header" [ngClass]="{'mobile-app-view': mobileAppView}">
  <h3>Status</h3>
</div>

<div class="container-xs-height full-height m-t-60">
  <div class="row-xs-height">
    <div class="col-xs-height col-middle">
      <ng-container *ngIf="gitEnvVariables$ | async as gitEnvVariables">
        <div class="row">
          <div class="col col-sm-12">
            <p class="no-margin">
              Version
            </p>
            <p class="small hint-text m-b-20">
              {{ gitEnvVariables.versionHash }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col col-sm-12">
            <p class="no-margin">
              Epic
            </p>
            <p class="small hint-text m-b-20">
              {{ gitEnvVariables.envName }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col col-sm-12">
            <p class="no-margin">
              Environment
            </p>
            <p class="small hint-text m-b-20">
              {{ gitEnvVariables.env }}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col col-sm-12">
            <p class="no-margin">
              Branch
            </p>
            <p class="small hint-text m-b-20">
              {{gitEnvVariables.branch}}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col col-sm-12">
            <p class="no-margin">
              Pipeline Url
            </p>
            <p class="small hint-text m-b-20">
              <a [attr.href]="gitEnvVariables.pipelineUrl" target="_blank">{{gitEnvVariables.pipelineUrl}}</a>
            </p>
          </div>
        </div>
      </ng-container>
      <div class="row">
        <div class="col col-sm-12">
          <ng-container *ngFor="let name of apiNameList">
            <kendo-expansionpanel #panel
                                  [title]="name" 
                                  (action)="onApiStatusClick(panel)">
              <ng-container *ngIf="!(isStatusLoading$ | async); else loader">
                <div *ngIf="(status$ | async) as status">
                  <ul>
                    <li *ngFor="let item of status.status | keyvalue">
                      <span class="text-break"><strong>{{item.key}}</strong>: {{item.value}}</span>
                    </li>
                    <li *ngIf="status.xoLibraries">
                      <span class="text-break"><strong>xo-libraries</strong>:</span>
                    </li>
                  </ul>
                  <div class="p-l-30 p-b-30" *ngIf="status.xoLibraries as xoLibraries">
                    <kendo-panelbar>
                      <kendo-panelbar-item  *ngFor="let xoLibrary of xoLibraries | keyvalue"
                                            [title]="xoLibrary.key">
                        <ng-template kendoPanelBarContent>
                          <ul>
                            <li *ngFor="let item of xoLibrary.value | keyvalue">
                              <span class="text-break"><strong>{{item.key}}</strong>: {{item.value}}</span>
                            </li>
                          </ul>
                        </ng-template>
                      </kendo-panelbar-item>
                    </kendo-panelbar>
                  </div>
                </div>
              </ng-container>
              <ng-template #loader>
                <spinner class="full-height"></spinner>
              </ng-template>
            </kendo-expansionpanel>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
