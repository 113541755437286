<div class="container-xs-height full-height">
  <div class="row-xs-height">
    <div class="col-xs-height col-middle">
      <div class="error-container text-center">
        <!--<h1 class="error-number">404</h1>-->
        <h2 class="semi-bold">Access denied</h2>
        <p>Your organization account should be activated first. Please contact your supervisor.</p>
      </div>
    </div>
  </div>
</div>
