import {Organization} from './organization';
import { UserAccount } from './user-account';
import { ViewPermissions } from './view-permissions';

export class User {
  firstName = '';
  lastName = '';
  viewPermissions: ViewPermissions = new ViewPermissions();
  userEulaAccepted: boolean = null;
  userId: number = null;
  emailAddress = '';
  accounts: Array<UserAccount> = [];
}