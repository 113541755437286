import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import * as containers from './core/containers';
import * as guards from './core/guards';
import { VersionHistoryPageComponent } from './core/containers/version-history-page/version-history-page.component';

const appRoutes: Routes = [
  {
    path: 'flights', loadChildren: () => import('./modules/+publish/publish.module').then(m => m.PublishModule),
    canActivate: [guards.UserAgreementGuard],
  },
  {
    path: 'fulfill-flights', loadChildren: () => import('./modules/+fulfill/fulfill.module').then(m => m.FulfillModule),
    canActivate: [guards.UserAgreementGuard],
  },
  {
    path: 'profile', loadChildren: () => import('./modules/+profile/profile.module').then(m => m.ProfileModule),
    canActivate: [guards.UserAgreementGuard]
  },
  {
    path: 'pricing', loadChildren: () => import('./modules/+pricing/pricing.module').then(m => m.PricingModule),
    canActivate: [guards.UserAgreementGuard]
  },
  { path: 'system', component: containers.StatusPageComponent },
  { path: 'access-denied', component: containers.AccessDeniedPageComponent },
  {
    path: 'fleet', loadChildren: () => import('./modules/+fleet/fleet.module').then(m => m.FleetModule),
    canActivate: [guards.UserAgreementGuard]
  },
  { path: 'not-found', component: containers.NotFoundPageComponent },
  {
    path: 'user-agreement',
    loadChildren: () => import('./modules/+agreement/agreement.module').then(m => m.AgreementModule),
    canActivate: [guards.UserAgreementAcceptedGuard]
  },
  {
    path: 'version-history', component: VersionHistoryPageComponent,
    canActivate: [guards.UserAgreementGuard]
  },
  {
    path: 'about', component: containers.LandingComponent,
    data: {
      mobileAppView: true
    }
  },
  {
    path: '', component: containers.LandingComponent
  },
  {
    path: 'login', component: containers.LandingComponent
  },
  {
    path: '**',
    component: containers.ErrorPageComponent
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
