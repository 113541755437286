import { Rating } from './rating';
import { Prospectus } from './prospectus';
import { ServiceArea } from './service-area';
import { OperatorDocument } from './operator-document';
import { AircraftCategory } from './aircraft-category';

export class Operator {
  private id: string;
  private name: string;
  private legalName: string;
  private firstName: string;
  private lastName: string;
  private title: string;
  private emailAddress: string;
  private argusRating: Rating;
  private wyvernRating: Rating;
  private isBaoRating: Rating;
  private levelFlightRating: Rating;
  private prospectus: Prospectus;
  private serviceAreas: Array<ServiceArea>;
  private operatorDocuments: Array<OperatorDocument>;
  private numberOfUsers: number;
  private phoneNumber: string;
  private cancellationTerms: string;
  private notes: string;
  private externalNote: string;
  private aircraftCategories: Array<AircraftCategory>;
  private draftCancellationTerms: string;
  private quoteRequestEmail: string;
  private aircraftsRegistrationCountryCode: string;
  private addressLine1: string;
  private addressLine2: string;
  private stateProvince: string;
  private city: string;
  private postalCode: string;

  getOperatorId(): string  {
    return this.id;
  }

  setOperatorId(id: string): Operator  {
    this.id = id;

    return this;
  }

  getNumberOfUsers(): number {
    return this.numberOfUsers;
  }

  setNumberOfUsers(value: number): Operator {
    this.numberOfUsers = value;
    return this;
  }

  getName(): string {
    return this.name;
  }

  setName(value: string): Operator {
    this.name = value;
    return this;
  }

  getLegalName(): string {
    return this.legalName;
  }

  setLegalName(value: string): Operator {
    this.legalName = value;
    return this;
  }

  getFirstName(): string {
    return this.firstName;
  }

  setFirstName(value: string): Operator {
    this.firstName = value;
    return this;
  }

  getLastName(): string {
    return this.lastName;
  }

  setLastName(value: string): Operator {
    this.lastName = value;
    return this;
  }

  getTitle(): string {
    return this.title;
  }

  setTitle(value: string): Operator {
    this.title = value;
    return this;
  }

  getEmailAddress(): string {
    return this.emailAddress;
  }

  setEmailAddress(value: string): Operator {
    this.emailAddress = value;
    return this;
  }

  getArgusRating(): Rating {
    return this.argusRating;
  }

  setArgusRating(value: Rating): Operator {
    this.argusRating = value;
    return this;
  }

  getWyvernRating(): Rating {
    return this.wyvernRating;
  }

  setWyvernRating(value: Rating): Operator {
    this.wyvernRating = value;
    return this;
  }

  getIsBaoRating(): Rating {
    return this.isBaoRating;
  }

  setIsBaoRating(value: Rating): Operator {
    this.isBaoRating = value;
    return this;
  }

  getLevelFlightRating(): Rating {
    return this.levelFlightRating;
  }

  setLevelFlightRating(value: Rating): Operator {
    this.levelFlightRating = value;
    return this;
  }

  getProspectus(): Prospectus {
    return this.prospectus;
  }

  setProspectus(value: Prospectus): Operator {
    this.prospectus = value;
    return this;
  }

  getServiceAreas(): Array<any> {
    return this.serviceAreas;
  }

  setServiceAreas(value: Array<any>): Operator {
    this.serviceAreas = value;
    return this;
  }

  getOperatorDocuments(): Array<OperatorDocument> {
    return this.operatorDocuments;
  }

  setOperatorDocuments(value: Array<OperatorDocument>): Operator {
    this.operatorDocuments = value;
    return this;
  }

  getPhoneNumber(): string {
    return this.phoneNumber;
  }

  setPhoneNumber(value: any): Operator {
    this.phoneNumber = value;
    return this;
  }

  setCancellationTerms(value: string): Operator {
    this.cancellationTerms = value;
    return this;
  }

  getCancellationTerms(): string {
    return this.cancellationTerms;
  }

  setNotes(value: string): Operator {
    this.notes = value;
    return this;
  }

  getNotes(): string {
    return this.notes;
  }

  setExtrenalNote(value: string): Operator {
    this.externalNote = value;
    return this;
  }

  getExtrenalNote(): string {
    return this.externalNote;
  }

  getAircraftCategories(): Array<AircraftCategory> {
    return this.aircraftCategories;
  }

  setAircraftCategories(value: Array<AircraftCategory>): Operator {
    this.aircraftCategories = value;
    return this;
  }

  setDraftCancellationTerms(value: string): Operator {
    this.draftCancellationTerms = value === undefined ? null : value;
    return this;
  }

  getDraftCancellationTerms(): string {
    return this.draftCancellationTerms;
  }

  getQuoteRequestEmail(): string {
    return this.quoteRequestEmail;
  }

  setQuoteRequestEmail(value: string): Operator {
    this.quoteRequestEmail = value;
    return this;
  }

  getAircraftsRegistrationCountryCode(): string {
    return this.aircraftsRegistrationCountryCode;
  }

  setAircraftsRegistrationCountryCode(value: string): Operator {  
    this.aircraftsRegistrationCountryCode = value;
    return this;
  }

  getAddressLine1(): string {
    return this.addressLine1;
  }

  setAddressLine1(value: string): Operator {
    this.addressLine1 = value;
    return this;
  }

  getAddressLine2(): string {
    return this.addressLine2;
  }

  setAddressLine2(value: string): Operator {
    this.addressLine2 = value;
    return this;
  }

  getStateProvince(): string {
    return this.stateProvince;
  }

  setStateProvince(value: string): Operator {
    this.stateProvince = value;
    return this;
  }

  getCity(): string {
    return this.city;
  }

  setCity(value: string): Operator {
    this.city = value;
    return this;
  }

  getPostalCode(): string {
    return this.postalCode;
  }

  setPostalCode(value: string): Operator {
    this.postalCode = value;
    return this;
  }
}
