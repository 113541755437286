<!-- START HEADER -->
<div class="header p-r-0 p-l-0">
  <div class="container-fluid">
    <div class="page-content-wrapper">
      <div class="header-md-height">
        <!-- LEFT SIDE -->
        <div class="pull-left">
          <h3>{{title}}</h3>
        </div>



        <!-- START User Info-->

        <div class="pull-right toggle-sidebar-btn"
             style="z-index: 2; margin-top: 10px;">
          <!-- START ACTION BAR -->
          <a (click)="toggleSidebar()"
             class="m-t-10 btn-link toggle-sidebar padding-5"
             href="javascript:void(0)">
            <span class="pg pg-menu"></span>
          </a>
          <!-- END ACTION BAR -->
        </div>


        <div class="pull-right"
             style="z-index: 2">
          <div class="dropdown pull-right"
               dropdown>
            <button class="profile-dropdown-toggle"
                    type="button"
                    dropdownToggle
                    id="profileDropdown">
              <svg xmlns="http://www.w3.org/2000/svg"
                   width="40"
                   height="40"
                   viewBox="0 0 40 40">
                <path d="M20,20.87a6,6,0,0,1-6.09-5.82q0-.13,0-.27V12.17a6.09,6.09,0,0,1,12.18,0v2.61a6,6,0,0,1-5.82,6.09Zm0-13a4.21,4.21,0,0,0-4.35,4.06q0,.14,0,.29v2.61a4.35,4.35,0,0,0,8.7,0V12.17a4.21,4.21,0,0,0-4.06-4.35ZM31.3,33H29.57V28.7c0-3.07-6.21-5.22-9.57-5.22s-9.57,2.15-9.57,5.22V33H8.7V28.7c0-4.57,7.36-7,11.3-7s11.3,2.39,11.3,7V33ZM20,40A20,20,0,1,1,40,20,20,20,0,0,1,20,40ZM20,1.74A18.26,18.26,0,1,0,38.26,20h0A18.28,18.28,0,0,0,20,1.74Z"
                      fill="#d7d8de" /></svg>
              <div class="bold m-t-5">
                {{ userAccount?.operator?.name }}
              </div>
              <!--<span class="thumbnail-wrapper d32 circular inline m-t-5">
                <img src="assets/img/profiles/profile-picture-default.png"
                     alt=""
                     data-src="assets/img/profiles/profile-picture-default.png"
                     ui-jq="unveil"
                     width="32"
                     height="32">
              </span>-->
            </button>
            <ul class="dropdown-menu dropdown-menu-right p-b-0 p-t-0"
                *dropdownMenu
                role="menu">
                <ng-container *ngIf="user.accounts.length > 1">
                  <li role="menuitem">
                    <div class="radio radio-primary account-radio-group col">
                      <ng-container *ngFor="let account of user.accounts; index as i">
                        <div class="row account-radio">
                          <input
                            [id]="'account-' + i"
                            type="radio"
                            name="account"
                            (change)="accountChange.emit(account)"
                            [checked]="account?.operator?.id === userAccount.operator.id"
                          />
                          <label class="account-label" [for]="'account-' + i">{{account.operator.name}}</label>
                        </div>
                      </ng-container>
                    </div>
                  </li>
                  <li class="divider dropdown-divider m-b-0"></li>
                </ng-container>
              <li role="menuitem">
                <div class="dropdown-item" routerLink="/profile" (click)="profileClicked.emit()">
                  <h5>Profile</h5>
                </div>
              </li>
              <li class="divider dropdown-divider m-b-0"></li>
              <li role="menuitem">
                <div class="dropdown-item" routerLink="/pricing" (click)="pricingClicked.emit()">
                  <h5 >Pricing</h5>
                </div>
              </li>
              <li class="divider dropdown-divider m-b-0"></li>
              <li role="menuitem"
                  class="bg-master-lighter">
                <a class="clearfix dropdown-item"
                   (click)="signOut()">
                  <h6 class="pull-left">Logout</h6>
                  <span class="pull-right"
                        style="margin-top: 13px">
                    <i class="fal fa-power-off"></i>
                  </span>
                </a>
              </li>
            </ul>
          </div>
        </div>


        <!-- END User Info-->
        <!-- <div class=" pull-right"> -->


        <!-- START User Info-->
        <!--<div class="visible-lg visible-md m-t-10">-->
        <!--<div class="pull-left p-r-10 p-t-10 fs-16 font-heading">-->
        <!--</div>-->
        <!--<div class="dropdown pull-right">-->
        <!--<button class="profile-dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true"-->
        <!--aria-expanded="false">-->
        <!--<span class="thumbnail-wrapper d32 circular inline m-t-5">-->
        <!--<a ui-jq="unveil" class="glyphicon glyphicon-th fs-16"></a>-->
        <!--</span>-->
        <!--</button>-->
        <!--<div class="dropdown-menu profile-dropdown" role="menu">-->
        <!--<div class="container p-t-15 p-b-15" style="width: 280px; height: 280px;">-->
        <!--<div class="row">-->
        <!--<div class="col-xs-4">-->
        <!--<button class="btn btn-default no-padding" style="width: 70px; height: 70px;">-->
        <!--<div><i class="fa fa-thumbs-up" style="font-size: 32px;"></i></div>-->
        <!--<div><span class="fs-12">JetWings</span></div>-->
        <!--</button>-->
        <!--</div>-->
        <!--<div class="col-xs-4">-->
        <!--<button class="btn btn-default no-padding" style="width: 70px; height: 70px;">-->
        <!--<div><i class="fa fa-heartbeat" style="font-size: 32px;"></i></div>-->
        <!--<div><span class="fs-12">JetPulse</span></div>-->
        <!--</button>-->
        <!--</div>-->
        <!--<div class="col-xs-4">-->

        <!--</div>-->
        <!--</div>-->
        <!--<div class="row">-->
        <!--<div class="col-xs-4">-->
        <!--</div>-->
        <!--<div class="col-xs-4">-->
        <!--</div>-->
        <!--<div class="col-xs-4">-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="row">-->
        <!--<div class="col-xs-4">-->
        <!--</div>-->
        <!--<div class="col-xs-4">-->
        <!--</div>-->
        <!--<div class="col-xs-4">-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!--</div>-->
        <!-- END User Info-->
        <!-- </div> -->
      </div>
    </div>
  </div>
</div>
<!-- END HEADER -->
