<div class="jumbotron  no-margin"
     data-pages="parallax">
  <div class="container-fluid container-fixed-lg sm-p-l-20 sm-p-r-20">
    <div class="inner"
         style="transform: translateY(0px); opacity: 1;">
      <h5>Version History</h5>
    </div>
  </div>
</div>
<div class="container-fluid container-fixed-lg">
  <ul class="breadcrumb">
    <li class="breadcrumb-item">
      <a>Home</a>
    </li>
    <li class="breadcrumb-item active">
      <a>Version History</a>
    </li>
  </ul>
</div>
<div class="container-fluid container-fixed-lg">
  <div class="row">
    <div class="col-sm-12">
      <version-history [releaseList]="versions"></version-history>
    </div>
  </div>
</div>
