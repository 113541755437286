import * as fromOfferList from './offer-list';
import * as fromAircraft from './aircraft';
import * as fromFbo from './fbo-ff';
import * as fromCurrencyRates from './currency-rates';
import * as fromDeclineOfferList from './decline-offer-list';
import { ActionReducerMap, combineReducers, ActionReducer } from '@ngrx/store';
import { createSelector } from 'reselect';

export interface State {
  offerList: fromOfferList.State;
  aircraftList: fromAircraft.State;
  fbo: fromFbo.State;
  currencyRates: fromCurrencyRates.State;
  declineOfferList: fromDeclineOfferList.State;
}

export const reducers: ActionReducerMap<State> = {
  offerList: fromOfferList.reducer,
  aircraftList: fromAircraft.reducer,
  fbo: fromFbo.reducer,
  currencyRates: fromCurrencyRates.reducer,
  declineOfferList: fromDeclineOfferList.reducer,
};

const combinedReducer: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any): State {
  return combinedReducer(state, action);
}

export const getAircraftState = (state: any) => state.fulfill.aircraftList;
export const getAircraftListLoaded = createSelector(getAircraftState, fromAircraft.getLoaded);
export const getAircraftListLoading = createSelector(getAircraftState, fromAircraft.getLoading);
export const getAircraftList = createSelector(getAircraftState, fromAircraft.getItems);


export const getOfferListState = (state: any) => state.fulfill.offerList;
export const getOfferListSkip = createSelector(getOfferListState, fromOfferList.getSkip);
export const getOfferListIsLoaded = createSelector(getOfferListState, fromOfferList.getIsLoaded);
export const getOfferListIsLoading = createSelector(getOfferListState, fromOfferList.getIsLoading);
export const getOfferListLookupCriteria = createSelector(getOfferListState,
  fromOfferList.getCriteria);
export const getOfferListItems = createSelector(getOfferListState, fromOfferList.getItems);
export const getOfferListItemsPerPage = createSelector(getOfferListState, fromOfferList.getItemsPerPage);
export const getOfferListItemsTotalCount = createSelector(getOfferListState,
  fromOfferList.getItemsTotalCount);
export const getOfferListSelectedPurchaseOffer = createSelector(getOfferListState, fromOfferList.getSelectedPurchaseOffer);
export const getSelectedOfferIsLoading = createSelector(getOfferListState, fromOfferList.getIsSelectedPurchaseOfferLoading);
export const getSelectedOfferIsLoaded = createSelector(getOfferListState, fromOfferList.getIsSelectedPurchaseOfferLoaded);
export const getAcceptOfferDialogIsVisible = createSelector(getOfferListState, fromOfferList.getAcceptOfferDialogVisible);
export const getYourOffer = createSelector(getOfferListState, fromOfferList.getYourOffer);
export const getLegFbos = createSelector(getOfferListState, fromOfferList.getLegs);

export const getFboState = (state: any) => state.fulfill.fbo;
export const getGetFboMap = createSelector(getFboState, fromFbo.getFboMap);
export const getFboLoading = createSelector(getFboState, fromFbo.getFboLoading);

export const getCurrencyRatesState = (state: any) => state.fulfill.currencyRates;
export const getCurrencyRatesLoaded = createSelector(getCurrencyRatesState, fromCurrencyRates.getLoaded);
export const getCurrencyRatesLoading = createSelector(getCurrencyRatesState, fromCurrencyRates.getLoading);
export const getCurrencyRates = createSelector(getCurrencyRatesState, fromCurrencyRates.getItems);

export const getDeclineOfferListState = (state: any) => state.fulfill.declineOfferList;
export const getDeclineOfferListIsLoaded = createSelector(getDeclineOfferListState, fromDeclineOfferList.getIsLoaded);
export const getDeclineOfferListIsLoading = createSelector(getDeclineOfferListState, fromDeclineOfferList.getIsLoading);
export const getDeclineOfferListLookupCriteria = createSelector(getDeclineOfferListState,
  fromDeclineOfferList.getCriteria);
export const getDeclineOfferListItems = createSelector(getDeclineOfferListState, fromDeclineOfferList.getItems);