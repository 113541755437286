import { PurchaseOfferStatusEnum } from '../../../../shared/graphql-types';

export class StatusItem {
  name: string;
  value: Array<string>;
}

export const statusList: Array<StatusItem> = [
  {
    name: 'Active',
    value: [PurchaseOfferStatusEnum.Active, PurchaseOfferStatusEnum.Bid, PurchaseOfferStatusEnum.BidApproved]
  },
  {
    name: 'Accepted',
    value: [PurchaseOfferStatusEnum.Accepted]
  },
  {
    name: 'Expired',
    value: [PurchaseOfferStatusEnum.Expired]
  },
  {
    name: 'All',
    value: [
      PurchaseOfferStatusEnum.Active,
      PurchaseOfferStatusEnum.Cancelled,
      PurchaseOfferStatusEnum.Accepted,
      PurchaseOfferStatusEnum.Declined,
      PurchaseOfferStatusEnum.Expired,
      PurchaseOfferStatusEnum.Bid,
      PurchaseOfferStatusEnum.BidApproved,
    ]
  }
];
