import { Pipe, PipeTransform } from '@angular/core';
import { ViewModeEnum } from '../models';

@Pipe({name: 'viewMode'})
export class ViewModePipe implements PipeTransform {
  transform(viewMode: ViewModeEnum): string {
    switch (viewMode) {
      case null:
      case ViewModeEnum.TABLE_VIEW:
        return 'Table View';
      case ViewModeEnum.CARD_VIEW:
        return 'Card View';
      default:
        return (`${viewMode} - Not Supported`);
    }
  }
}
