import * as actions from '../actions/airport';
import * as models from '../models';

export interface State {
  airportOptions: Array<models.Airport>;
  airportOptionsLoading: boolean;
}

const initialState: State = {
  airportOptions: [],
  airportOptionsLoading: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {

    case actions.GET_AIRPORT_LIST: {
      return {
        ...state,
        airportOptionsLoading: true
      };
    }

    case actions.GET_AIRPORT_LIST_SUCCESS: {
      return {
        ...state,
        airportOptions: action.payload,
        airportOptionsLoading: false
      };
    }

    case actions.GET_AIRPORT_LIST_FAIL: {
      return {
        ...state,
        airportOptionsLoading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getAirportOptions = (state: State) => state.airportOptions;
export const getAirportOptionsLoading = (state: State) => state.airportOptionsLoading;
