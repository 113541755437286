import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ViewModeEnum } from '../../models';

@Component({
  selector: 'view-switch',
  templateUrl: './view-switch.component.html',
  styleUrls: ['./view-switch.component.scss']
})
export class ViewSwitchComponent {
  @Input() activeViewMode: ViewModeEnum;
  @Output() viewModeChange = new EventEmitter<ViewModeEnum>();
  viewModeEnum = ViewModeEnum;

  setViewMode(viewMode: ViewModeEnum): void {
    this.viewModeChange.emit(viewMode);
  }
}
