import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as reducers from '../reducers';

@Injectable()
export class UserAgreementAcceptedGuard implements CanActivate {

  constructor(public router: Router, public store$: Store<any>) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const returnUrl = route.queryParams['return-url'];

    if (!returnUrl) {
        return of(true);
    }

    return this.store$.pipe(
        select(reducers.getUserUser),
        withLatestFrom(this.store$.pipe(select(reducers.getUserAccount))),
        take(1),
        switchMap(([user, account]) => {
          if (
            user?.userEulaAccepted && 
            account?.operator?.eulaAccepted && 
            account?.operator?.operatorAgreement
          ) {
            this.router.navigate([returnUrl]);
            return of(false);
          }
          return of(true);
        })
      );

  }
}
