<div class="row justify-content-end m-b-10 m-t-10 m-r-0">
  <button type="button"
          class="view-btn"
          [tooltip]="viewModeEnum.CARD_VIEW | viewMode"
          container="body"
          [ngClass]="{active: activeViewMode === viewModeEnum.CARD_VIEW}"
          (click)="setViewMode(viewModeEnum.CARD_VIEW)">
    <i class="fal fa-list-alt"></i></button>

  <button type="button"
          class="view-btn"
          [tooltip]="viewModeEnum.TABLE_VIEW | viewMode"
          container="body"
          [ngClass]="{active: activeViewMode === viewModeEnum.TABLE_VIEW}"
          (click)="setViewMode(viewModeEnum.TABLE_VIEW)">
    <i class="fal fa-columns"></i></button>
</div>
