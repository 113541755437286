import * as fromAircraftList from './aircraft-list';
import { ActionReducer, ActionReducerMap, combineReducers, createSelector } from '@ngrx/store';

export interface State {
  aircraftList: fromAircraftList.State;
}

const reducers: ActionReducerMap<State> = {
  aircraftList: fromAircraftList.reducer,
};

const combinedReducers: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any) {
  return combinedReducers(state, action);
}

export const getAircraftListState = state => state.pricing.aircraftList;
export const getAircraftListItems = createSelector(getAircraftListState, fromAircraftList.getItems);
export const getAircraftListLoaded = createSelector(getAircraftListState, fromAircraftList.getIsLoaded);
export const getAircraftListLoading = createSelector(getAircraftListState, fromAircraftList.getIsLoading);
export const getAircraftListSkip = createSelector(getAircraftListState, fromAircraftList.getSkip);
export const getAircraftListItemsPerPage = createSelector(getAircraftListState, fromAircraftList.getItemsPerPage);
export const getAircraftListItemsTotalCount = createSelector(getAircraftListState, fromAircraftList.getItemsTotalCount)
export const getAircraftListSelectedAircraft = createSelector(getAircraftListState, fromAircraftList.getSelectedAircraft);
export const getAircraftListAircraftChanges = createSelector(getAircraftListState, fromAircraftList.getAircraftChanges);
