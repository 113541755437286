import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';

import * as actions from '../actions/user';
import * as dataContracts from '../services/user/contracts/user';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { IUserService, USER_SERVICE_TOKEN } from '../services/user/contracts/user';
import { 
  ANALYTICS_SERVICE_TOKEN, 
  IAnalyticsService, 
  LOCAL_STORAGE_SERVICE_TOKEN,
  ILocalStorage 
} from '../../shared/services';
import * as models from '../models';

@Injectable()
export class UserEffects {

  loadUser$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.LoadAction>(actions.LOAD),
      switchMap(() => {

        const request = new dataContracts.GetUserRequest();

        return this.userService.getUser(request)
          .pipe(
            map(svcResp => {
              const user = svcResp.entity;
              const selectedAccountId = this.storageService.getItem('selectedAccountId')
              let account: models.UserAccount;
              if (selectedAccountId) {
                account = user.accounts.find((account) => account.operator?.id === selectedAccountId);
              } 

              if (!account && user.accounts?.length) {
                account = user.accounts[0];
              }
              
              this.analyticsService.logUser({...this.getUserProperties(svcResp.entity, account)});
              return new actions.LoadSuccessAction({ user, account });
            })
          );
      })
    ));

  signOut$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.SignOutAction>(actions.SIGN_OUT),
      switchMap(() => {
        return this.userService.signOut(new dataContracts.SignOutRequest())
          .pipe(
            map(svcResp => {
              location.reload();
              return { type: 'NO_ACTION' };
            })
          );
      })
    ));

    logUserActivity$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.LogUserActivity>(actions.LOG_USER_ACTIVITY),
      map(action => action.payload),
      switchMap((payload) => {
        return this.userService.logUserActivity(payload)
          .pipe(
            map(svcResp => {
              return { type: 'NO_ACTION' };
            })
          );
      })
    ));


  getUserProperties(user: models.User, account: models.UserAccount): any {
    const properties = {} as any;
    properties.UserID = user.userId;
    properties.OperatorID = account.operator.id;
    properties.OrganizationID = account.operator.id;
    properties.User = `${user.firstName} ${user.lastName}`;
    properties.Operator = account.operator.legalName;
    return properties;
  }

  constructor(private actions$: Actions,
              @Inject(USER_SERVICE_TOKEN) private userService: IUserService,
              @Inject(LOCAL_STORAGE_SERVICE_TOKEN) private storageService: ILocalStorage,
              @Inject(ANALYTICS_SERVICE_TOKEN) private analyticsService: IAnalyticsService) {
  }
}
