import { Action } from "@ngrx/store";
import * as viewModels from '../../view/view-models';
import * as models from '../../domain/models';

export const RESET = '[Offers.DeclineOfferList] Reset';
export const LOAD = '[Offers.DeclineOfferList] Load';
export const LOAD_SUCCESS = '[DeclineOffers.OfferList] Load Success';
export const LOAD_FAIL = '[DeclineOffers.OfferList] Load Fail';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class LoadPayload {
  offerIds: Array<string>;
  criteria: viewModels.OffersLookupCriteria;

  constructor(offerIds: Array<string>, criteria?: viewModels.OffersLookupCriteria) {
    this.criteria = new viewModels.OffersLookupCriteria();
    this.offerIds = offerIds;

    if (criteria) {
      this.criteria = criteria;
    }
  }
}

export class LoadAction implements Action {
  readonly type = LOAD;

  constructor(public payload: LoadPayload) {
  }
}

export class LoadSuccessPayload {
  skip: number;
  data: Array<models.OperatorPurchaseOffer>;
  count: number;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {
  }
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions =
  ResetAction
  | LoadAction
  | LoadSuccessAction
  | LoadFailAction;