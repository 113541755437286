import {gql, Apollo} from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as contracts from './contracts/user';
import * as models from '../../models';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { GetCurrentUserQuery } from '../../../shared/graphql-types';
import { CookieService } from 'ngx-cookie';
import { of } from 'rxjs';
import { UserMapper } from './mappers/user-mapper';


@Injectable()
export class UserService implements contracts.IUserService {
  constructor(private apollo: Apollo,
              private cookieService: CookieService) {
  }

  getUser(request: contracts.GetUserRequest): Observable<contracts.GetUserResponse> {

    return this.apollo.query<GetCurrentUserQuery>({
      query: gql(`
      query getCurrentUser {
        getCurrentUser {
          firstName,
          lastName,
          userEulaAccepted,
          accounts {
            operator {
              operatorUuid
              operatorAgreement
              legalName
              name
              eulaAccepted
              prospectus {
                approved
              }
            }
            superUser
          }
          userId
          emailAddress
        }
      }`)
    })
      .pipe(
        map(svcResp => {
          const response = new contracts.GetUserResponse();
          if (!svcResp.data) {
            return response;
          }
          response.entity = UserMapper.Instance().userDtoToUser(svcResp.data.getCurrentUser);
          return response;
        })
      );
  }

  signOut(request: contracts.SignOutRequest): Observable<contracts.SignOutResponse> {

    const host = location.host.split('.');
    if (host.length > 2) {
      let i = 2;
      while (i < host.length) {
        const subdomain = '.' + location.host.split('.').splice(-i, i).join('.');
        i++;
        this.cookieService.removeAll({domain: subdomain});
      }
    }
    this.cookieService.removeAll();
    return of(new contracts.SignOutResponse());
  }

  logUserActivity(request: models.UserActionsEnum): Observable<contracts.LogUserActivityResponse> {
    return this.apollo.mutate<GetCurrentUserQuery>({
      mutation: gql(`mutation logUserActivity($action: UserActivityAction!) {
        logUserActivity(action: $action) {
          action
        }
      }`),
      variables: {
        action: request
      }
    }).pipe(
      map((svcResp) => {
        return new contracts.LogUserActivityResponse();
      })
    )
  }
}
