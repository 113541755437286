import { EnvironmentEnum, IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
  bearerToken: null,
  environment: EnvironmentEnum.PRODUCTION,
  environmentName: 'PROD',
  version: '2.1.0',
  testMode: null,

  identityProviderUrl: 'https://identity-op.jetsmarter.com',
  operatorApiEndpoint: 'https://operator.mp.prod.jetsmarter.io',
  sourcingServiceEndpoint: 'https://sourcing.mp.prod.jetsmarter.io',
  aircraftServiceEndpoint: 'https://aircraft.mp.prod.jetsmarter.io',
  bottomPriceServiceEndpoint: 'https://api.jetsmarter.com/charter7',
  consoleSiteUrl: 'https://console.vista.studio',
  jetStudioSiteUrl: 'https://requests.vista.studio',
  routeManagementServiceEndpoint: 'https://api.jetsmarter.io/route-mgmt',
};
