import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const LOAD_FBO = '[Fulfill Flights] Load Fbo';
export const LOAD_FBO_FAILURE = '[Fulfill Flights] Load Fbo Failure';
export const LOAD_FBO_SUCCESS = '[Fulfill Flights] Load Fbo Success';

export class LoadFboAction implements Action {
  readonly type = LOAD_FBO;

  constructor(public payload: string) {
  }
}

export class LoadFboFailurePayload {
  constructor(public airportCode: string) {
  }
}

export class LoadFboFailureAction implements Action {
  readonly type = LOAD_FBO_FAILURE;

  constructor(public payload: LoadFboFailurePayload) {
  }
}

export class LoadFboSuccessPayload {
  airportCode: string;
  fboList: Array<models.Fbo>;
}

export class LoadFboSuccessAction implements Action {
  readonly type = LOAD_FBO_SUCCESS;

  constructor(public payload: LoadFboSuccessPayload) {
  }
}

export type Actions =
  LoadFboAction
  | LoadFboFailureAction
  | LoadFboSuccessAction;
