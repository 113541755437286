import { Action } from '@ngrx/store';
import { Aircraft } from '../../domain/models';

export const LOAD_AIRCRAFT_DETAILS = '[Fleet Aircraft Details] Load Details';
export const LOAD_AIRCRAFT_DETAILS_SUCCESS = '[Fleet Aircraft Details] Load Details Success';
export const LOAD_AIRCRAFT_DETAILS_FAIL = '[Fleet Aircraft Details] Load Details Failure';
export const RESET_AIRCRAFT_DETAILS = '[Fleet Aircraft Details] Reset Info';
export const SAVE_AIRCRAFT = '[Fleet Aircraft Details] Save';
export const SAVE_AIRCRAFT_SUCCESS = '[Fleet Aircraft Details] Save Success';
export const SAVE_AIRCRAFT_FAIL = '[Fleet Aircraft Details] Save Fail';
export const SET_EDIT_MODE = '[Fleet Aircraft Details] Set Edit Mode';
export const SET_AIRCRAFT = '[Fleet Aircraft Details] Set';
export const SET_EDIT_ACTION_NEW = '[Fleet Aircraft Details] Set Edit Action New';


export class LoadAircraftPayload {
  constructor(public aircraftId: number) {}
}
export class LoadAircraftDetailsAction implements Action {
  readonly type = LOAD_AIRCRAFT_DETAILS;

  constructor(public payload: LoadAircraftPayload) {
  }
}

export class LoadAircraftDetailsSuccessAction implements Action {
  readonly type = LOAD_AIRCRAFT_DETAILS_SUCCESS;

  constructor(public payload: Aircraft) {
  }
}

export class LoadAircraftDetailsFailAction implements Action {
  readonly type = LOAD_AIRCRAFT_DETAILS_FAIL;
}

export class ResetAircraftDetailsAction implements Action {
  readonly type = RESET_AIRCRAFT_DETAILS;
}


export class SaveAircraftAction implements Action {
  readonly type = SAVE_AIRCRAFT;
}

export class SaveAircraftSuccessAction implements Action {
  readonly type = SAVE_AIRCRAFT_SUCCESS;
}

export class SaveAircraftFailAction implements Action {
  readonly type = SAVE_AIRCRAFT_FAIL;
}

export class SetEditModeAction implements Action {
  readonly type = SET_EDIT_MODE;

  constructor(public payload: boolean) {}
}

export class SetAircraftPayload {
  constructor(public item: Aircraft) {}
}

export class SetAircraftAction implements Action {
  readonly type = SET_AIRCRAFT;

  constructor(public payload: SetAircraftPayload) {}
}

export class SetEditActionNewAction implements Action {
  readonly type = SET_EDIT_ACTION_NEW;

  constructor(public payload: boolean) {}
}

export type Actions =
  LoadAircraftDetailsAction
  | LoadAircraftDetailsSuccessAction
  | LoadAircraftDetailsFailAction
  | ResetAircraftDetailsAction
  | SaveAircraftAction
  | SaveAircraftSuccessAction
  | SaveAircraftFailAction
  | SetEditModeAction
  | SetAircraftAction
  | SetEditActionNewAction;
