<div class="container-fixed-header" [ngClass]="{'mobile-app-view': mobileAppView}">
  <ul class="list-inline">
    <li><a (click)="moveTo('fulfillFlight')"
           href="javascript:void(0)">Fulfill Flights</a></li>
    <li><a (click)="moveTo('publishFlight')"
           href="javascript:void(0)">Publish Flights</a></li>
  </ul>
</div>

<div class="landing-content bg-white">
  <div class="cover-photo"
       style="background: url(assets/img/landing-header-2x.jpg) no-repeat 50% bottom/cover;"></div>

  <div class="container">
    <!-- START FULFILL FLIGHT ROW -->
    <div class="row">
      <div class="col-lg-6 col-sm-12 d-flex flex-column">
        <img class="fulfill-flights"
             src="assets/img/fulfill-flights.png"
             alt="Fulfill Flights">
      </div>
      <div class="col-lg-6 col-sm-12 d-flex flex-column fulfill-flights">
        <h3>Fulfill Flights</h3>
        <p id="fulfillFlight"
           class="m-b-100">
          At XO, we are dedicated to eliminating all of the hassles of booking a private jet. We are committed to
          bringing efficiency and value to both our operators and customers. In keeping with this philosophy, our
          Fulfill Flights feature allows you to automatically receive confirmed trip requests directly from our
          customers or submit offers digitally for flights requested by vetted and verified private
          charter customers.
        </p>
      </div>
    </div>
    <!-- END FULFILL FLIGHT ROW -->

    <!-- START PUBLISH FLIGHT ROW -->
    <div class="row">
      <div class="col-lg-6 col-sm-12 d-flex flex-column ">
        <h3>Publish Flights</h3>
        <p id="publishFlight">
          Use our Publish Flights feature to instantly advertise and sell whole charter flights or individual seats in
          the XO Marketplace. You may enable route flexibility on charter flights to serve more markets and render in
          more search results for direct bookings. Seat pricing on shared flights can be set as fixed, allowing you to
          set the price or dynamic, allowing XO to increase the seat price to increase revenue for both parties.
        </p>
        <p class="smaller disclaimer">
          XO does not guarantee that any seat or flight will be sold. Operator will receive payment only in the event a
          flight or seat has been purchased. Once a flight or seat has been booked, Operator cannot cancel or change
          flight route, departure time or the aircraft make and model.
        </p>
      </div>
      <div class="col-lg-6 col-sm-12 d-flex flex-column">
        <img class="publish-flights"
             src="assets/img/publish-flights.png"
             alt="Publish Flights">
      </div>
    </div>
    <!-- END PUBLISH FLIGHT ROW -->

  </div>
</div>
