import { Action } from '@ngrx/store';
import * as models from '../../domain/models';

export const RESET = '[Pricing Aircraft List] Reset';
export const LOAD_AIRCRAFT_LIST = '[Pricing Aircraft List] Load Aircraft List';
export const LOAD_AIRCRAFT_LIST_SUCCESS = '[Pricing Aircraft List] Load Aircraft List Success';
export const LOAD_AIRCRAFT_LIST_FAIL = '[Pricing Aircraft List] Load Aircraft List Fail';
export const SAVE_AIRCRAFT_LIST = '[Pricing Aircraft List] Save Aircraft List';
export const SAVE_AIRCRAFT_LIST_SUCCESS = '[Pricing Aircraft List] Save Aircraft List Success';
export const SAVE_AIRCRAFT_LIST_FAIL = '[Pricing Aircraft List] Save Aircraft List Fail';
export const SET = '[Pricing Aircraft List] Set';
export const CHANGE_PAGE = '[Pricing Aircraft List] Change Page';
export const AIRCRAFT_SELECTION_CHANGE = '[Pricing Aircraft List] Aircraft Selection Change'


export class ResetAction implements Action {
  readonly type = RESET;
}

export class SetPayload {
  constructor(public item: Partial<models.Aircraft>) {}
}

export class SetAction implements Action {
  readonly type = SET;

  constructor(public payload: SetPayload) {}
}

export class SaveAircraftListAction implements Action {
  readonly type = SAVE_AIRCRAFT_LIST;
}
export class SaveAircraftListSuccessPayload {
  constructor(public data: Array<models.Aircraft>) {}
}
export class SaveAircraftListSuccessAction implements Action {
  readonly type = SAVE_AIRCRAFT_LIST_SUCCESS;

  constructor(public payload: SaveAircraftListSuccessPayload) {}
}

export class SaveAircraftListFailAction implements Action {
  readonly type = SAVE_AIRCRAFT_LIST_FAIL;
}

export class LoadAircraftListSuccessPayload {
  data: Array<models.Aircraft>;
  total: number;
}

export class LoadAircraftListAction implements Action {
  readonly type = LOAD_AIRCRAFT_LIST;
}

export class LoadAircraftListSuccessAction implements Action {
  readonly type = LOAD_AIRCRAFT_LIST_SUCCESS;

  constructor(public payload: LoadAircraftListSuccessPayload) {
  }
}

export class LoadAircraftListFailAction implements Action {
  readonly type = LOAD_AIRCRAFT_LIST_FAIL;
}

export class ChangePagePayload {
  skip: number; 
  take: number;
}
export class ChangePageAction implements Action {
  readonly type = CHANGE_PAGE;

  constructor(public payload: ChangePagePayload) {
  }
}

export class AircraftSelectionChangePayload {
  constructor(public items: Array<models.Aircraft>) {} 
}

export class AircraftSelectionChangeAction implements Action {
  readonly type = AIRCRAFT_SELECTION_CHANGE;

  constructor(public payload: AircraftSelectionChangePayload) {
  }
}

export type Actions = 
  | ResetAction
  | LoadAircraftListAction
  | LoadAircraftListSuccessAction
  | LoadAircraftListFailAction
  | SaveAircraftListAction
  | SaveAircraftListSuccessAction
  | SaveAircraftListFailAction
  | SetAction
  | ChangePageAction
  | AircraftSelectionChangeAction;
