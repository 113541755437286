import {Apollo, APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS} from 'apollo-angular';
import {HttpLink} from 'apollo-angular/http';
import {InMemoryCache, ApolloLink} from '@apollo/client/core';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, InjectionToken, Injector, NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpErrorResponse, HttpHandler } from '@angular/common/http';
import { CookieModule, CookieService } from 'ngx-cookie';

import { Store, StoreModule, select, ActionReducerMap } from '@ngrx/store';
import { StoreRouterConnectingModule, RouterStateSerializer, DefaultRouterStateSerializer } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { CustomSerializer, metaReducers, reducers, State } from './reducers';

import { AppRoutingModule } from './app-routing.module';
import * as coreComponents from './core/components';
import { CoreModule } from './core/core.module';
import * as coreUserActions from './core/actions/user';
import * as coreReducers from './core/reducers';
import * as configServiceContracts from './core/services/config/contracts';
import { ConfigKeysEnum } from './core/services/config/contracts';
import * as userServiceContracts from './core/services/user/contracts/user';
import * as coreTypes from './core/types';


import { environment } from '../environments/environment';
import { EnvironmentEnum } from '../environments/environment.interface';

import * as sharedSystemActions from './shared/actions/system';
import * as sharedTypes from './shared/types';
import * as sharedServices from './shared/services';
import { SharedModule } from './shared/shared.module';

import { HomeComponent } from './core/components/home/home.component';
import { VersionHistoryPageComponent } from './core/containers/version-history-page/version-history-page.component';

import { filter, first } from 'rxjs/operators';






import { EffectsModule } from '@ngrx/effects';
import * as coreEffects from './core/effects';

export const REDUCER_TOKEN = new InjectionToken<ActionReducerMap<State>>('Registered Reducers');

export function getReducers() {
  return reducers;
}

@NgModule({
  declarations: [
    HomeComponent,
    VersionHistoryPageComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    CookieModule.forRoot(),
    CoreModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    SharedModule,
    StoreModule.forRoot(reducers, { metaReducers, runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true } }),
    StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 15, // set maximum stored states
      logOnly: environment.environment === EnvironmentEnum.PRODUCTION // Restrict extension to log-only mode in prod
    }),
    EffectsModule.forRoot([coreEffects.UserEffects])
  ],
  providers: [
    // AppConfig,
    {
      provide: RouterStateSerializer,
      useClass: CustomSerializer
    },
    {
      provide: REDUCER_TOKEN,
      useFactory: getReducers,
    },
    {
      provide: configServiceContracts.SERVICE_TOKEN,
      useFactory: configServiceFactory,
      deps: [CookieService]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceInitializer,
      deps: [
        configServiceContracts.SERVICE_TOKEN, 
        Store, 
        CookieService, 
        sharedTypes.LOCAL_STORAGE_SERVICE_TOKEN],
      multi: true
    },
    {
      provide: sharedServices.BaseHttp,
      useFactory: baseHttpServiceFactory,
      deps: [HttpHandler, configServiceContracts.SERVICE_TOKEN, Injector]
    },
    { provide: HTTP_INTERCEPTORS, useClass: sharedTypes.AuthInterceptor, multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: provideApollo.bind(this, ConfigKeysEnum.SOURCING_SERVICE_ENDPOINT),
      deps: [HttpLink, configServiceContracts.SERVICE_TOKEN]
    },
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: provideNamedApollo.bind(this, [
        {endpointName: ConfigKeysEnum.AIRCRAFT_SERVICE_ENDPOINT, apolloName: 'aircraftApollo'},
        {endpointName: ConfigKeysEnum.OPERATOR_API_ENDPOINT, apolloName: 'operatorApollo'},
      ]),
      deps: [HttpLink, configServiceContracts.SERVICE_TOKEN],
    },
    {
      provide: userServiceContracts.USER_SERVICE_TOKEN,
      useFactory: userServiceFactory,
      deps: [Apollo, CookieService]
    },
    {
      provide: sharedTypes.NOTIFICATION_SERVICE_TOKEN,
      useClass: sharedTypes.NotificationService
    },
    {
      provide: sharedTypes.STOMP_SERVICE_TOKEN,
      useClass: sharedTypes.StompService
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    },
    {
      provide: sharedTypes.ANALYTICS_SERVICE_TOKEN,
      useClass: sharedTypes.AnalyticsService
    },
    {
      provide: sharedTypes.LOCAL_STORAGE_SERVICE_TOKEN,
      useClass: sharedTypes.BaseLocalStorageService
    },
  ],
  bootstrap: [coreComponents.AppComponent]
})
export class AppModule {
}

export function baseHttpServiceFactory(
  http: HttpClient,
  configService: coreTypes.IConfigService,
  injector: Injector
): sharedTypes.BaseHttp {
  return new sharedTypes.BaseHttp(configService, http,  injector);
}


export function configServiceFactory(cookieService: CookieService): any {
  return environment.environment === EnvironmentEnum.PRODUCTION
    ? new coreTypes.ConfigService(cookieService)
    : new coreTypes.ConfigMockService();
}

export function configServiceInitializer(
  configService: configServiceContracts.IConfigService,
  store$: Store<any>,
  cookieService: CookieService,
  storageService: sharedServices.ILocalStorage): Function {

  return () => configService.load()
    .then(() => {
      if (location.pathname === '/login') {
        try {
          const params = (new URL(location.href)).searchParams;
          const token = params.get('token');
          const expires = params.get('expirationDate');
          const returnUrl = params.get('returnUrl');
          const url = returnUrl && new URL(returnUrl);
          let selectedOperatorUuid = url?.searchParams?.get('selectedOperatorUuid') || params.get('selectedOperatorUuid');
          if (selectedOperatorUuid) {
            storageService.setItem('selectedAccountId', selectedOperatorUuid);
            url.searchParams.delete('selectedOperatorUuid');
          }
          cookieService.put('token', token, {expires: expires});
          location.href = url.href || location.origin;
        } catch (e) {
          this.notificationService.show(
            'Can not login',
            sharedTypes.NotificationStyle.Bar, sharedTypes.NotificationType.Danger,
            sharedTypes.NotificationPosition.Top, 10000);
        }
      } else {
        const url = new URL(location.href);
        let selectedOperatorUuid = url?.searchParams?.get('selectedOperatorUuid');
        if (selectedOperatorUuid) {
          storageService.setItem('selectedAccountId', selectedOperatorUuid);
          url.searchParams.delete('selectedOperatorUuid');
        }
        if (selectedOperatorUuid) {
          location.href = url.href || location.origin;
        }
      }

      store$.dispatch(new coreUserActions.LoadAction());
      store$.dispatch(new sharedSystemActions.SetBrowserSupport(true));

      return store$
        .pipe(
          select(coreReducers.getUserUser),
          filter(user => user !== null), first())
        .toPromise();
    });
}

export function userServiceFactory(apollo: Apollo, cookieService: CookieService): coreTypes.IUserService {
  if (environment.environment !== EnvironmentEnum.LOCAL) {
    return new coreTypes.UserService(apollo, cookieService);
  } else {
    return new coreTypes.UserServiceMock();
  }
}

export function provideApollo(
  endpointName: ConfigKeysEnum,
  httpLink: HttpLink,
  configService: configServiceContracts.IConfigService,
) {
  const onErrorLink = onError(({ networkError }) => {
    if (networkError && (networkError as HttpErrorResponse).status === 401) {
      location.reload();
    }
  });

  const auth = setContext((operation, context) => ({
    headers: {
      Authorization: `Bearer ${configService.get(ConfigKeysEnum.AUTHORIZATION_TOKEN)}`,
    },
    uri: `${configService.get(endpointName)}/graphql`,
  }));

  const link = ApolloLink.from([onErrorLink, auth, httpLink.create({})]);
  const cache = new InMemoryCache();
  const subResult = {
    link,
    cache,
    defaultOptions: {
      query: {
        errorPolicy: 'all',
      },
    },
  };

  return subResult;
}

export function provideNamedApollo(
  configs: Array<{endpointName: ConfigKeysEnum, apolloName: string}>,
  httpLink: HttpLink,
  configService: configServiceContracts.IConfigService,
) {
  return configs.reduce((acc, config) => {
    acc[config.apolloName] = provideApollo(config.endpointName, httpLink, configService);
    return acc;
  }, {});
}
