import { Injectable } from '@angular/core';
import * as contracts from './contracts/user';
import * as models from '../../models';
import { of, Observable } from 'rxjs';

@Injectable()
export class UserServiceMock implements contracts.IUserService {

  getUser(request: contracts.GetUserRequest): Observable<contracts.GetUserResponse> {

    const user = new models.User();
    user.firstName = 'Test';
    user.lastName = 'Delta';

    const response = new contracts.GetUserResponse();
    response.entity = user;

    return of(response);
  }

  signOut(request: contracts.SignOutRequest): Observable<contracts.SignOutResponse> {

    const response = new contracts.SignOutResponse();

    return of(response);
  }

  logUserActivity(request: models.UserActionsEnum): Observable<contracts.LogUserActivityResponse> {
    const response = new contracts.LogUserActivityResponse();
    return of(response);
  }
}
