import * as actions from '../actions/users';
import * as models from '../../domain/models';

export interface State {
  userList: Array<models.User>;
  isUserListLoaded: boolean;
  isUserListLoading: boolean;
  saveSuccessStatus: boolean;
  skip: number;
  itemsPerPage: number;
  itemsTotalCount: number;
}

const initialState: State = {
  userList: [],
  isUserListLoaded: false,
  isUserListLoading: false,
  saveSuccessStatus: null,
  skip: 0,
  itemsPerPage: 10,
  itemsTotalCount: 0,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_USER_LIST: {
      return {
        ...state,
        isUserListLoaded: false,
        isUserListLoading: true
      };
    }

    case actions.LOAD_USER_LIST_FAIL: {
      return {
        ...state,
        userList: [],
        itemsTotalCount: 0,
        isUserListLoaded: true,
        isUserListLoading: false
      };
    }
    case actions.LOAD_USER_LIST_SUCCESS: {
      return Object.assign({}, state, {
        userList: action.payload.entities,
        itemsTotalCount: action.payload.total,
        isUserListLoaded: true,
        isUserListLoading: false
      });
    }

    case actions.SAVE_USER: {
      return {
        ...state,
        isUserListLoading: true,
        isUserListLoaded: false,
        saveSuccessStatus: null,
      };
    }

    case actions.SAVE_USER_FAIL: {
      return {
        ...state,
        isUserListLoading: false,
        isUserListLoaded: true,
        saveSuccessStatus: false
      };
    }

    case actions.SAVE_USER_SUCCESS: {
      return {
        ...state,
        isUserListLoading: false,
        isUserListLoaded: true,
        saveSuccessStatus: true
      };
    }

    case actions.CHANGE_PAGE_ACTION: {
      return Object.assign({}, state, {
        skip: action.payload.skip,
        itemsPerPage: action.payload.take
      });
    }

    default: {
      return state;
    }
  }
}

export const getUserList = (state: State) => state.userList;
export const getIsUserListLoading = (state: State) => state.isUserListLoading;
export const getIsUserListLoaded = (state: State) => state.isUserListLoaded;
export const getSaveSuccessStatus = (state: State) => state.saveSuccessStatus;
export const getSkip = (state: State) => state.skip;
export const getItemsPerPage = (state: State) => state.itemsPerPage;
export const getItemsTotalCount = (state: State) => state.itemsTotalCount;

