import { Injectable, Inject } from '@angular/core';
import * as SockJS from 'sockjs-client';
import { RxStomp, RxStompConfig } from '@stomp/rx-stomp';
import { Observable, Subject } from 'rxjs';
import { StompHeaders, publishParams, Frame } from '@stomp/stompjs';
import * as contracts from './contracts';
import * as configServiceContracts from '../../../core/services/config/contracts';
import { ConfigKeysEnum } from '../../../core/services/config/contracts';
import { map } from 'rxjs/operators';
import { RxStompState } from '@stomp/rx-stomp';
import * as models from '../../models';
import * as mapper from './stomp-mapper';


@Injectable()
export class StompService extends RxStomp implements contracts.IStompService {

    get connectObservable(): Observable<RxStompState> {
        return this.connected$.pipe(map((st: RxStompState): RxStompState => st));
    }

    get errorSubject(): Subject<string | Frame> {
        return this.stompErrors$;
    }


    connect(romPath: string): void {
        const socket: WebSocket = new SockJS(`${this.configService.get(ConfigKeysEnum.SOURCING_SERVICE_ENDPOINT)}${romPath}`);
        const rxStompConfig: RxStompConfig = {};
        rxStompConfig.webSocketFactory = () => socket;
        rxStompConfig.connectHeaders = {
            'Authorization': `Bearer ${this.configService.get(ConfigKeysEnum.AUTHORIZATION_TOKEN)}`
        };
        this.configure(rxStompConfig);
        this.activate();
    }

    disconnect(): void {
        this.deactivate();
    }

    subscribe(destination, headers: StompHeaders = {}): Observable<models.StompMessage> {
        return this.watch(destination, headers).pipe(map(message => {
            if (message.body) {
                const parsed = JSON.parse(message.body);
                return mapper.StompMapper.Instance().stompMessageDtoToStompMessage(parsed);
            }
            return new models.StompMessage();
        }));
    }

    publish(queueName: string | publishParams, message?: string, headers: StompHeaders = {}): void {
        if (typeof queueName === 'string') {
            super.publish({destination: queueName as string, body: message, headers});
        } else {
            super.publish(queueName);
        }
    }

    constructor(@Inject(configServiceContracts.SERVICE_TOKEN) private configService: configServiceContracts.IConfigService) {
        super();
    }
}
