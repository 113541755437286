import * as fromCountriesCollection from './country-collection';
import { ActionReducer, ActionReducerMap, combineReducers, createSelector } from '@ngrx/store';

export interface State {
  countryCollection: fromCountriesCollection.State;
}

const reducers: ActionReducerMap<State> = {
  countryCollection: fromCountriesCollection.reducer,
};

const combinedReducers: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any) {
  return combinedReducers(state, action);
}

export const getCountriesCollectionState = (state: any) => state.agreement.countryCollection;
export const getCountriesCollection = createSelector(getCountriesCollectionState, fromCountriesCollection.getCountryCollection);
export const getCountriesCollectionIsLoaded = createSelector(getCountriesCollectionState, fromCountriesCollection.getIsLoaded);
export const getCountriesCollectionIsLoading = createSelector(getCountriesCollectionState, fromCountriesCollection.getIsLoading);