import {Component, Inject, QueryList, ViewChildren } from '@angular/core';
import { GIT_ENV_VARIABLES_SERVICE_TOKEN, IGitEnvVariablesService } from '../../services';
import { Observable } from 'rxjs';
import * as models from '../../models';
import { ActivatedRoute } from '@angular/router';
import * as apiStatusAction from '../../../core/actions/api-status';
import * as coreReducers from '../../../core/reducers';
import { Store, select } from '@ngrx/store';
import * as layoutActions from '../../../shared/actions/layout';
import { ExpansionPanelComponent } from '@progress/kendo-angular-layout';

@Component({
  selector: 'status-page',
  templateUrl: './status-page.component.html'
})
export class StatusPageComponent {
  public gitEnvVariables$: Observable<models.GitEnvVariables>;
  public status$: Observable<models.ApiStatus>;
  public mobileAppView: boolean;
  public apiNameList = Object.values(models.ApiEnum).map(String);
  public isStatusLoading$: Observable<boolean>;

  @ViewChildren("panel") public panels: QueryList<ExpansionPanelComponent>;

  constructor(
    private store$: Store<any>,
    @Inject(GIT_ENV_VARIABLES_SERVICE_TOKEN) 
    private gitEnvVariablesService: IGitEnvVariablesService,
    private route: ActivatedRoute
  ) {
    this.status$ = this.store$.pipe(select(coreReducers.getApiStatus));
    this.isStatusLoading$ = this.store$.pipe(select(coreReducers.getApiStatusIsLoading))
    this.gitEnvVariables$ = this.gitEnvVariablesService.getGitEnvVariables();
    this.mobileAppView = this.route.snapshot.data['mobileAppView'];
    if (this.mobileAppView) {
      this.store$.dispatch(new layoutActions.ActivateAppViewAction());
    }
  }
  
  onApiStatusClick(targetPanel: ExpansionPanelComponent): void {
    if (!targetPanel.expanded) {
      this.store$.dispatch(new apiStatusAction.GetApiStatusAction(targetPanel.title));
    }
    
    this.panels.forEach((panel) => {
      if(panel.title !== targetPanel.title) {
        panel.toggle(false);
      }
    });
  }
}
