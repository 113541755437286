import * as actions from '../actions/order-collection';
import * as models from '../../domain/models';
import { AvailableFlightSortInput } from '../../../../shared/graphql-types';

export interface State {
  skip: number;
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.Order>;
  itemsPerPage: number;
  itemsTotalCount: number;
  filterOptions: models.FilterOptions;
  autocompleteOptions: models.AutocompleteOptions;
  flightDetailsSidepanelVisible: boolean;
  sortingOptions: AvailableFlightSortInput;
}

const initialState: State = {
  skip: 0,
  isLoaded: false,
  isLoading: false,
  items: [],
  itemsPerPage: 20,
  itemsTotalCount: 0,
  filterOptions: new models.FilterOptions(),
  autocompleteOptions: new models.AutocompleteOptions(),
  flightDetailsSidepanelVisible: false,
  sortingOptions: null
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD: {
      return Object.assign({}, state, {
        isLoading: true
      });
    }

    case actions.LOAD_SUCCESS: {
      const payload: actions.LoadSuccessPayload = action.payload;

      return Object.assign({}, state, {
        itemsTotalCount: payload.count,
        items: payload.data,
        isLoaded: true,
        isLoading: false
      });
    }

    case actions.LOAD_FAIL: {

      return Object.assign({}, state, {
        itemsTotalCount: 0,
        items: [],
        isLoaded: true,
        isLoading: false
      });
    }

    case actions.CHANGE_PAGE_ACTION: {

      return Object.assign({}, state , {
        skip : action.payload.skip,
        itemsPerPage: action.payload.take
      });
    }

    case actions.SET_FILTER_OPTIONS: {
      const payload = action.payload;

      return Object.assign({}, state , {
        filterOptions : Object.assign( {}, state.filterOptions, payload )
      });
    }

    case actions.LOAD_AUTOCOMPLETE_OPTIONS_SUCCESS: {
      const payload = action.payload;
      const searchField = payload.searchField === 'extId' ? 'intId' : payload.searchField;

      return Object.assign({}, state , {
        autocompleteOptions: Object.assign({}, state.autocompleteOptions, {
          [searchField] : payload.options
        })
      });
    }

    case actions.LOAD_AUTOCOMPLETE_OPTIONS_FAILURE: {
      const payload = action.payload;
      const searchField = payload.searchField;

      return Object.assign({}, state , {
        autocompleteOptions: Object.assign({}, state.autocompleteOptions, {
          [searchField] : []
        })
      });
    }

    case actions.SET_FLIGHT_DETAILS_SIDEPANEL_STATUS: {
      return Object.assign({}, state, {
        flightDetailsSidepanelVisible: action.payload
      });
    }

    case actions.RESET_STATE: {
      return initialState;
    }

    case actions.SET_SORTING_OPTIONS: {
      return Object.assign({}, state, {
        sortingOptions: action.payload
      });
    }

    default: {
      return state;
    }
  }
}

export const getSkip = (state: State) => state.skip;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getItemsPerPage = (state: State) => state.itemsPerPage;
export const getItemsTotalCount = (state: State) => state.itemsTotalCount;
export const getFilterOptions = (state: State) => state.filterOptions;
export const getAutocompleteOptions = (state: State) => state.autocompleteOptions;
export const getTailNumberAutocompleteOptions = (state: State) => state.autocompleteOptions.tailNumber;
export const getIntIdAutocompleteOptions = (state: State) => state.autocompleteOptions.intId;
export const getFlightDetailsSidepanelVisible = (state: State) => state.flightDetailsSidepanelVisible;
export const getSorting = (state: State) => state.sortingOptions;
