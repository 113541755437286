import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as actions from '../actions/api-status';
import { catchError, map, mergeMap } from 'rxjs/operators';
import * as apiStatusContracts from '../services/api-status/contracts';
import * as models from '../models';

@Injectable()
export class ApiStatusEffects {
  private apiEnum = models.ApiEnum;
  constructor(private actions$: Actions,
              @Inject(apiStatusContracts.API_STATUS_SERVICE_TOKEN)
              private apiStatusService: apiStatusContracts.IApiStatusService) {
  }

  getApiStatus$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.GetApiStatusAction>(actions.GET_API_STATUS),
      mergeMap(({ payload }) => {
        return this.apiStatusService.getApiStatus(payload)
          .pipe(
            map(svcResp => {
              const successPayload = new actions.GetApiStatusSuccessPayload();
              successPayload.apiName = payload;
              successPayload.status = svcResp;
              return new actions.GetApiStatusSuccessAction(successPayload);
            }),
            catchError(() => of(new actions.GetApiStatusFailAction()))
          );
      })
    ));
}
