import * as models from '../../domain/models';

import * as actions from '../actions/decline-offer-list';
import * as viewModels from '../../view/view-models';
import * as _ from 'lodash';

export interface State {
  criteria: viewModels.OffersLookupCriteria;
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.OperatorPurchaseOffer>;
}

const initialState: State = {
  criteria: new viewModels.OffersLookupCriteria(),
  isLoaded: false,
  isLoading: false,
  items: [],
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.RESET : {
      return {...initialState};
    }

    case actions.LOAD: {
      return {
        ...state,
        criteria: _.cloneDeep(action.payload.criteria),
        isLoading: true
      };
    }

    case actions.LOAD_SUCCESS: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false,
        items: action.payload.data,
      };
    }

    default: {
      return state;
    }
  }
}

export const getCriteria = (state: State) => state.criteria;
export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;

