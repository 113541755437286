import * as actions from '../actions/api-status';
import * as models from '../models';
import * as _ from 'lodash';

export interface State {
  apiStatus: models.ApiStatus,
  apiStatusLoading: boolean,
  apiStatusLoaded: boolean,
}

const initialState: State = {
  apiStatus: null,
  apiStatusLoading: false,
  apiStatusLoaded: false
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {

    case actions.GET_API_STATUS: {
      return {
        ...state,
        apiStatusLoading: true,
      };
    }

    case actions.GET_API_STATUS_SUCCESS: {
      return {
        ...state,
        apiStatus: action.payload.status,
        apiStatusLoading: false,
        apiStatusLoaded: true
      };
    }

    case actions.GET_API_STATUS_FAIL: {
      return {
        ...state,
        apiStatusLoading: false,
        apiStatusLoaded: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getApiStatus = (state: State) => state.apiStatus;
export const getApiStatusIsLoading = (state: State) => state.apiStatusLoading;
export const getApiStatusIsLoaded = (state: State) => state.apiStatusLoaded;
