import {Action} from '@ngrx/store';

export const GET_API_STATUS = '[Shared] Get Status';
export const GET_API_STATUS_SUCCESS = '[Shared] Get Status Success';
export const GET_API_STATUS_FAIL = '[Shared] Get Status Fail';

export class GetApiStatusAction implements Action {
  readonly type = GET_API_STATUS;

  constructor(public payload: string) {
  }
}
export class GetApiStatusSuccessPayload {
  apiName: string;
  status: any;
}
export class GetApiStatusSuccessAction implements Action {
  readonly type = GET_API_STATUS_SUCCESS;

  constructor(public payload: GetApiStatusSuccessPayload) {
  }
}

export class GetApiStatusFailAction implements Action {
  readonly type = GET_API_STATUS_FAIL;
}

export type Actions =
  | GetApiStatusAction
  | GetApiStatusSuccessAction
  | GetApiStatusFailAction;
