import { Action } from '@ngrx/store';
import { CurrencyRate } from '../../domain/models';

export const LOAD = '[Offers.CurrencyRates] Load';
export const LOAD_SUCCESS = '[Offers.CurrencyRates] Load Success';
export const LOAD_FAIL = '[Offers.CurrencyRates] Load Fail';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: Array<CurrencyRate>) {
  }
}

export class LoadFailAction implements Action {
  readonly type = LOAD_FAIL;
}

export type Actions = LoadAction
  | LoadSuccessAction
  | LoadFailAction;
