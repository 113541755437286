import * as actions from '../actions/fbo-ff';
import * as models from '../../domain/models';
import * as _ from 'lodash';

export interface State {
  fboMap: Map<string, Array<models.Fbo>>;
  countLoading: number;
}

const initialState: State = {
  fboMap: new Map<string, Array<models.Fbo>>(),
  countLoading: 0
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {

    case actions.LOAD_FBO: {
      return Object.assign({}, state, {
        countLoading: state.countLoading + 1
      });
    }

    case actions.LOAD_FBO_FAILURE: {
      const payload: actions.LoadFboFailurePayload = action.payload;

      const map = _.cloneDeep(state.fboMap);
      if (map.get(payload.airportCode) !== undefined) {
        map.delete(payload.airportCode);
      }

      return Object.assign({}, state, {
        fboMap: map,
        countLoading : state.countLoading - 1,
      });
    }

    case actions.LOAD_FBO_SUCCESS: {
      const payload: actions.LoadFboSuccessPayload = action.payload;

      const map = _.cloneDeep(state.fboMap);
      map.set(payload.airportCode, payload.fboList);

      return Object.assign({}, state, {
        fboMap: map,
        countLoading: state.countLoading - 1
      });
    }

    default: {
      return state;
    }
  }
}

export const getFboMap = (state: State) => state.fboMap;
export const getFboLoading = (state: State) => state.countLoading > 0;
