import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as airportContracts from './contracts';
import * as models from '../../models';
import { Observable, of } from 'rxjs';
import { Apollo, gql } from 'apollo-angular';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class AirportService implements airportContracts.IAirportService {

  constructor(
    private store: Store<any>,
    private apollo: Apollo) {
  }

  getAirportList(request: string): Observable<Array<models.Airport>> {

    return this.apollo.query<any>({
      query: gql(`
      query getAirportShared($filter: AirportFilterInput) {
        listAirports(filter: $filter, pagination: {skip: 0, limit: 20}) {
          airports {
            code
            name
            timezoneName
            address {
              city
            }
          }
        }
      }`),
      variables: {
        filter: {
          or: [
            {
              code: {contains: request},
            },
            {
              name: {contains: request},
            },
            {
              icao: {contains: request},
            },
            {
              iata: {contains: request},
            },
            {
              address: {city: {contains: request}},
            }
          ],
          active: {eq: true}
        }
      }
    })
      .pipe(
        map(serviceResponse => {
          const items = serviceResponse.data.listAirports.airports;
          return items ? items.map(item => {
            const airport = new models.Airport();
            airport.code = item.code;
            airport.name = item.name;
            airport.city = item.address.city;
            airport.timezoneName = item.timezoneName;
            return airport;
          }) : [];
        }),
        catchError((error: Response) => {
          return of([]);
        })
      );
  }
}
