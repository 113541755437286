import * as actions from '../actions/aircraft-list';
import * as models from '../../domain/models';
import * as _ from 'lodash';

export interface State {
  isLoaded: boolean;
  isLoading: boolean;
  items: Array<models.Aircraft>;
  selectedAircraft: Array<models.Aircraft>
  changes: Partial<models.Aircraft>;
  itemsPerPage: number;
  itemsTotalCount: number;
  skip: number;
}

const initialState: State = {
  isLoaded: false,
  isLoading: false,
  items: [],
  selectedAircraft: [],
  changes: {},
  itemsPerPage: 10,
  itemsTotalCount: 0,
  skip: 0,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.RESET: {
      return initialState;
    }

    case actions.LOAD_AIRCRAFT_LIST: {
      return { 
        ...state,
        isLoading: true
      };
    }

    case actions.LOAD_AIRCRAFT_LIST_SUCCESS: {
      const payload: actions.LoadAircraftListSuccessPayload = action.payload;

      return { 
        ...state,
        items: _.cloneDeep(payload.data),
        itemsTotalCount: payload.total,
        isLoaded: true,
        isLoading: false
      };
    }

    case actions.SAVE_AIRCRAFT_LIST: {
      return { 
        ...state,
        isLoaded: false,
        isLoading: true
      };
    }

    case actions.SAVE_AIRCRAFT_LIST_SUCCESS: {
      const payload: actions.SaveAircraftListSuccessPayload = action.payload;
      const items: Array<models.Aircraft> = _.cloneDeep(state.items);
      payload.data.forEach((aircraft) => {
        const index = items.findIndex((a) => a.aircraftId === aircraft.aircraftId);
        items[index] = aircraft;
      })

      return { 
        ...state,
        items,
        changes: {},
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.SAVE_AIRCRAFT_LIST_FAIL: {
      const items: Array<models.Aircraft> = _.cloneDeep(state.items);

      return { 
        ...state,
        items,
        itemsTotalCount: 0,
        changes: {},
        isLoaded: true,
        isLoading: false
      };
    }

    case actions.LOAD_AIRCRAFT_LIST_FAIL: {

      return { 
        ...state,
        items: [],
        isLoaded: true,
        isLoading: false,
      };
    }

    case actions.SET: {
      return {
        ...state,
        changes: _.cloneDeep(action.payload.item),
      }
    }

    case actions.CHANGE_PAGE: {
      return {
        ...state,
        skip: action.payload.skip,
        itemsPerPage: action.payload.take,
      };
    }

    case actions.AIRCRAFT_SELECTION_CHANGE: {
      return {
        ...state,
        selectedAircraft: _.cloneDeep(action.payload.items)
      }
    }

    default: {
      return state;
    }
  }
}

export const getIsLoaded = (state: State) => state.isLoaded;
export const getIsLoading = (state: State) => state.isLoading;
export const getItems = (state: State) => state.items;
export const getSkip = (state: State) => state.skip;
export const getItemsPerPage = (state: State) => state.itemsPerPage;
export const getItemsTotalCount = (state: State) => state.itemsTotalCount;
export const getSelectedAircraft = (state: State) => state.selectedAircraft;
export const getAircraftChanges = (state: State) => state.changes; 