import { AircraftSortingInput } from '../../../../shared/graphql-types';

export class AircraftLookup {
    searchTerm: string = null;
    skip = 0;
    limit = 20;
    total = 0;
    deleted: boolean = false;
    d085OpsSpecsApproval: boolean = true;
    sorting: AircraftSortingInput = { tailNumber: true };
    insApproveOnly: boolean = null;
    completed: boolean = null;
    approvedBy: string = null;
    aircraftCategory: number = null;
    homeBase: string = null;
    homeBaseCountry: string = null;
    homeBaseServiceAreas: Array<string> = null;
    onFleet: boolean = null;
    marketplacePhotosApproval: boolean = null;
    aircraftAge: number = null;
    refurbishmentAge: number = null;

    constructor(data?: any) {
      if (data) {
        this.searchTerm = data.hasOwnProperty('searchTerm') ? data.searchTerm : null;
        this.d085OpsSpecsApproval = data.hasOwnProperty('d085OpsSpecsApproval') ? data.d085OpsSpecsApproval : true;
        this.insApproveOnly = data.hasOwnProperty('insApproveOnly') ? data.insApproveOnly : null;
        this.completed = data.hasOwnProperty('completed') ? data.completed : null;
        this.approvedBy = data.hasOwnProperty('approvedBy') ? data.approvedBy : null;
        this.aircraftCategory = data.hasOwnProperty('aircraftCategory') ? data.aircraftCategory : null;
        this.homeBase = data.hasOwnProperty('homeBase') ? data.homeBase : null;
        this.homeBaseCountry = data.hasOwnProperty('homeBaseCountry') ? data.homeBaseCountry : null;
        this.homeBaseServiceAreas = data.hasOwnProperty('homeBaseServiceAreas') ? data.homeBaseServiceAreas : null;
        this.onFleet = data.hasOwnProperty('onFleet') ? data.onFleet : null;
        this.marketplacePhotosApproval = data.hasOwnProperty('marketplacePhotosApproval') ? data.marketplacePhotosApproval : null;
        this.aircraftAge = data.hasOwnProperty('aircraftAge') ? data.aircraftAge : null;
        this.refurbishmentAge = data.hasOwnProperty('refurbishmentAge') ? data.refurbishmentAge : null;
      }
    }
  }