import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import * as contracts from './contracts'
import * as models from '../../models';
import { map } from 'rxjs/operators';

@Injectable()
export class GitEnvVariablesService implements contracts.IGitEnvVariablesService {
  constructor(private http: HttpClient) {}

  getGitEnvVariables(): Observable<models.GitEnvVariables> {
    let requestUrl = 'assets/json/git-env-variables.json';
    return this.http.get<any>(requestUrl).pipe(
      map(serviceResponse => {
        const response = new models.GitEnvVariables();
        if (!serviceResponse) {
            return response;
        }
        response.branch = serviceResponse.BRANCH;
        response.pipelineUrl = serviceResponse.PIPELINE_URL;
        response.envName = serviceResponse.ENV_NAME;
        response.env = serviceResponse.ENV
        response.versionHash = serviceResponse.VERSION_HASH;
        return response;
      })
    );
  }
}
