import * as actions from '../actions/operator';
import * as models from '../../domain/models';
import { VendorDto } from '../../services/mappers/dtos/vendor';

export interface State {
  operator: models.Operator;
  isLoaded: boolean;
  isLoading: boolean;
  regions: Array<models.Region>;
  isRegionsLoading: boolean;
  isRegionsLoaded: boolean;
}

const initialState: State = {
  operator: new models.Operator(),
  isLoaded: false,
  isLoading: false,
  regions: [],
  isRegionsLoading: false,
  isRegionsLoaded: false,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {

    case actions.LOAD_OPERATOR_INFO: {
      return {
        ...state,
        isLoaded: false,
        isLoading: true
      };
    }

    case actions.LOAD_OPERATOR_INFO_FAIL: {
      return {
        ...state,
        isLoaded: true,
        isLoading: false
      };
    }
    case actions.LOAD_OPERATOR_INFO_SUCCESS: {
      return Object.assign({}, state, {
        operator: action.payload,
        isLoaded: true,
        isLoading: false
      });
    }

    case actions.SET_OPERATOR_USER_NUMBER: {
      const operator = Object.assign(new models.Operator(), state.operator);
      operator.setNumberOfUsers(action.payload);
      return {
        ...state,
        operator
      };
    }

    case actions.LOAD_REGIONS: {
      return {
        ...state,
        isRegionsLoaded: false,
        isRegionsLoading: true
      };
    }
    case actions.LOAD_REGIONS_FAIL: {
      return {
        ...state,
        isRegionsLoaded: true,
        isRegionsLoading: false
      };
    }
    case actions.LOAD_REGIONS_SUCCESS: {
      return {
        ...state,
        regions: action.payload.entities,
        isRegionsLoaded: true,
        isRegionsLoading: false
      };
    }

    case actions.SAVE_OPERATOR_INFO: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case actions.SAVE_OPERATOR_INFO_SUCCESS:
    case actions.SAVE_OPERATOR_INFO_FAIL: {
      return {
        ...state,
        isLoading: false
      };
    }

    default: {
      return state;
    }
  }
}

export const getOperatorInfo = (state: State) => state.operator;
export const getIsOperatorInfoLoading = (state: State) => state.isLoading;
export const getIsOperatorInfoLoaded = (state: State) => state.isLoaded;
export const getRegions = (state: State) => state.regions;
export const getIsRegionsLoading = (state: State) => state.isRegionsLoading;
export const getIsRegionsLoaded = (state: State) => state.isRegionsLoaded;
