import { Action } from '@ngrx/store';
import * as models from '../../domain/models';
import * as viewModels from '../../view/view-models';
import { CurrencyEnum } from '../../domain/models';

export const RESET = '[Offers.OfferList] Reset';
export const SEARCH = '[Offers.OfferList] Search';
export const SEARCH_SUCCESS = '[Offers.OfferList] Load Success';
export const SEARCH_FAIL = '[Offers.OfferList] Load Fail';
export const SET_SELECTED_PURCHASE_OFFER = '[Offers.OfferList] Ser Selected Purchase Offer';
export const GET_PURCHASE_OFFER = '[Offers.OfferList] Get Purchase Offer By ID';
export const GET_NEW_PURCHASE_OFFER_BY_SOURCING_REQUEST = '[Offers.Offer]  Get New Purchase Offer By Sourcing Request';
export const GET_PURCHASE_OFFER_SUCCESS = '[Offers.OfferList] Get Purchase Offer By ID Success';
export const GET_PURCHASE_OFFER_FAIL = '[Offers.OfferList] Get Purchase Offer By ID Fail';
export const SET_ACCEPT_OFFER_DIALOG_VISIBLE = '[Offers.OfferList] Set Accept Offer Dialog Visible';
export const SET_YOUR_OFFER_AIRCRAFT = '[Offers.OfferList] Set Your Offer Aircraft For Chart';
export const SET_YOUR_OFFER_PRICE = '[Offers.OfferList] Set Your Offer Price For Chart';
export const SET_FBOS = '[Offers.OfferList] Set FBOs';
export const ADD_NEW_LEG = '[Offers.OfferList] Add New Leg';
export const REMOVE_LEG = '[Offers.OfferList] Remove Leg';

export class ResetAction implements Action {
  readonly type = RESET;
}

export class SearchPayload {
  criteria: viewModels.OffersLookupCriteria;
  skip: number;
  itemsPerPage: number;
  isLoading?: boolean;

  constructor(criteria: viewModels.OffersLookupCriteria, skip: number, itemsPerPage: number) {
    this.criteria = new viewModels.OffersLookupCriteria();

    if (criteria) {
      this.criteria = criteria;
    }

    this.skip = skip;
    this.itemsPerPage = itemsPerPage;
  }
}

export class SearchAction implements Action {
  readonly type = SEARCH;

  constructor(public payload: SearchPayload) {
  }
}

export class SearchSuccessPayload {
  skip: number;
  data: Array<models.OperatorPurchaseOffer>;
  count: number;
}

export class SearchSuccessAction implements Action {
  readonly type = SEARCH_SUCCESS;

  constructor(public payload: SearchSuccessPayload) {
  }
}

export class SearchFailAction implements Action {
  readonly type = SEARCH_FAIL;
}

export class SetSelectedPurchaseOfferAction implements Action {
  readonly type = SET_SELECTED_PURCHASE_OFFER;

  constructor(public payload: models.OperatorPurchaseOffer) {
  }
}

export class GetPurchaseOfferAction implements Action {
  readonly type = GET_PURCHASE_OFFER;

  constructor(public payload: string) {
  }
}


export class GetNewPurchaseOfferBySourcingRequestAction implements Action {
  readonly type = GET_NEW_PURCHASE_OFFER_BY_SOURCING_REQUEST;

  constructor(public payload: string) {
  }
}

export class GetPurchaseOfferSuccessAction implements Action {
  readonly type = GET_PURCHASE_OFFER_SUCCESS;

  constructor(public payload: models.OperatorPurchaseOffer) {
  }
}

export class GetPurchaseOfferFailAction implements Action {
  readonly type = GET_PURCHASE_OFFER_FAIL;
}

export class SetAcceptOfferDialogVisibleAction implements Action {
  readonly type = SET_ACCEPT_OFFER_DIALOG_VISIBLE;

  constructor(public payload: boolean) {
  }
}


export class SetYourOfferAircraft implements Action {
  readonly type = SET_YOUR_OFFER_AIRCRAFT;

  constructor(public payload: { modelName: string, categoryName: string }) {
  }
}

export class SetYourOfferPrice implements Action {
  readonly type = SET_YOUR_OFFER_PRICE;

  constructor(public payload: { offerPrice: number, currency: CurrencyEnum, offerPriceInCurrency: number }) {
  }
}

export class SetFbos implements Action {
  readonly type = SET_FBOS;

  constructor(public payload: Array<models.LegRequest>) {
  }
}

export class AddNewLeg implements Action {
  readonly type = ADD_NEW_LEG;
}

export class RemoveLeg implements Action {
  readonly type = REMOVE_LEG;

  constructor(public payload: number) {}
}

export type Actions =
  ResetAction
  | SearchAction
  | SearchSuccessAction
  | SearchFailAction
  | SetSelectedPurchaseOfferAction
  | GetPurchaseOfferAction
  | GetPurchaseOfferSuccessAction
  | GetPurchaseOfferFailAction
  | SetAcceptOfferDialogVisibleAction
  | SetYourOfferAircraft
  | SetYourOfferPrice
  | SetFbos
  | AddNewLeg
  | RemoveLeg
  | GetNewPurchaseOfferBySourcingRequestAction;
