import {Action} from '@ngrx/store';

export const GET_AIRPORT_LIST = '[Shared] Get Airport List';
export const GET_AIRPORT_LIST_SUCCESS = '[Shared] Get Airport List Success';
export const GET_AIRPORT_LIST_FAIL = '[Shared] Get Airport List Fail';

export class GetAirportListAction implements Action {
  readonly type = GET_AIRPORT_LIST;

  constructor(public payload: string) {
  }
}

export class GetAirportListSuccessAction implements Action {
  readonly type = GET_AIRPORT_LIST_SUCCESS;

  constructor(public payload: any) {
  }
}

export class GetAirportListFailAction implements Action {
  readonly type = GET_AIRPORT_LIST_FAIL;

  constructor() {
  }
}

export type Actions =
  | GetAirportListAction
  | GetAirportListSuccessAction
  | GetAirportListFailAction;
