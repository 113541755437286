import {Component, OnDestroy} from '@angular/core';
import { select, Store } from '@ngrx/store';

import * as layoutActions from '../../../shared/actions/layout';
import * as reducers from '../../reducers';
import { Observable } from 'rxjs';
import * as models from '../../models';

@Component({
  selector: 'error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnDestroy {

  viewPermissions$: Observable<models.ViewPermissions>;

  constructor(private store: Store<any>) {
    this.store.dispatch(new layoutActions.ActivateFullHeightViewAction());
    this.viewPermissions$ = this.store.pipe(select(reducers.getUserUserPermissions));
  }

  ngOnDestroy(): void {
    this.store.dispatch(new layoutActions.DeactivateFullHeightViewAction());
  }
}
