import { Action } from '@ngrx/store';
import { VendorDto } from '../../services/mappers/dtos/vendor';
import * as viewModels from '../../view/view-models';

export const LOAD_OPERATOR_INFO = '[Operator Profile] Load Operator Info';
export const LOAD_OPERATOR_INFO_SUCCESS = '[Operator Profile] Accept Purchase Offer Success';
export const LOAD_OPERATOR_INFO_FAIL = '[Operator Profile] Accept Purchase Offer Fail';

export const LOAD_REGIONS = '[Operator Profile] Load Regions';
export const LOAD_REGIONS_SUCCESS = '[Operator Profile] Load Regions Success';
export const LOAD_REGIONS_FAIL = '[Operator Profile] Load Regions Fail';

export const SAVE_OPERATOR_INFO = '[Operator Profile] Save Operator Info';
export const SAVE_OPERATOR_INFO_SUCCESS = '[Operator Profile] Save Operator Info Success';
export const SAVE_OPERATOR_INFO_FAIL = '[Operator Profile] Save Operator Info Fail';

export const SET_OPERATOR_USER_NUMBER = '[Operator Profile] Set Operator Number of Users';

export const SYNC_OPERATOR = '[Operator Profile] Sync';
export const SYNC_OPERATOR_SUCCESS = '[Operator Profile] Sync Success';
export const SYNC_OPERATOR_FAIL = '[Operator Profile] Sync Fail';

export class LoadOperatorInfo implements Action {
  readonly type = LOAD_OPERATOR_INFO;
  constructor() {}
}

export class LoadOperatorInfoSuccess implements Action {
  readonly type = LOAD_OPERATOR_INFO_SUCCESS;
  constructor(public payload: any) {
  }
}

export class LoadOperatorInfoFail implements Action {
  readonly type = LOAD_OPERATOR_INFO_FAIL;
}

export class LoadRegions implements Action {
  readonly type = LOAD_REGIONS;
  constructor() {}
}

export class LoadRegionsSuccess implements Action {
  readonly type = LOAD_REGIONS_SUCCESS;
  constructor(public payload: any) {
  }
}

export class LoadRegionsFail implements Action {
  readonly type = LOAD_REGIONS_FAIL;
}

export class SaveOperatorInfo implements Action {
  readonly type = SAVE_OPERATOR_INFO;
  constructor(public payload: viewModels.OperatorFormViewModel) {}
}

export class SaveOperatorInfoSuccess implements Action {
  readonly type = SAVE_OPERATOR_INFO_SUCCESS;
  constructor() {
  }
}

export class SaveOperatorInfoFail implements Action {
  readonly type = SAVE_OPERATOR_INFO_FAIL;
}

export class SetNumberOfUser implements Action {
  readonly type = SET_OPERATOR_USER_NUMBER;
  constructor(public payload: number) {
  }
}

export class SyncOperatorAction implements Action {
  readonly type = SYNC_OPERATOR;

  constructor(public payload: string) {
  }
}

export class SyncOperatorFailAction implements Action {
  readonly type = SYNC_OPERATOR_SUCCESS;
}

export class SyncOperatorSuccessAction implements Action {
  readonly type = SYNC_OPERATOR_FAIL;
}


export type Actions =
    LoadOperatorInfo 
  | LoadOperatorInfoSuccess 
  | LoadOperatorInfoFail 
  | LoadRegions 
  | LoadRegionsSuccess 
  | LoadRegionsFail 
  | SaveOperatorInfo 
  | SaveOperatorInfoSuccess 
  | SaveOperatorInfoFail 
  | SetNumberOfUser
  | SyncOperatorAction
  | SyncOperatorSuccessAction
  | SyncOperatorFailAction;
