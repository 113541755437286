import {Action} from '@ngrx/store';
import * as models from '../models';

export const LOAD = '[Shared.User] Load';
export const LOAD_SUCCESS = '[Shared.User] Load Success';
export const CHANGE_ACCOUNT = '[Shared.User] Change Account';
export const SIGN_OUT = '[Shared.User] Sign Out';
export const LOG_USER_ACTIVITY = '[Shared.User] LOG_USER_ACTIVITY';

export class LoadAction implements Action {
  readonly type = LOAD;
}

export class LoadSuccessPayload  {
  user: models.User;
  account: models.UserAccount;
}

export class LoadSuccessAction implements Action {
  readonly type = LOAD_SUCCESS;

  constructor(public payload: LoadSuccessPayload) {
  }
}

export class SignOutAction implements Action {
  readonly type = SIGN_OUT;
}

export class ChangeAccountPayload {
  account: models.UserAccount;
}
export class ChangeAccountAction implements Action {
  readonly type = CHANGE_ACCOUNT;

  constructor(public payload: ChangeAccountPayload) {}
}

export class LogUserActivity implements Action {
  readonly type = LOG_USER_ACTIVITY;

  constructor(public payload: models.UserActionsEnum) {}
}

export type Actions =
  LoadAction
  | LoadSuccessAction
  | SignOutAction
  | LogUserActivity
  | ChangeAccountAction;
