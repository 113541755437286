import * as actions from '../actions/aircraft-details';
import { Aircraft } from '../../domain/models';
import * as _ from 'lodash';

export interface State {
  isAircraftDetailsLoaded: boolean;
  isAircraftDetailsLoading: boolean;
  aircraft: Aircraft;
  editMode: boolean;
  editActionNew: boolean;
  pristine: boolean;
}

const initialState: State = {
  isAircraftDetailsLoaded: false,
  isAircraftDetailsLoading: false,
  aircraft: null,
  editMode: false,
  editActionNew: false,
  pristine: true,
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_AIRCRAFT_DETAILS: {
      return {
        ...state,
        isAircraftDetailsLoading: true,
        isAircraftDetailsLoaded: false,
      };
    }

    case actions.LOAD_AIRCRAFT_DETAILS_SUCCESS: {
      return { 
          ...state,
        isAircraftDetailsLoaded: true,
        isAircraftDetailsLoading: false,
        pristine: true,
        aircraft: action.payload,
      };
    }

    case actions.LOAD_AIRCRAFT_DETAILS_FAIL: {
      return {
        ...state,
        isAircraftDetailsLoaded: true,
        isAircraftDetailsLoading: false,
      };
    }

    case actions.RESET_AIRCRAFT_DETAILS: {
      return { ...initialState };
    }

    case actions.SET_AIRCRAFT: {
      let pristine = _.cloneDeep(state.pristine);
      const compare1 = _.cloneDeep(state.aircraft);
      const compare2 = _.cloneDeep(action.payload);
      delete compare1.deleted;
      delete compare2.deleted;
      if (!_.isEqual(compare1, compare2)) {
        pristine = false;
      }

      return {
        ...state,
        pristine,
        aircraft: _.cloneDeep(action.payload.item),
        isAircraftDetailsLoaded: true,
      };
    }

    case actions.SAVE_AIRCRAFT: {
      return {
        ...state,
        isAircraftDetailsLoading: true,
      };
    }

    case actions.SAVE_AIRCRAFT_SUCCESS: {
      return {
        ...state,
        pristine: true,
        isAircraftDetailsLoading: false,
        editMode: false,
        editActionNew: false,
      };
    }

    case actions.SAVE_AIRCRAFT_FAIL: {
      return {
        ...state,
        isAircraftDetailsLoading: false,
      };
    }

    case actions.SET_EDIT_MODE: {
      return {
        ...state,
        editMode: action.payload
      }
    }

    case actions.SET_EDIT_ACTION_NEW: {
      return {
        ...state,
        editMode: true,
        editActionNew: action.payload

      }
    }

    default: {
      return state;
    }
  }
}

export const getAircraftDetailsIsLoading = (state: State) => state.isAircraftDetailsLoading;
export const getAircraftDetailsIsLoaded = (state: State) => state.isAircraftDetailsLoaded;
export const getAircraftDetails = (state: State) => state.aircraft;
export const getPristine = (state: State) => state.pristine;
export const getEditMode = (state: State) => state.editMode;
export const getEditActionNew = (state: State) => state.editActionNew;
