// tslint:disable
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Must be a valid ICAO airport code */
  AircraftIdInput: any;
  /** Must be a valid aircraft id */
  AirportCodeInput: string;
  /** A scalar BigDecimal */
  BigDecimal: number;
  /** An email address scalar */
  EmailAddress: string;
  /** An ISO-8601 compliant Date Scalar */
  IsoDate: string;
  /** An ISO-8601 compliant DateTime Scalar */
  IsoDateTime: string;
}




export interface AcceptOperatorCancellationTerms {
  defaultTerms?: Maybe<Scalars['Boolean']>;
  terms?: Maybe<Scalars['String']>;
}

/** Postal address */
export interface Address {
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
}

export interface AddressFilterInput {
  line1?: Maybe<ModelStringFilterInput>;
  line2?: Maybe<ModelStringFilterInput>;
  city?: Maybe<ModelStringFilterInput>;
  state?: Maybe<ModelStringFilterInput>;
  country?: Maybe<ModelStringFilterInput>;
}

export interface Aircraft {
  aircraftId: Scalars['Int'];
  tailNumber: Scalars['String'];
  modelName?: Maybe<Scalars['String']>;
  modelId?: Maybe<Scalars['Int']>;
  categoryName?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['Int']>;
  operatorUuid?: Maybe<Scalars['String']>;
  operatorName?: Maybe<Scalars['String']>;
  operator?: Maybe<Operator>;
  yom?: Maybe<Scalars['Int']>;
  yor?: Maybe<Scalars['Int']>;
  maxPax?: Maybe<Scalars['Int']>;
  insuranceCurrency?: Maybe<Scalars['String']>;
  insuranceAmount?: Maybe<Scalars['Int']>;
  operatorApprovalExpirationDate?: Maybe<Scalars['IsoDate']>;
  insuranceExpirationDate?: Maybe<Scalars['IsoDate']>;
  insuranceApproved?: Maybe<Scalars['Boolean']>;
  offFleetOperatorNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  insuranceRestrictionApprover?: Maybe<Scalars['Boolean']>;
  homeBase?: Maybe<Scalars['String']>;
  requiresOwnerApproval?: Maybe<Scalars['Boolean']>;
  deleted?: Maybe<Scalars['Boolean']>;
  shuttleMaxPax?: Maybe<Scalars['Int']>;
  noChange?: Maybe<Scalars['Boolean']>;
  source?: Maybe<Scalars['String']>;
  amenities?: Maybe<Array<AircraftAmenity>>;
  images?: Maybe<Array<AircraftImage>>;
  completed?: Maybe<Scalars['Boolean']>;
  notes?: Maybe<Scalars['String']>;
  costPerHour?: Maybe<Scalars['Int']>;
  capacity?: Maybe<Scalars['Int']>;
  reservationAvailability?: Maybe<Scalars['Boolean']>;
  generic?: Maybe<Scalars['Boolean']>;
  equipments?: Maybe<Array<AircraftEquipment>>;
}


export interface AircraftReservationAvailabilityArgs {
  from: Scalars['IsoDateTime'];
  to?: Maybe<Scalars['IsoDateTime']>;
}

export interface AircraftAmenity {
  id: Scalars['Int'];
  name: Scalars['String'];
  visible?: Maybe<Scalars['Boolean']>;
}

export interface AircraftAmenityFilterInput {
  id?: Maybe<ModelIntFilterInput>;
  name?: Maybe<ModelStringFilterInput>;
  visible?: Maybe<ModelBooleanFilterInput>;
}

export interface AircraftCategory {
  aircraftCategoryId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
}

export interface AircraftCategoryFilterInput {
  aircraftCategoryId?: Maybe<ModelIntFilterInput>;
  name?: Maybe<ModelStringFilterInput>;
}

export interface AircraftEquipment {
  id: Scalars['Int'];
  wyvernCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

export interface AircraftFilterInput {
  aircraftId?: Maybe<ModelIntFilterInput>;
  tailNumber?: Maybe<ModelStringFilterInput>;
  modelName?: Maybe<ModelStringFilterInput>;
  modelId?: Maybe<ModelIntFilterInput>;
  categoryName?: Maybe<ModelStringFilterInput>;
  categoryId?: Maybe<ModelIntFilterInput>;
  operatorUuid?: Maybe<ModelStringFilterInput>;
  operatorName?: Maybe<ModelStringFilterInput>;
  yom?: Maybe<ModelIntFilterInput>;
  yor?: Maybe<ModelIntFilterInput>;
  maxPax?: Maybe<ModelIntFilterInput>;
  insuranceCurrency?: Maybe<ModelStringFilterInput>;
  insuranceAmount?: Maybe<ModelIntFilterInput>;
  insuranceExpirationDate?: Maybe<ModelIsoDateFilterInput>;
  insuranceApproved?: Maybe<ModelBooleanFilterInput>;
  insuranceRestrictionApprover?: Maybe<ModelBooleanFilterInput>;
  homeBase?: Maybe<ModelStringFilterInput>;
  requiresOwnerApproval?: Maybe<ModelBooleanFilterInput>;
  deleted?: Maybe<ModelBooleanFilterInput>;
  shuttleMaxPax?: Maybe<ModelIntFilterInput>;
  noChange?: Maybe<ModelBooleanFilterInput>;
  source?: Maybe<ModelStringFilterInput>;
  amenities?: Maybe<AircraftAmenityFilterInput>;
  images?: Maybe<AircraftImageFilterInput>;
  completed?: Maybe<ModelBooleanFilterInput>;
  notes?: Maybe<ModelStringFilterInput>;
  costPerHour?: Maybe<ModelIntFilterInput>;
  capacity?: Maybe<ModelIntFilterInput>;
}


export interface AircraftImage {
  id: Scalars['Int'];
  url: Scalars['String'];
  type: Scalars['String'];
  width: Scalars['Int'];
  height: Scalars['Int'];
  sortOrder?: Maybe<Scalars['Int']>;
}

export interface AircraftImageFilterInput {
  id?: Maybe<ModelIntFilterInput>;
  url?: Maybe<ModelStringFilterInput>;
  type?: Maybe<ModelStringFilterInput>;
  width?: Maybe<ModelIntFilterInput>;
  height?: Maybe<ModelIntFilterInput>;
}

export interface AircraftPagination {
  aircrafts: Array<Aircraft>;
  pagination?: Maybe<Pagination>;
}

export interface AircraftSortingInput {
  tailNumber?: Maybe<Scalars['Boolean']>;
  modelName?: Maybe<Scalars['Boolean']>;
  categoryName?: Maybe<Scalars['Boolean']>;
  yom?: Maybe<Scalars['Boolean']>;
  homeBase?: Maybe<Scalars['Boolean']>;
  insuranceRestrictionApprover?: Maybe<Scalars['Boolean']>;
}

export interface AircraftType {
  typeId: Scalars['Int'];
}

export interface AircraftTypeFilterInput {
  typeId?: Maybe<ModelIntFilterInput>;
  name?: Maybe<ModelStringFilterInput>;
}

export interface Airport {
  id: Scalars['ID'];
  code: Scalars['String'];
  name: Scalars['String'];
  icao?: Maybe<Scalars['String']>;
  iata?: Maybe<Scalars['String']>;
  countryCode: Scalars['String'];
  address?: Maybe<Address>;
  active?: Maybe<Scalars['Boolean']>;
  location?: Maybe<Geolocation>;
  timezoneName?: Maybe<Scalars['String']>;
  fbos?: Maybe<Array<Fbo>>;
}


export interface AirportFilterInput {
  id?: Maybe<ModelIdFilterInput>;
  code?: Maybe<ModelStringFilterInput>;
  name?: Maybe<ModelStringFilterInput>;
  icao?: Maybe<ModelStringFilterInput>;
  iata?: Maybe<ModelStringFilterInput>;
  countryCode?: Maybe<ModelStringFilterInput>;
  address?: Maybe<AddressFilterInput>;
  active?: Maybe<ModelBooleanFilterInput>;
  location?: Maybe<GeolocationFilterInput>;
  timezoneName?: Maybe<ModelStringFilterInput>;
  fbos?: Maybe<FboFilterInput>;
  and?: Maybe<Array<Maybe<AirportFilterInput>>>;
  or?: Maybe<Array<Maybe<AirportFilterInput>>>;
}

export interface AirportPagination {
  airports: Array<Maybe<Airport>>;
  pagination?: Maybe<Pagination>;
}

export interface AuditLogEvent {
  /** The collection the event is assoicated with */
  collection: Scalars['String'];
  /** The id of the document the event is assoicated with */
  documentId: Scalars['String'];
  /** When the event ocurred */
  timestamp: Scalars['IsoDateTime'];
  /** The topic of the event */
  topic: Scalars['String'];
  /** The subject of the event */
  subject: Scalars['String'];
  /** The message content of the event */
  message: Scalars['String'];
  /** The user (or system) that originated the event */
  userEmail?: Maybe<Scalars['String']>;
}

export interface AuditLogEventFilterInput {
  collection?: Maybe<ModelStringFilterInput>;
  documentId?: Maybe<ModelStringFilterInput>;
  timestamp?: Maybe<ModelIsoDateTimeFilterInput>;
  topic?: Maybe<ModelStringFilterInput>;
  subject?: Maybe<ModelStringFilterInput>;
  message?: Maybe<ModelStringFilterInput>;
}

export interface AuditLogEventPagination {
  events: Array<AuditLogEvent>;
  pagination?: Maybe<Pagination>;
}

export interface AuditUser {
  userId?: Maybe<Scalars['Int']>;
  userTypeId?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['Int']>;
  organizationUuid?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  isStaff?: Maybe<Scalars['Boolean']>;
  isOperator?: Maybe<Scalars['Boolean']>;
}

export interface AutomatedOfferCreate {
  aircraftId?: Maybe<Scalars['Int']>;
  models?: Maybe<Array<Maybe<Scalars['String']>>>;
  categoryId?: Maybe<Scalars['Int']>;
}

export interface AutomatedOfferCreateConfigurations {
  elacProForma?: Maybe<Array<Maybe<AutomatedOfferCreate>>>;
  xoDedicatedFleet?: Maybe<Array<Maybe<AutomatedOfferCreate>>>;
  searchOptions?: Maybe<Array<Maybe<AutomatedOfferCreate>>>;
}

export interface AutomatedOfferCreateConfigurationsFilterInput {
  elacProForma?: Maybe<AutomatedOfferCreateFilterInput>;
  xoDedicatedFleet?: Maybe<AutomatedOfferCreateFilterInput>;
  searchOptions?: Maybe<AutomatedOfferCreateFilterInput>;
}

export interface AutomatedOfferCreateFilterInput {
  aircraftId?: Maybe<ModelIntFilterInput>;
  models?: Maybe<ModelStringFilterInput>;
  categoryId?: Maybe<ModelIntFilterInput>;
}

/** This represents the inventory of available fights. */
export interface AvailableFlight {
  availableFlightId: Scalars['ID'];
  status: AvailableFlightStatus;
  /** The type of flight (private, shared, repositioning */
  contractType: FlightContractType;
  priceType?: Maybe<FlightPriceEnum>;
  /** The departure location */
  departureAirport?: Maybe<Airport>;
  departureFbo?: Maybe<Fbo>;
  /** The departure time */
  departureTime: Scalars['IsoDateTime'];
  departureTimeUTC: Scalars['IsoDateTime'];
  createdDepartureTime: Scalars['IsoDateTime'];
  createdDepartureTimeUTC: Scalars['IsoDateTime'];
  actualDepartureTime: Scalars['IsoDateTime'];
  actualDepartureTimeUTC: Scalars['IsoDateTime'];
  finalDepartureTimeUTC?: Maybe<Scalars['IsoDateTime']>;
  /** The arrival location */
  arrivalAirport?: Maybe<Airport>;
  arrivalFbo?: Maybe<Fbo>;
  /** The arrival time */
  arrivalTime?: Maybe<Scalars['IsoDateTime']>;
  arrivalTimeUTC?: Maybe<Scalars['IsoDateTime']>;
  actualArrivalTime: Scalars['IsoDateTime'];
  actualArrivalTimeUTC: Scalars['IsoDateTime'];
  expirationTime?: Maybe<Scalars['IsoDateTime']>;
  aircraft?: Maybe<Aircraft>;
  operator?: Maybe<Operator>;
  expirationOffset?: Maybe<Scalars['Int']>;
  /** Estimated flight time (minutes) */
  eft?: Maybe<Scalars['Int']>;
  /** External reference information for operator use */
  externalId?: Maybe<Scalars['String']>;
  /** The number seats the operator has made available */
  seatsOffered?: Maybe<Scalars['Int']>;
  /** The number of seats that can be booked. */
  seatsAvailable?: Maybe<Scalars['Int']>;
  /** Per seat pricing applied shuttle flights */
  shuttlePricing?: Maybe<ShuttlePricing>;
  shuttlePriceHistory?: Maybe<Array<ShuttlePricing>>;
  bookings?: Maybe<Array<Booking>>;
  passengers?: Maybe<Array<Passenger>>;
  charterPriceHistory?: Maybe<Array<CharterPricing>>;
  /** Pricing applied for charter flights */
  charterPricing?: Maybe<CharterPricing>;
  repositioningItinerary?: Maybe<RepositioningItinerary>;
  parentFlightId?: Maybe<Scalars['String']>;
  /** 	createUser: User	 */
  creationTime?: Maybe<Scalars['IsoDateTime']>;
  legacyLegId?: Maybe<Scalars['Int']>;
  sharedCharterId?: Maybe<Scalars['Int']>;
  auditLog?: Maybe<Array<Maybe<AuditLogEvent>>>;
}

export interface AvailableFlightFilterInput {
  availableFlightId?: Maybe<ModelIdFilterInput>;
  status?: Maybe<AvailableFlightStatusFilterInput>;
  contractType?: Maybe<FlightContractTypeFilterInput>;
  priceType?: Maybe<FlightPriceTypeFilterInput>;
  repositioningItinerary?: Maybe<RepositioningItineraryFilterInput>;
  creationTime?: Maybe<ModelIsoDateTimeFilterInput>;
  legacyLegId?: Maybe<ModelIntFilterInput>;
  sharedCharterId?: Maybe<ModelIntFilterInput>;
  aircraft?: Maybe<AircraftFilterInput>;
  operator?: Maybe<OperatorFilterInput>;
  externalId?: Maybe<ModelStringFilterInput>;
  eft?: Maybe<ModelIntFilterInput>;
  departureTime?: Maybe<ModelIsoDateTimeFilterInput>;
  departureTimeUTC?: Maybe<ModelIsoDateTimeFilterInput>;
  createdDepartureTime?: Maybe<ModelIsoDateTimeFilterInput>;
  createdDepartureTimeUTC?: Maybe<ModelIsoDateTimeFilterInput>;
  actualDepartureTime?: Maybe<ModelIsoDateTimeFilterInput>;
  actualDepartureTimeUTC?: Maybe<ModelIsoDateTimeFilterInput>;
  finalDepartureTimeUTC?: Maybe<ModelIsoDateTimeFilterInput>;
  departureFbo?: Maybe<FboFilterInput>;
  departureAirport?: Maybe<AirportFilterInput>;
  arrivalTime?: Maybe<ModelIsoDateTimeFilterInput>;
  arrivalTimeUTC?: Maybe<ModelIsoDateTimeFilterInput>;
  actualArrivalTime?: Maybe<ModelIsoDateTimeFilterInput>;
  actualArrivalTimeUTC?: Maybe<ModelIsoDateTimeFilterInput>;
  arrivalFBO?: Maybe<FboFilterInput>;
  arrivalAirport?: Maybe<AirportFilterInput>;
  seatPrice?: Maybe<ModelBigDecimalFilterInput>;
  seatsOffered?: Maybe<ModelIntFilterInput>;
  seatsAvailable?: Maybe<ModelIntFilterInput>;
  seatPricing?: Maybe<ShuttlePricingFilterInput>;
  flightPrice?: Maybe<ModelBigDecimalFilterInput>;
  flightPricing?: Maybe<CharterPricingFilterInput>;
  parentFlightId?: Maybe<ModelStringFilterInput>;
  bookings?: Maybe<BookingFilterInput>;
  passengers?: Maybe<PassengerFilterInput>;
  auditLog?: Maybe<AuditLogEventFilterInput>;
}

export interface AvailableFlightPagination {
  availableFlights: Array<AvailableFlight>;
  pagination?: Maybe<Pagination>;
}

export interface AvailableFlightSortInput {
  creationTime?: Maybe<Scalars['Boolean']>;
  finalDepartureTimeUTC?: Maybe<Scalars['Boolean']>;
}

export enum AvailableFlightStatus {
  /**
   * The flight is open for booking.
   *
   * Flights when created are OPEN.
   *
   * Only CLOSED flights maybe OPEN.
   */
  Open = 'OPEN',
  /**
   * The flight has been booked.
   * Once booked flights cannot
   * be closed.
   *
   * For shuttles this indicates
   * that at least one seat has
   * been booked.
   *
   * Only OPEN flights maybe BOOKED.
   *
   * For charters this indicates
   * that the flight has been sold.
   */
  Booked = 'BOOKED',
  /**
   * The flight was not sold
   * before departure time
   * but is no longer available.
   *
   * Only OPEN or CLOSED flights
   * may be EXPIRED.
   *
   * Bookings are not permitted.
   */
  Expired = 'EXPIRED',
  /**
   * Either seats or whole the flight
   * was sold and has been completed.
   *
   * Only BOOKED flights maybe COMPLETED.
   *
   * Bookings are not permitted.
   */
  Completed = 'COMPLETED',
  /**
   * The flight is closed for bookings.
   *
   * Only OPEN flights maybe CLOSED.
   */
  Closed = 'CLOSED',
  /**
   * The flight is cancelled for booking.
   *
   * Cancelled flights cannot be changed.
   */
  Cancelled = 'CANCELLED'
}

export interface AvailableFlightStatusFilterInput {
  ne?: Maybe<AvailableFlightStatus>;
  eq?: Maybe<AvailableFlightStatus>;
}

export enum AvailableFlightTab {
  Upcoming = 'upcoming',
  Past = 'past',
  All = 'all'
}

export interface BidHistory {
  status?: Maybe<PurchaseOfferStatusEnum>;
  aircraft?: Maybe<Aircraft>;
  acceptedBy?: Maybe<User>;
  effective?: Maybe<Scalars['IsoDateTime']>;
  superseded?: Maybe<Scalars['IsoDateTime']>;
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  offerPrice?: Maybe<Scalars['BigDecimal']>;
  operatorShare?: Maybe<Scalars['BigDecimal']>;
  brokerRate?: Maybe<Scalars['BigDecimal']>;
  brokerShare?: Maybe<Scalars['BigDecimal']>;
  quoteReferenceId?: Maybe<Scalars['String']>;
  legs?: Maybe<Array<Maybe<SourcingRequestLeg>>>;
  currencyDetails?: Maybe<CurrencyDetails>;
  offerPriceInCurrency?: Maybe<Scalars['BigDecimal']>;
  firmFlexDepartureFee?: Maybe<Scalars['BigDecimal']>;
  firmFlexDeparture?: Maybe<Scalars['Boolean']>;
}


export interface Booking {
  lastUpdated: Scalars['IsoDateTime'];
  passengerCount: Scalars['Int'];
  /** The pricing in effect when the booking was made.  Private charters do not have prices attached to bookings */
  price?: Maybe<ShuttlePricing>;
}

export interface BookingFilterInput {
  lastUpdated?: Maybe<ModelIsoDateTimeFilterInput>;
  passengerCount?: Maybe<ModelIntFilterInput>;
  price?: Maybe<ShuttlePricingFilterInput>;
}

export interface BrokerFeeRates {
  maxPrice?: Maybe<Scalars['BigDecimal']>;
  fulfillDynamicPrice?: Maybe<Scalars['BigDecimal']>;
  fulfillFixedPrice?: Maybe<Scalars['BigDecimal']>;
  publishWholeCharter?: Maybe<Scalars['BigDecimal']>;
  publishSharedCharter?: Maybe<Scalars['BigDecimal']>;
}

export interface BrokerFeeRatesInput {
  maxPrice?: Maybe<Scalars['BigDecimal']>;
  fulfillDynamicPrice?: Maybe<Scalars['BigDecimal']>;
  fulfillFixedPrice?: Maybe<Scalars['BigDecimal']>;
  publishWholeCharter?: Maybe<Scalars['BigDecimal']>;
  publishSharedCharter?: Maybe<Scalars['BigDecimal']>;
}

export interface CamberOption {
  perOccupiedHourCost?: Maybe<Scalars['BigDecimal']>;
  hoursBillableTotal?: Maybe<Scalars['BigDecimal']>;
  hoursOccupiedTotal?: Maybe<Scalars['BigDecimal']>;
  hoursUnoccupiedTotal?: Maybe<Scalars['BigDecimal']>;
  quotes?: Maybe<Array<Maybe<CamberOptionQuote>>>;
}

export interface CamberOptionQuote {
  aircraft?: Maybe<Aircraft>;
  efficiencyCost?: Maybe<EfficiencyCost>;
  rationale?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface CamberOptions {
  options: Array<Maybe<CamberOption>>;
}

export interface CancellationTerms {
  defaultTerms?: Maybe<Scalars['Boolean']>;
  terms?: Maybe<Scalars['String']>;
  operatorCancellationTerms?: Maybe<Scalars['String']>;
}

export interface CharterFlightEstimate {
  absoluteMinimum: CharterPricing;
  absoluteMaximum: CharterPricing;
  suggestedMinimum: CharterPricing;
  suggestedMaximum: CharterPricing;
}

export enum CharterPermissionsEnum {
  Ok = 'OK',
  RouteLimitExceeded = 'ROUTE_LIMIT_EXCEEDED'
}

export interface CharterPricing {
  /** The price offered to the customer for a seat. */
  flightPrice: Scalars['BigDecimal'];
  /** The amount remitted to the operator (broker share + federal tax cost + segment fee) */
  operatorRevenue: Scalars['BigDecimal'];
  /** The amount paid to the broker ( charter price + broker share ) */
  brokerRevenue: Scalars['BigDecimal'];
  /** The rate charged by the broker */
  brokerRate: Scalars['BigDecimal'];
  /** The price paid by the customer (charter cost + credit card cost). */
  customerPrice?: Maybe<Scalars['BigDecimal']>;
  /** Percent of the charter price collected by the broker (charter price * broker share ) */
  brokerShare: Scalars['BigDecimal'];
  /** Percent of the seat price collected by the operator (charter price - broker share ) */
  operatorShare: Scalars['BigDecimal'];
  /** Taxes paid to the Federal government */
  federalTaxCost?: Maybe<Scalars['BigDecimal']>;
  /** Per flight cost for segment fees */
  segmentFeeCost?: Maybe<Scalars['BigDecimal']>;
  /** The operator price for landing fees */
  landingFeePrice?: Maybe<Scalars['BigDecimal']>;
  /** The operator price for airport denisty fees */
  densityFeePrice?: Maybe<Scalars['BigDecimal']>;
  /** The operator price for repositioning */
  repositioningPrice?: Maybe<Scalars['BigDecimal']>;
  /** The date time that this price become in effect. */
  effective: Scalars['IsoDateTime'];
  /** The date time that this price was superseded with another price. */
  superseded?: Maybe<Scalars['IsoDateTime']>;
  /** The user that set this price */
  user: User;
  totalTaxes?: Maybe<Array<Maybe<TaxSnapshot>>>;
}

export interface CharterPricingFilterInput {
  charterPrice?: Maybe<ModelBigDecimalFilterInput>;
  operatorRevenue?: Maybe<ModelBigDecimalFilterInput>;
  brokerRevenue?: Maybe<ModelBigDecimalFilterInput>;
  customerPrice?: Maybe<ModelBigDecimalFilterInput>;
  brokerShare?: Maybe<ModelBigDecimalFilterInput>;
  operatorShare?: Maybe<ModelBigDecimalFilterInput>;
  federalTaxCost?: Maybe<ModelBigDecimalFilterInput>;
  segmentFeeCost?: Maybe<ModelBigDecimalFilterInput>;
  landingFeePrice?: Maybe<ModelBigDecimalFilterInput>;
  densityFeePrice?: Maybe<ModelBigDecimalFilterInput>;
  repositioningPrice?: Maybe<ModelBigDecimalFilterInput>;
  effective?: Maybe<ModelIsoDateTimeFilterInput>;
  superseded?: Maybe<ModelIsoDateTimeFilterInput>;
  user?: Maybe<UserFilterInput>;
}

export interface ClientDetails {
  clientName?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
}

export interface ClientDetailsFilterInput {
  clientName?: Maybe<ModelStringFilterInput>;
  clientId?: Maybe<ModelIntFilterInput>;
}

export interface ClientDetailsInput {
  clientName?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
}

export interface ClientDiscount {
  discountName?: Maybe<Scalars['String']>;
  discountValue?: Maybe<Scalars['Float']>;
  operatorId?: Maybe<Scalars['String']>;
}

export interface ClientMembershipStatus {
  clientMembershipStatus?: Maybe<Scalars['Boolean']>;
  clientBookingFee?: Maybe<Scalars['Int']>;
  clientDiscounts?: Maybe<Array<Maybe<ClientDiscount>>>;
}

export interface CompetitorInsight {
  aircraftCategoryName?: Maybe<Scalars['String']>;
  aircraftModelName?: Maybe<Scalars['String']>;
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  offerPrice?: Maybe<Scalars['BigDecimal']>;
}

export interface Configuration {
  name: Scalars['String'];
  value: Scalars['BigDecimal'];
  validFrom: Scalars['IsoDateTime'];
  validTo?: Maybe<Scalars['IsoDateTime']>;
}

export interface ConfigurationInput {
  name: Scalars['String'];
  value: Scalars['String'];
  validFrom: Scalars['IsoDateTime'];
  validTo?: Maybe<Scalars['IsoDateTime']>;
}

export interface Contact {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  quoteRequestEmail?: Maybe<Scalars['String']>;
}

export interface ContactDetails {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}

export interface ContactDetailsFilterInput {
  name?: Maybe<ModelStringFilterInput>;
  email?: Maybe<ModelStringFilterInput>;
}

export interface ContactDetailsInput {
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
}

export interface ContactDetailsPagination {
  leadOwners: Array<Maybe<ContactDetails>>;
  total?: Maybe<Scalars['Int']>;
}

export interface ContactDetailsSortInput {
  name?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
}

/** Create a new available flight */
export interface CreateAvailableFlightInput {
  /**
   * Indicates the type of flight
   * private, shared, repositioning
   */
  contractType: FlightContractType;
  /**
   * Indicates the pricing model
   * fixed or dynamic
   */
  priceType: FlightPriceEnum;
  departureTime: Scalars['IsoDateTime'];
  departureAirportCode: Scalars['String'];
  departureFboId?: Maybe<Scalars['Int']>;
  /**
   * Arrival time is only required
   * when creating empty legs. An
   * empty leg represents the time
   * when the aircraft should be
   * at the arrival airport/FBO.
   */
  arrivalTime?: Maybe<Scalars['IsoDateTime']>;
  /**
   * The arrival airport. Required
   * if the arrivalDboId is not.
   */
  arrivalAirportCode: Scalars['String'];
  /**
   * The arrival FBO.  Reuired
   * if arrivalAirportCode is not.
   */
  arrivalFboId?: Maybe<Scalars['Int']>;
  /**
   * The id of the aircraft
   * associated with this flight
   */
  aircraftId: Scalars['Int'];
  /** Estimated flight time in minutes */
  eft: Scalars['Int'];
  /**
   * The number of hours prior to
   * departure time that the flight
   * should expired and removed
   * from the market.
   */
  expirationOffset?: Maybe<Scalars['Int']>;
  /**
   * An external id or comment allows
   * correlation with operator processes.
   */
  externalId?: Maybe<Scalars['String']>;
  /**
   * The price the operator is charging
   * for a whole plane, required if
   * contract type is CHARTER
   */
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  /** The price the operator shares */
  operatorShare?: Maybe<Scalars['BigDecimal']>;
  /**
   * The price the operator is charging
   * per a seat, required if the
   * contract type is SHUTTLE
   */
  seatPrice?: Maybe<Scalars['BigDecimal']>;
  /**
   * The number of seats the
   * operator is offering for
   * sale, required if the contract
   * type is SHUTTLE
   */
  seatsOffered?: Maybe<Scalars['Int']>;
  /**
   * The hourly rate being
   * charged
   */
  flightRate?: Maybe<Scalars['BigDecimal']>;
  /**
   * Additional landing fees charged
   * for each segment
   */
  landingFee?: Maybe<Scalars['BigDecimal']>;
  flexibility?: Maybe<Scalars['Float']>;
}

export interface CreateFlightRequestInput {
  specialRequest?: Maybe<Scalars['String']>;
  lang?: Maybe<Scalars['String']>;
  timeIs24hrs?: Maybe<Scalars['String']>;
  clientId?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
  aircraftCategoryIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  req?: Maybe<Array<Maybe<RequestLeg>>>;
  createdByUserId?: Maybe<Scalars['Int']>;
  showXoDedicatedFleet?: Maybe<Scalars['Boolean']>;
  sendToXoMarketplace?: Maybe<Scalars['Boolean']>;
  allowFirmDeparture?: Maybe<Scalars['Boolean']>;
  offersRequireStaffApproval?: Maybe<Scalars['Boolean']>;
  elacProForma?: Maybe<Scalars['Boolean']>;
  petOnBoard?: Maybe<Scalars['Boolean']>;
  petNote?: Maybe<Scalars['String']>;
  sfOpportunityId?: Maybe<Scalars['String']>;
  travelReasonId?: Maybe<Scalars['String']>;
  specialRequirementIds?: Maybe<Array<Maybe<Scalars['String']>>>;
}

export interface CreateOffersResult {
  offers?: Maybe<Array<Maybe<PurchaseOffer>>>;
  errors?: Maybe<Array<Maybe<PurchaseOfferCreationError>>>;
}

export enum CreationType {
  ElacProForma = 'ELAC_PRO_FORMA',
  XoDedicatedFleet = 'XO_DEDICATED_FLEET',
  SearchOptions = 'SEARCH_OPTIONS',
  Manual = 'MANUAL',
  Automated = 'AUTOMATED',
  AutomatedForPreviewOrg = 'AUTOMATED_FOR_PREVIEW_ORG'
}

export interface CreationTypeFilterInput {
  eq?: Maybe<CreationType>;
  ne?: Maybe<CreationType>;
}

export enum Currency {
  Usd = 'USD',
  Eur = 'EUR',
  Gbp = 'GBP'
}

export interface CurrencyDetails {
  currency?: Maybe<Currency>;
  rate?: Maybe<Scalars['BigDecimal']>;
  effectiveTime?: Maybe<Scalars['IsoDateTime']>;
  updateTime?: Maybe<Scalars['IsoDateTime']>;
}

export interface CurrencyRate {
  id: Scalars['ID'];
  code: Scalars['String'];
  rate?: Maybe<Scalars['BigDecimal']>;
  effectiveTime?: Maybe<Scalars['IsoDateTime']>;
  updateTime?: Maybe<Scalars['IsoDateTime']>;
  syncTime?: Maybe<Scalars['IsoDateTime']>;
}

export interface CurrentUser {
  /** The current user ID */
  userId?: Maybe<Scalars['Int']>;
  /** The user type ID */
  userTypeId?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['Int']>;
  organizationUuid?: Maybe<Scalars['String']>;
  organizationLegalName?: Maybe<Scalars['String']>;
  /** The users first name */
  firstName?: Maybe<Scalars['String']>;
  /** The users last name */
  lastName?: Maybe<Scalars['String']>;
  /** The users email address */
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  active?: Maybe<Scalars['Boolean']>;
  activated?: Maybe<Scalars['Boolean']>;
  superuser?: Maybe<Scalars['Boolean']>;
  userEulaAccepted?: Maybe<Scalars['Boolean']>;
  operatorEulaAccepted?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<Maybe<Permission>>>;
}

export interface CustomerRequirements {
  yomMin?: Maybe<Scalars['Int']>;
  yorMin?: Maybe<Scalars['Int']>;
  amenities?: Maybe<CustomerRequirementsAmenities>;
  additionalInstructions?: Maybe<Scalars['String']>;
}

export interface CustomerRequirementsAmenities {
  name: Scalars['String'];
}

export interface CustomerRequirementsAmenityFilterInput {
  name: ModelStringFilterInput;
}

export interface CustomerRequirementsAmenityInput {
  name: Scalars['String'];
}

export interface CustomerRequirementsFilterInput {
  yomMin?: Maybe<ModelIntFilterInput>;
  yorMin?: Maybe<ModelIntFilterInput>;
  amenities?: Maybe<Array<CustomerRequirementsAmenityFilterInput>>;
  additionalInstructions?: Maybe<ModelStringFilterInput>;
}

export interface CustomerRequirementsInput {
  yomMin?: Maybe<Scalars['Int']>;
  yorMin?: Maybe<Scalars['Int']>;
  amenities?: Maybe<Array<CustomerRequirementsAmenityInput>>;
  additionalInstructions?: Maybe<Scalars['String']>;
}

export interface EalcPricing {
  price?: Maybe<Scalars['BigDecimal']>;
  categoryId?: Maybe<Scalars['Int']>;
  aircraftId?: Maybe<Scalars['Int']>;
  elacPriceInfo?: Maybe<ElacPriceInfo>;
}

export interface EfficiencyCost {
  perOccupiedHourCost?: Maybe<Scalars['BigDecimal']>;
  hoursUnoccupiedInbound?: Maybe<Scalars['BigDecimal']>;
  hoursOccupied?: Maybe<Scalars['BigDecimal']>;
  hoursUnoccupiedOutbound?: Maybe<Scalars['BigDecimal']>;
}

export interface EfficiencyCostInput {
  perOccupiedHourCost?: Maybe<Scalars['BigDecimal']>;
  hoursUnoccupiedInbound?: Maybe<Scalars['BigDecimal']>;
  hoursOccupied?: Maybe<Scalars['BigDecimal']>;
  hoursUnoccupiedOutbound?: Maybe<Scalars['BigDecimal']>;
}

export interface ElacPriceInfo {
  pricingSupportType?: Maybe<SourcingRequestPriceTypeEnum>;
  bestPrice?: Maybe<Scalars['Boolean']>;
  base?: Maybe<Scalars['BigDecimal']>;
  surcharges?: Maybe<Array<Maybe<Surcharge>>>;
  fixedPriceAlternateOption?: Maybe<PriceAlternateOption>;
}

export interface ElacPriceInfoInput {
  pricingSupportType?: Maybe<SourcingRequestPriceTypeEnum>;
  bestPrice?: Maybe<Scalars['Boolean']>;
  base?: Maybe<Scalars['BigDecimal']>;
  surcharges?: Maybe<Array<Maybe<SurchargeInput>>>;
  fixedPriceAlternateOption?: Maybe<PriceAlternateOptionInput>;
}


export interface EmailAddressFilterInput {
  ne?: Maybe<Scalars['EmailAddress']>;
  eq?: Maybe<Scalars['EmailAddress']>;
  lte?: Maybe<Scalars['EmailAddress']>;
  lt?: Maybe<Scalars['EmailAddress']>;
  gte?: Maybe<Scalars['EmailAddress']>;
  gt?: Maybe<Scalars['EmailAddress']>;
}

/** Defines the parameters for sending emails. */
export interface EmailConfig {
  /** The unique name to identify this configuration */
  name: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  /** The event that triggers when this email is generated. */
  event?: Maybe<EmailEvent>;
  /** The subject of this email. */
  subject?: Maybe<Scalars['String']>;
  /** The indicates that this email should be send to operator agents. (default: false) */
  notifyOperator?: Maybe<Scalars['Boolean']>;
  /** The indicates that this email should be archived to S3. (default: false) */
  archive?: Maybe<Scalars['Boolean']>;
  /** The list of recipients this email should go to. */
  recipients?: Maybe<Array<Scalars['EmailAddress']>>;
  /** The list of cc recipient this email should go to. */
  cc?: Maybe<Array<Scalars['EmailAddress']>>;
  /** The list of bcc recipient this email should go to. */
  bcc?: Maybe<Array<Scalars['EmailAddress']>>;
  /** The name of the context element in the template. */
  context: Scalars['String'];
  /** The list of attachments for this email */
  attachments?: Maybe<Array<EmailConfigAttachment>>;
  sendPushNotification?: Maybe<Scalars['Boolean']>;
  pushNotificationTitle?: Maybe<Scalars['String']>;
  pushNotificationBody?: Maybe<Scalars['String']>;
}

export interface EmailConfigAttachment {
  /** The name of the configuration to use for the attachment */
  name: Scalars['String'];
  /** The name that the attachment appears as in the email */
  attachmentName: Scalars['String'];
}

export interface EmailConfigAttachmentFilterInput {
  name?: Maybe<ModelStringFilterInput>;
  attachmentName?: Maybe<ModelStringFilterInput>;
}

export interface EmailConfigAttachmentInput {
  /** The name of the configuration to use for the attachment */
  name: Scalars['String'];
  /** The name that the attachment appears as in the email */
  attachmentName: Scalars['String'];
}

export interface EmailConfigFilterInput {
  name?: Maybe<ModelStringFilterInput>;
  template?: Maybe<ModelStringFilterInput>;
  event?: Maybe<EmailEventFilterInput>;
  archive?: Maybe<ModelBooleanFilterInput>;
  notifyOperator?: Maybe<ModelBooleanFilterInput>;
  subject?: Maybe<ModelStringFilterInput>;
  recipients?: Maybe<EmailAddressFilterInput>;
  cc?: Maybe<EmailAddressFilterInput>;
  bcc?: Maybe<EmailAddressFilterInput>;
  context?: Maybe<EmailAddressFilterInput>;
  attachments?: Maybe<EmailConfigAttachmentFilterInput>;
}

export interface EmailConfigInput {
  name: Scalars['String'];
  template?: Maybe<Scalars['String']>;
  templateName?: Maybe<Scalars['String']>;
  event?: Maybe<EmailEvent>;
  subject: Scalars['String'];
  /** The indicates that this email should be archived to S3. */
  archive?: Maybe<Scalars['Boolean']>;
  /** The indicates that this email should be send to operator agents. */
  notifyOperator: Scalars['Boolean'];
  /** The list of recipients this email should go to. */
  recipients?: Maybe<Array<Scalars['EmailAddress']>>;
  /** The list of cc recipient this email should go to. */
  cc?: Maybe<Array<Scalars['EmailAddress']>>;
  /** The list of bcc recipient this email should go to. */
  bcc?: Maybe<Array<Scalars['EmailAddress']>>;
  /** The name of the context element in the template. */
  context?: Maybe<Scalars['String']>;
  /** The list of attachments for this email */
  attachments?: Maybe<Array<EmailConfigAttachmentInput>>;
  sendPushNotification?: Maybe<Scalars['Boolean']>;
  pushNotificationTitle?: Maybe<Scalars['String']>;
  pushNotificationBody?: Maybe<Scalars['String']>;
}

export interface EmailConfigPagination {
  emailConfigs: Array<EmailConfig>;
  pagination?: Maybe<Pagination>;
}

export enum EmailEvent {
  PrivateCharterCreated = 'PRIVATE_CHARTER_CREATED',
  PrivateCharterCreatedExpire = 'PRIVATE_CHARTER_CREATED_EXPIRE',
  PrivateCharterBooked = 'PRIVATE_CHARTER_BOOKED',
  PrivateCharterBookedExpire = 'PRIVATE_CHARTER_BOOKED_EXPIRE',
  PrivateCharterPassengerUpdated = 'PRIVATE_CHARTER_PASSENGER_UPDATED',
  PrivateCharterCancelled = 'PRIVATE_CHARTER_CANCELLED',
  PrivateCharterStatusChanged = 'PRIVATE_CHARTER_STATUS_CHANGED',
  PrivateCharterUpdated = 'PRIVATE_CHARTER_UPDATED',
  PrivateCharterPreflight_24 = 'PRIVATE_CHARTER_PREFLIGHT_24',
  PrivateCharterPreflight_3 = 'PRIVATE_CHARTER_PREFLIGHT_3',
  PrivateCharterExpired = 'PRIVATE_CHARTER_EXPIRED',
  PrivateCharterCompleted = 'PRIVATE_CHARTER_COMPLETED',
  PrivateCharterPostflight = 'PRIVATE_CHARTER_POSTFLIGHT',
  SharedCharterCreated = 'SHARED_CHARTER_CREATED',
  SharedCharterCreatedExpire = 'SHARED_CHARTER_CREATED_EXPIRE',
  SharedCharterSeatsBooked = 'SHARED_CHARTER_SEATS_BOOKED',
  SharedCharterSeatsBookedExpire = 'SHARED_CHARTER_SEATS_BOOKED_EXPIRE',
  SharedCharterPassengerUpdated = 'SHARED_CHARTER_PASSENGER_UPDATED',
  SharedCharterSeatsCancelled = 'SHARED_CHARTER_SEATS_CANCELLED',
  SharedCharterStatusChanged = 'SHARED_CHARTER_STATUS_CHANGED',
  SharedCharterUpdated = 'SHARED_CHARTER_UPDATED',
  SharedCharterPreflight_24 = 'SHARED_CHARTER_PREFLIGHT_24',
  SharedCharterPreflight_3 = 'SHARED_CHARTER_PREFLIGHT_3',
  SharedCharterExpired = 'SHARED_CHARTER_EXPIRED',
  SharedCharterCompleted = 'SHARED_CHARTER_COMPLETED',
  SharedCharterPostflight = 'SHARED_CHARTER_POSTFLIGHT'
}

export interface EmailEventFilterInput {
  ne?: Maybe<EmailEvent>;
  eq?: Maybe<EmailEvent>;
}

export interface EqualBooleanFilterInput {
  eq: Scalars['Boolean'];
}

export interface EqualContainsContactDetailsFilterInput {
  name?: Maybe<EqualContainsStringFilterInput>;
  email?: Maybe<EqualContainsStringFilterInput>;
}

export interface EqualContainsStringFilterInput {
  eq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
}

export interface EqualIntFilterInput {
  eq: Scalars['Int'];
}

/**  only supported operation #################################### */
export interface EqualStringFilterInput {
  eq: Scalars['String'];
}

export interface Fbo {
  fboId: Scalars['Int'];
  name: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  officeHours?: Maybe<Scalars['String']>;
  address?: Maybe<Address>;
}

export interface FboFilterInput {
  id?: Maybe<ModelIdFilterInput>;
  fboId?: Maybe<ModelIntFilterInput>;
  name?: Maybe<ModelStringFilterInput>;
  phoneNumber?: Maybe<ModelStringFilterInput>;
  officeHours?: Maybe<ModelStringFilterInput>;
  address?: Maybe<AddressFilterInput>;
}

export enum FlightContractType {
  Shuttle = 'SHUTTLE',
  Charter = 'CHARTER'
}

export interface FlightContractTypeFilterInput {
  ne?: Maybe<FlightContractType>;
  eq?: Maybe<FlightContractType>;
}

export interface FlightEstimate {
  distance: Scalars['Int'];
  minimumEft: Scalars['Int'];
  maximumEft: Scalars['Int'];
  costPerHour?: Maybe<Scalars['Int']>;
  capacity?: Maybe<Scalars['Int']>;
  shuttlePricing?: Maybe<ShuttleFlightEstimate>;
  charterPricing?: Maybe<CharterFlightEstimate>;
}

export interface FlightEstimateInput {
  departureAirportCode: Scalars['String'];
  arrivalAirportCode: Scalars['String'];
  contractType?: Maybe<FlightContractType>;
  aircraftId?: Maybe<Scalars['Int']>;
  operatorUuid?: Maybe<Scalars['String']>;
}

export enum FlightPriceEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED'
}

export enum FlightPriceInputEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED'
}

export interface FlightPriceTypeFilterInput {
  ne?: Maybe<FlightPriceEnum>;
  eq?: Maybe<FlightPriceEnum>;
}

export interface FlightRequest {
  flightRequestId: Scalars['Int'];
  sourcingRequestId?: Maybe<Scalars['String']>;
  sourcingRequest?: Maybe<SourcingRequest>;
  client?: Maybe<ClientDetails>;
  aircraftCategories?: Maybe<Array<Maybe<AircraftCategory>>>;
  specialRequest?: Maybe<Scalars['String']>;
  createdDate?: Maybe<Scalars['IsoDateTime']>;
  updateTime?: Maybe<Scalars['IsoDateTime']>;
  legs?: Maybe<Array<Maybe<FlightRequestLeg>>>;
  sendToXOMarketplace?: Maybe<Scalars['Boolean']>;
  elacProForma?: Maybe<Scalars['Boolean']>;
  showXODedicatedFleet?: Maybe<Scalars['Boolean']>;
  allowFirmDeparture?: Maybe<Scalars['Boolean']>;
  offersRequireStaffApproval?: Maybe<Scalars['Boolean']>;
  podContact?: Maybe<ContactDetails>;
  ownerContact?: Maybe<ContactDetails>;
  state?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  createdByUserId?: Maybe<Scalars['Int']>;
  createdByUser?: Maybe<AuditUser>;
  salesAdvisor?: Maybe<SalesAdvisor>;
  petOnBoard?: Maybe<Scalars['Boolean']>;
  petNote?: Maybe<Scalars['String']>;
  sfOpportunityId?: Maybe<Scalars['String']>;
  travelReason?: Maybe<TravelReason>;
  specialRequirements?: Maybe<Array<Maybe<SpecialRequirement>>>;
}

export interface FlightRequestClientDetailsFilterInput {
  clientName?: Maybe<EqualContainsStringFilterInput>;
  clientId?: Maybe<EqualIntFilterInput>;
}

export interface FlightRequestFilterInput {
  client?: Maybe<FlightRequestClientDetailsFilterInput>;
  createdByUserId?: Maybe<EqualIntFilterInput>;
  itinerary?: Maybe<ItineraryFilterInput>;
  aircraftCategoryIds?: Maybe<InIntFilterInput>;
  salesAdvisor?: Maybe<FlightRequestSalesAdvisorInput>;
  showXODedicatedFleet?: Maybe<EqualBooleanFilterInput>;
  sendToXOMarketplace?: Maybe<EqualBooleanFilterInput>;
  elacProForma?: Maybe<EqualBooleanFilterInput>;
  allowFirmDeparture?: Maybe<EqualBooleanFilterInput>;
  offersRequireStaffApproval?: Maybe<EqualBooleanFilterInput>;
  podContact?: Maybe<EqualContainsContactDetailsFilterInput>;
  ownerContact?: Maybe<EqualContainsContactDetailsFilterInput>;
}

export interface FlightRequestLeg {
  departureDate?: Maybe<Scalars['String']>;
  departureTimeUTC?: Maybe<Scalars['String']>;
  pax?: Maybe<Scalars['Int']>;
  destinationAirportCode?: Maybe<Scalars['String']>;
  originAirportCode?: Maybe<Scalars['String']>;
  toIsTechStop?: Maybe<Scalars['Boolean']>;
}

export interface FlightRequestListItem {
  flightRequestId: Scalars['Int'];
  sourcingRequestId?: Maybe<Scalars['String']>;
  client?: Maybe<ClientDetails>;
  aircraftCategories?: Maybe<Array<Maybe<AircraftCategory>>>;
  createdDate?: Maybe<Scalars['IsoDateTime']>;
  legs?: Maybe<Array<Maybe<FlightRequestLeg>>>;
  state?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  createdByUser?: Maybe<AuditUser>;
  petOnBoard?: Maybe<Scalars['Boolean']>;
  petNote?: Maybe<Scalars['String']>;
  sfOpportunityId?: Maybe<Scalars['String']>;
}

export interface FlightRequestPagination {
  flightRequests: Array<Maybe<FlightRequestListItem>>;
  total?: Maybe<Scalars['Int']>;
}

export interface FlightRequestSalesAdvisorInput {
  id?: Maybe<EqualIntFilterInput>;
  name?: Maybe<EqualContainsStringFilterInput>;
  email?: Maybe<EqualContainsStringFilterInput>;
}

export interface FlightRequestSortInput {
  createdDate?: Maybe<Scalars['Boolean']>;
  updateTime?: Maybe<Scalars['Boolean']>;
  flightRequestId?: Maybe<Scalars['Boolean']>;
}

export interface FullMultiNgaPurchaseOfferCreateInput {
  offers?: Maybe<Array<MultiNgaPurchaseOfferCreateInput>>;
  sourcingRequestId: Scalars['String'];
}

export interface GaPurchaseOfferCreateInput {
  sourcingRequestId: Scalars['String'];
  operatorUuids: Array<Scalars['String']>;
  offerPrice?: Maybe<Scalars['BigDecimal']>;
  operatorComment?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
}

/** Provides Latitude and Longitude */
export interface Geolocation {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

export interface GeolocationFilterInput {
  latitude?: Maybe<ModelFloatFilterInput>;
  longitude?: Maybe<ModelFloatFilterInput>;
}

export enum GuaranteedTypeEnum {
  Ga = 'GA',
  Nga = 'NGA'
}

export interface GuaranteedTypeFilterInput {
  ne?: Maybe<GuaranteedTypeEnum>;
  eq?: Maybe<GuaranteedTypeEnum>;
}

export interface InIntFilterInput {
  in: Array<Scalars['Int']>;
}


export interface IsoDateFilterInput {
  lte?: Maybe<Scalars['IsoDateTime']>;
  lt?: Maybe<Scalars['IsoDateTime']>;
  gte?: Maybe<Scalars['IsoDateTime']>;
  gt?: Maybe<Scalars['IsoDateTime']>;
}


export interface ItineraryFilterInput {
  destinationAirportCode?: Maybe<EqualStringFilterInput>;
  originAirportCode?: Maybe<EqualStringFilterInput>;
  departureTime?: Maybe<IsoDateFilterInput>;
}

/**
 * Represents a segment of
 * a repositioning itinerary
 */
export interface ItinerarySegment {
  type: SegmentTypeEnum;
  /**
   * The length of this segment in
   * minutes
   */
  eft?: Maybe<Scalars['Int']>;
  /** The starting time for this segment */
  departureTime: Scalars['IsoDateTime'];
  /** The starting time for this segment in UTC */
  departureTimeUTC: Scalars['IsoDateTime'];
  /** The Airport departure of the segment */
  departureAirport?: Maybe<Airport>;
  /** The arrival time for this segment */
  arrivalTime: Scalars['IsoDateTime'];
  /** The arrival time for this segment in UTC */
  arrivalTimeUTC: Scalars['IsoDateTime'];
  /** The Airport arrival of the segment */
  arrivalAirport?: Maybe<Airport>;
  /**
   * The ID of the available flight associated with
   * the segment
   */
  availableFlightId?: Maybe<Scalars['String']>;
  /** The private or shared charter flight */
  flight?: Maybe<AvailableFlight>;
}

export interface ItinerarySegmentFilterInput {
  type?: Maybe<SegmentTypeFilterInput>;
  /**
   * The length of this segment in
   * minutes
   */
  eft?: Maybe<ModelIntFilterInput>;
  /** The starting time for this segment */
  departureTime?: Maybe<ModelIsoDateTimeFilterInput>;
  /** The starting time for this segment in UTC */
  departureTimeUTC?: Maybe<ModelIsoDateTimeFilterInput>;
  /** The Airport departure of the segment */
  departureAirport?: Maybe<AirportFilterInput>;
  /** The arrival time for this segment */
  arrivalTime?: Maybe<ModelIsoDateTimeFilterInput>;
  /** The arrival time for this segment in UTC */
  arrivalTimeUTC?: Maybe<ModelIsoDateTimeFilterInput>;
  /** The Airport arrival of the segment */
  arrivalAirport?: Maybe<AirportFilterInput>;
  /**
   * The ID of the available flight associated with
   * the segment
   */
  availableFlightId?: Maybe<ModelStringFilterInput>;
}

export enum LogicalOperator {
  And = 'AND',
  Or = 'OR'
}

export interface MarketplaceOption {
  operator?: Maybe<Operator>;
  aircraftCategory?: Maybe<AircraftCategory>;
  creationType?: Maybe<CreationType>;
}

export interface MarketplaceOptions {
  options?: Maybe<Array<Maybe<MarketplaceOption>>>;
}

export interface ModelBigDecimalFilterInput {
  ne?: Maybe<Scalars['BigDecimal']>;
  eq?: Maybe<Scalars['BigDecimal']>;
  lte?: Maybe<Scalars['BigDecimal']>;
  lt?: Maybe<Scalars['BigDecimal']>;
  gte?: Maybe<Scalars['BigDecimal']>;
  gt?: Maybe<Scalars['BigDecimal']>;
  /**   between: [ BigDecimal ] */
  exists?: Maybe<Scalars['Boolean']>;
}

export interface ModelBooleanFilterInput {
  ne?: Maybe<Scalars['Boolean']>;
  eq?: Maybe<Scalars['Boolean']>;
  exists?: Maybe<Scalars['Boolean']>;
}

export interface ModelFloatFilterInput {
  ne?: Maybe<Scalars['Float']>;
  eq?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  lt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  contains?: Maybe<Scalars['Float']>;
  /**
   * notContains: Float
   * between: [Float]
   */
  exists?: Maybe<Scalars['Boolean']>;
}

export interface ModelIdFilterInput {
  ne?: Maybe<Scalars['ID']>;
  eq?: Maybe<Scalars['ID']>;
  lte?: Maybe<Scalars['ID']>;
  lt?: Maybe<Scalars['ID']>;
  gte?: Maybe<Scalars['ID']>;
  gt?: Maybe<Scalars['ID']>;
  contains?: Maybe<Scalars['ID']>;
  /**
   * notContains: ID
   * between: [ID]
   */
  startsWith?: Maybe<Scalars['ID']>;
  exists?: Maybe<Scalars['Boolean']>;
}

export interface ModelIntFilterInput {
  ne?: Maybe<Scalars['Int']>;
  eq?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  lt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  /**  contains: Int */
  in?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /**
   * notContains: Int
   * between: [Int]
   */
  exists?: Maybe<Scalars['Boolean']>;
}

export interface ModelIsoDateFilterInput {
  ne?: Maybe<Scalars['IsoDate']>;
  eq?: Maybe<Scalars['IsoDate']>;
  lte?: Maybe<Scalars['IsoDate']>;
  lt?: Maybe<Scalars['IsoDate']>;
  gte?: Maybe<Scalars['IsoDate']>;
  gt?: Maybe<Scalars['IsoDate']>;
  /**   between: [IsoDate] */
  exists?: Maybe<Scalars['Boolean']>;
}

export interface ModelIsoDateTimeFilterInput {
  ne?: Maybe<Scalars['IsoDateTime']>;
  eq?: Maybe<Scalars['IsoDateTime']>;
  lte?: Maybe<Scalars['IsoDateTime']>;
  lt?: Maybe<Scalars['IsoDateTime']>;
  gte?: Maybe<Scalars['IsoDateTime']>;
  gt?: Maybe<Scalars['IsoDateTime']>;
  /**   between: [IsoDateTime] */
  exists?: Maybe<Scalars['Boolean']>;
}

export interface ModelStringFilterInput {
  ne?: Maybe<Scalars['String']>;
  eq?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  lt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * notContains: String
   * between: [String]
   */
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  exists?: Maybe<Scalars['Boolean']>;
}

export interface ModeratedOffer {
  approverComment?: Maybe<Scalars['String']>;
  selectedSendOffers?: Maybe<Array<Maybe<SelectedOffer>>>;
  user?: Maybe<User>;
  dateTime?: Maybe<Scalars['IsoDateTime']>;
  showYor?: Maybe<Scalars['Boolean']>;
  showYom?: Maybe<Scalars['Boolean']>;
}

export interface ModeratedOfferInput {
  operatorPurchaseOfferId?: Maybe<Scalars['String']>;
  recommendedOffer?: Maybe<Scalars['Boolean']>;
  offerComment?: Maybe<Scalars['String']>;
}

export interface MultiNgaPurchaseOfferCreateInput {
  aircraftId?: Maybe<Scalars['Int']>;
  operatorUuid: Scalars['String'];
  aircraftCategoryId?: Maybe<Scalars['Int']>;
  operatorComment?: Maybe<Scalars['String']>;
  efficiencyCosts?: Maybe<Array<Maybe<EfficiencyCostInput>>>;
}

export interface Mutation {
  acceptUserAgreement?: Maybe<User>;
  acceptOperatorAgreement?: Maybe<Operator>;
  createAvailableFlight?: Maybe<AvailableFlight>;
  updateAvailableFlight: AvailableFlight;
  /** Removes an email config definition */
  removeEmailConfig?: Maybe<Scalars['Boolean']>;
  removeAuditLogEvent?: Maybe<Scalars['Boolean']>;
  removeAvailableFlights?: Maybe<Scalars['Boolean']>;
  removeOperator?: Maybe<Scalars['Boolean']>;
  removeUser?: Maybe<Scalars['Boolean']>;
  /** Updates or creates an email configuration */
  putEmailConfig?: Maybe<EmailConfig>;
  /** Updates or creates a configuration */
  putConfiguration?: Maybe<Configuration>;
  /** Creates or updates a template based on template name */
  putTemplate?: Maybe<Template>;
  /** Creates or updates an user */
  putUser?: Maybe<User>;
  /** Create user device token */
  addUserDeviceToken?: Maybe<Array<Maybe<UserToken>>>;
  /** Activates cluster for backend processes */
  activateProcesses?: Maybe<Scalars['Int']>;
  acceptGaPurchaseOffer: PurchaseOffer;
  acceptNgaPurchaseOffer: PurchaseOffer;
  acceptPurchaseOffer: PurchaseOffer;
  cancelPurchaseOffer: PurchaseOffer;
  createNgaPurchaseOffer: Array<Maybe<PurchaseOffer>>;
  createMultiNgaPurchaseOffers: CreateOffersResult;
  cloneNgaPurchaseOffer: PurchaseOffer;
  acceptNgaDeferredOffer: PurchaseOffer;
  createGaPurchaseOffer: Array<Maybe<PurchaseOffer>>;
  declinePurchaseOffer: PurchaseOffer;
  selectPurchaseOffer: PurchaseOffer;
  takeOnFleetPurchaseOffer: PurchaseOffer;
  cancelSourcingRequest: SourcingRequest;
  createSourcingRequest: SourcingRequest;
  createSourcingRequestFromFlightRequest: SourcingRequest;
  putSourcingRequest: SourcingRequest;
  sendModeratedOffers: SourcingRequest;
  updateModeratedOfferOperatorComment: PurchaseOffer;
  removeSourcingRequest?: Maybe<Scalars['Boolean']>;
  removeStompNotifications?: Maybe<Scalars['Boolean']>;
  proFormaBook: ProFormaBookResult;
  createFlightRequest?: Maybe<Scalars['Int']>;
  sendUserMessage?: Maybe<Scalars['Boolean']>;
}


export interface MutationCreateAvailableFlightArgs {
  input?: Maybe<CreateAvailableFlightInput>;
}


export interface MutationUpdateAvailableFlightArgs {
  input: UpdateAvailableFlightInput;
}


export interface MutationRemoveEmailConfigArgs {
  name: Scalars['String'];
}


export interface MutationRemoveAuditLogEventArgs {
  filter: AuditLogEventFilterInput;
}


export interface MutationRemoveAvailableFlightsArgs {
  filter: AvailableFlightFilterInput;
}


export interface MutationRemoveOperatorArgs {
  operatorId: Scalars['Int'];
}


export interface MutationRemoveUserArgs {
  userId: Scalars['Int'];
}


export interface MutationPutEmailConfigArgs {
  input: EmailConfigInput;
}


export interface MutationPutConfigurationArgs {
  input: ConfigurationInput;
}


export interface MutationPutTemplateArgs {
  input?: Maybe<TemplateInput>;
}


export interface MutationPutUserArgs {
  input: UserInput;
}


export interface MutationAddUserDeviceTokenArgs {
  input: UserTokenInput;
}


export interface MutationActivateProcessesArgs {
  nameRegEx: Scalars['String'];
  domain?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
}


export interface MutationAcceptGaPurchaseOfferArgs {
  input: PurchaseOfferGaAcceptInput;
}


export interface MutationAcceptNgaPurchaseOfferArgs {
  input: PurchaseOfferNgaAcceptInput;
}


export interface MutationAcceptPurchaseOfferArgs {
  input: PurchaseOfferAcceptInput;
}


export interface MutationCancelPurchaseOfferArgs {
  purchaseOfferId: Scalars['String'];
}


export interface MutationCreateNgaPurchaseOfferArgs {
  input: NgaPurchaseOfferCreateInput;
}


export interface MutationCreateMultiNgaPurchaseOffersArgs {
  input: FullMultiNgaPurchaseOfferCreateInput;
}


export interface MutationCloneNgaPurchaseOfferArgs {
  sourceOfferId: Scalars['String'];
}


export interface MutationAcceptNgaDeferredOfferArgs {
  purchaseOfferId: Scalars['String'];
}


export interface MutationCreateGaPurchaseOfferArgs {
  input: GaPurchaseOfferCreateInput;
}


export interface MutationDeclinePurchaseOfferArgs {
  purchaseOfferId: Scalars['String'];
}


export interface MutationSelectPurchaseOfferArgs {
  purchaseOfferId?: Maybe<Scalars['String']>;
}


export interface MutationTakeOnFleetPurchaseOfferArgs {
  purchaseOfferId: Scalars['String'];
  newOperatorId: Scalars['String'];
}


export interface MutationCancelSourcingRequestArgs {
  sourcingRequestId?: Maybe<Scalars['String']>;
}


export interface MutationCreateSourcingRequestArgs {
  input: SourcingRequestInput;
}


export interface MutationCreateSourcingRequestFromFlightRequestArgs {
  flightRequestId: Scalars['Int'];
}


export interface MutationPutSourcingRequestArgs {
  input: SourcingRequestInput;
}


export interface MutationSendModeratedOffersArgs {
  input: SendApprovedOffersInput;
}


export interface MutationUpdateModeratedOfferOperatorCommentArgs {
  purchaseOfferId: Scalars['String'];
  comment: Scalars['String'];
}


export interface MutationUpdateModeratedOfferOperatorCommentArgs {
  purchaseOfferId: Scalars['String'];
  comment: Scalars['String'];
}


export interface MutationRemoveSourcingRequestArgs {
  filter: SourcingRequestFilterInput;
}


export interface MutationRemoveStompNotificationsArgs {
  filter: StompNotificationFilterInput;
}


export interface MutationProFormaBookArgs {
  input: ProFormaInput;
}


export interface MutationCreateFlightRequestArgs {
  input?: Maybe<CreateFlightRequestInput>;
}


export interface MutationSendUserMessageArgs {
  user: Scalars['String'];
  message?: Maybe<Scalars['String']>;
}

export interface NgaPurchaseOfferCreateInput {
  sourcingRequestId: Scalars['String'];
  operatorUuids: Array<Scalars['String']>;
  aircraftCategoryIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  operatorComment?: Maybe<Scalars['String']>;
}

export interface OfferExpirationTime {
  maxHoursToDeparture?: Maybe<Scalars['Int']>;
  durationHours?: Maybe<Scalars['Int']>;
  expirationHours?: Maybe<Scalars['Int']>;
}

export interface OfferValidationResult {
  operator?: Maybe<Operator>;
  aircraft?: Maybe<Aircraft>;
  aircraftCategory?: Maybe<AircraftCategory>;
  code?: Maybe<ValidationCodeEnum>;
  message?: Maybe<Scalars['String']>;
}

export interface OfferValidationResults {
  errors?: Maybe<Array<Maybe<OfferValidationResult>>>;
  warnings?: Maybe<Array<Maybe<OfferValidationResult>>>;
}

export interface Operator {
  name?: Maybe<Scalars['String']>;
  legalName?: Maybe<Scalars['String']>;
  legalNamePca?: Maybe<Scalars['String']>;
  dotName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  disclaimer?: Maybe<Scalars['String']>;
  dba?: Maybe<Scalars['String']>;
  /** Indicates that the operator should be sent offers before other operators */
  offerPreviews?: Maybe<Scalars['Boolean']>;
  /** Indicates that the operator should automatically receive fulfullment offers */
  automatedSourcingForGuaranteed?: Maybe<Scalars['Boolean']>;
  /**
   * Indicates that the operator participates
   * in bidding for dynamic priced charters
   */
  dynamicPriceFulfillment?: Maybe<Scalars['Boolean']>;
  /**
   * Indicates that this operator can run more then four shared charters a week
   * on a route between airports.
   */
  allowShuttles?: Maybe<Scalars['Boolean']>;
  eulaAccepted?: Maybe<Scalars['Boolean']>;
  eulaAcceptedBy?: Maybe<User>;
  aircraft?: Maybe<Array<Maybe<Aircraft>>>;
  users?: Maybe<Array<Maybe<User>>>;
  active?: Maybe<Scalars['Boolean']>;
  addressLine1?: Maybe<Scalars['String']>;
  addressLine2?: Maybe<Scalars['String']>;
  addressLine3?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  stateProvince?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  phoneNumber1?: Maybe<Scalars['String']>;
  phoneNumber2?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['IsoDateTime']>;
  aircraftOperatorId?: Maybe<Scalars['Int']>;
  operatorId?: Maybe<Scalars['Int']>;
  operatorUuid?: Maybe<Scalars['String']>;
  intacctId?: Maybe<Scalars['String']>;
  intacctName?: Maybe<Scalars['String']>;
  dotFilingsEmail?: Maybe<Scalars['String']>;
  chartersEmail?: Maybe<Scalars['String']>;
  emptyLegsEmail?: Maybe<Scalars['String']>;
  fulfillEmail?: Maybe<Scalars['String']>;
  shuttlesEmail?: Maybe<Scalars['String']>;
  websiteUrl?: Maybe<Scalars['String']>;
  argusRating?: Maybe<Rating>;
  wyvernRating?: Maybe<Rating>;
  isBaoRating?: Maybe<Rating>;
  levelFlightRating?: Maybe<Rating>;
  documents?: Maybe<Array<Maybe<OperatorDocument>>>;
  prospectus?: Maybe<Prospectus>;
  contact?: Maybe<Contact>;
  serviceAreas?: Maybe<Array<Maybe<ServiceArea>>>;
  aircraftsRegistrationCountryCode?: Maybe<Scalars['String']>;
  cancellationTerms?: Maybe<Scalars['String']>;
  draftCancellationTerms?: Maybe<Scalars['String']>;
  brokerRates?: Maybe<Array<Maybe<BrokerFeeRates>>>;
  dedicatedFleet?: Maybe<Scalars['Boolean']>;
  aircraftCategories?: Maybe<Array<Maybe<AircraftCategory>>>;
  offerExpirationTime?: Maybe<Array<Maybe<OfferExpirationTime>>>;
  automatedOfferCreateConfigurations?: Maybe<AutomatedOfferCreateConfigurations>;
  externalNote?: Maybe<Scalars['String']>;
}

export interface OperatorAircraftCategory {
  aircraftCategoryId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  namePlural?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
}

export interface OperatorDocument {
  operatorDocumentId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  status?: Maybe<OperatorDocumentStatus>;
  docTypeId?: Maybe<Scalars['Int']>;
}

export enum OperatorDocumentStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export interface OperatorFilterInput {
  name?: Maybe<ModelStringFilterInput>;
  legalName?: Maybe<ModelStringFilterInput>;
  legalNamePca?: Maybe<ModelStringFilterInput>;
  dotName?: Maybe<ModelStringFilterInput>;
  description?: Maybe<ModelStringFilterInput>;
  disclaimer?: Maybe<ModelStringFilterInput>;
  dba?: Maybe<ModelStringFilterInput>;
  /** Indicates that the operator should be sent offers before other operators */
  offerPreviews?: Maybe<ModelBooleanFilterInput>;
  /** Indicates that the operator should automatically receive fulfullment offers */
  automatedSourcingForGuaranteed?: Maybe<ModelBooleanFilterInput>;
  /**
   * Indicates that the operator participates
   * in bidding for dynamic priced charters
   */
  dynamicPriceFulfillment?: Maybe<ModelBooleanFilterInput>;
  /**
   * Indicates that this operator can run more then four shared charters a week
   * on a route between airports.
   */
  allowShuttles?: Maybe<ModelBooleanFilterInput>;
  eulaAccepted?: Maybe<ModelBooleanFilterInput>;
  eulaAcceptedBy?: Maybe<UserFilterInput>;
  aircraftCategoryIds?: Maybe<ModelIntFilterInput>;
  active?: Maybe<ModelBooleanFilterInput>;
  addressLine1?: Maybe<ModelStringFilterInput>;
  addressLine2?: Maybe<ModelStringFilterInput>;
  addressLine3?: Maybe<ModelStringFilterInput>;
  city?: Maybe<ModelStringFilterInput>;
  countryCode?: Maybe<ModelStringFilterInput>;
  stateProvince?: Maybe<ModelStringFilterInput>;
  postalCode?: Maybe<ModelStringFilterInput>;
  phoneNumber1?: Maybe<ModelStringFilterInput>;
  phoneNumber2?: Maybe<ModelStringFilterInput>;
  created?: Maybe<Scalars['IsoDateTime']>;
  aircraftOperatorId?: Maybe<ModelIntFilterInput>;
  operatorId?: Maybe<ModelIntFilterInput>;
  operatorUuid?: Maybe<ModelStringFilterInput>;
  intacctId?: Maybe<ModelStringFilterInput>;
  intacctName?: Maybe<ModelStringFilterInput>;
  dotFilingsEmail?: Maybe<ModelStringFilterInput>;
  chartersEmail?: Maybe<ModelStringFilterInput>;
  emptyLegsEmail?: Maybe<ModelStringFilterInput>;
  fulfillEmail?: Maybe<ModelStringFilterInput>;
  shuttlesEmail?: Maybe<ModelStringFilterInput>;
  websiteUrl?: Maybe<ModelStringFilterInput>;
  automatedOfferCreateConfigurations?: Maybe<AutomatedOfferCreateConfigurationsFilterInput>;
  and?: Maybe<Array<Maybe<OperatorFilterInput>>>;
  or?: Maybe<Array<Maybe<OperatorFilterInput>>>;
}

export interface OperatorPagination {
  operators: Array<Operator>;
  pagination?: Maybe<Pagination>;
}

export interface Pagination {
  count?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}

export interface PaginationInput {
  skip: Scalars['Int'];
  limit: Scalars['Int'];
}

export interface Passenger {
  legalName?: Maybe<Scalars['String']>;
  dateOfBirth?: Maybe<Scalars['IsoDate']>;
  price: ShuttlePricing;
  comments?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
}

export interface PassengerFilterInput {
  legalName?: Maybe<ModelStringFilterInput>;
  dateOfBirth?: Maybe<ModelIsoDateFilterInput>;
  price?: Maybe<ShuttlePricingFilterInput>;
  comments?: Maybe<ModelStringFilterInput>;
  weight?: Maybe<ModelIntFilterInput>;
}

export interface Permission {
  name?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
}

export interface PriceAlternateOption {
  price?: Maybe<Scalars['BigDecimal']>;
  pricingSupportType?: Maybe<SourcingRequestPriceTypeEnum>;
  bestPrice?: Maybe<Scalars['Boolean']>;
  base?: Maybe<Scalars['BigDecimal']>;
  surcharges?: Maybe<Array<Maybe<Surcharge>>>;
}

export interface PriceAlternateOptionInput {
  price?: Maybe<Scalars['BigDecimal']>;
  pricingSupportType?: Maybe<SourcingRequestPriceTypeEnum>;
  bestPrice?: Maybe<Scalars['Boolean']>;
  surcharges?: Maybe<Array<Maybe<SurchargeInput>>>;
  base?: Maybe<Scalars['BigDecimal']>;
}

export interface PriceBreakdown {
  operatorPrice?: Maybe<Scalars['BigDecimal']>;
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  commission?: Maybe<Scalars['BigDecimal']>;
}

export interface PriceDetailsInput {
  basePrice: Scalars['BigDecimal'];
  seatsCount?: Maybe<Scalars['Int']>;
  departureAirportCode: Scalars['AirportCodeInput'];
  arrivalAirportCode: Scalars['AirportCodeInput'];
  /** The anticipated departure time, allows determination of whether the flight is permitted. */
  departureTime?: Maybe<Scalars['IsoDateTime']>;
  arrivalTime?: Maybe<Scalars['IsoDateTime']>;
  eft?: Maybe<Scalars['Int']>;
  operatorUuid?: Maybe<Scalars['String']>;
}

export interface ProFormaBookResult {
  status?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
}

export interface ProFormaInput {
  offerId: Scalars['String'];
  priceToValidate: Scalars['BigDecimal'];
}

export interface Prospectus {
  approved?: Maybe<Scalars['Boolean']>;
  prospectusNumber?: Maybe<Scalars['String']>;
  aocNumber?: Maybe<Scalars['String']>;
  approvalDate?: Maybe<Scalars['IsoDate']>;
  expirationDate?: Maybe<Scalars['IsoDate']>;
}

export interface PurchaseOffer {
  operatorPurchaseOfferId?: Maybe<Scalars['String']>;
  sourcingRequestId?: Maybe<Scalars['String']>;
  sourcingRequest?: Maybe<SourcingRequest>;
  expirationDate?: Maybe<Scalars['IsoDateTime']>;
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  offerPrice?: Maybe<Scalars['BigDecimal']>;
  operatorShare?: Maybe<Scalars['BigDecimal']>;
  brokerRate?: Maybe<Scalars['BigDecimal']>;
  brokerShare?: Maybe<Scalars['BigDecimal']>;
  commission?: Maybe<Scalars['BigDecimal']>;
  aircraftCategories: Array<AircraftCategory>;
  aircraftTypes?: Maybe<Array<Maybe<AircraftType>>>;
  legs: Array<SourcingRequestLeg>;
  status: PurchaseOfferStatusEnum;
  priceType: PurchaseOfferPriceTypeEnum;
  aircraftId?: Maybe<Scalars['Int']>;
  aircraft?: Maybe<Aircraft>;
  operatorUuid?: Maybe<Scalars['String']>;
  operator?: Maybe<Operator>;
  customerRequirements?: Maybe<CustomerRequirements>;
  dba?: Maybe<Scalars['String']>;
  offerCount?: Maybe<Scalars['Int']>;
  bidHistory?: Maybe<Array<Maybe<BidHistory>>>;
  charterAgreementPDF?: Maybe<Scalars['String']>;
  createdBy?: Maybe<AuditUser>;
  createdDate?: Maybe<Scalars['IsoDateTime']>;
  acceptedBy?: Maybe<AuditUser>;
  acceptedDate?: Maybe<Scalars['IsoDateTime']>;
  declinedBy?: Maybe<AuditUser>;
  declinedDate?: Maybe<Scalars['IsoDateTime']>;
  cancelledBy?: Maybe<AuditUser>;
  cancelledDate?: Maybe<Scalars['IsoDateTime']>;
  lastModifiedBy?: Maybe<AuditUser>;
  lastModifiedDate?: Maybe<Scalars['IsoDateTime']>;
  takenOnFleetBy?: Maybe<AuditUser>;
  takenOnFleetDate?: Maybe<Scalars['IsoDateTime']>;
  takenOffFleetBy?: Maybe<AuditUser>;
  takenOffFleetDate?: Maybe<Scalars['IsoDateTime']>;
  deferredDateTill?: Maybe<Scalars['IsoDateTime']>;
  competitorInsights?: Maybe<Array<Maybe<CompetitorInsight>>>;
  acceptOperatorCancellationTerms?: Maybe<Scalars['String']>;
  offersRequireStaffApproval?: Maybe<Scalars['Boolean']>;
  ownerAircraftApprovalAcquired?: Maybe<Scalars['Boolean']>;
  recommended?: Maybe<Scalars['String']>;
  offerComment?: Maybe<Scalars['String']>;
  bidApprovedBy?: Maybe<AuditUser>;
  type?: Maybe<GuaranteedTypeEnum>;
  quoteReferenceId?: Maybe<Scalars['String']>;
  offerApproved?: Maybe<Scalars['Boolean']>;
  operatorComment?: Maybe<Scalars['String']>;
  currencyDetails?: Maybe<CurrencyDetails>;
  offerPriceInCurrency?: Maybe<Scalars['BigDecimal']>;
  firmFlexDepartureFee?: Maybe<Scalars['BigDecimal']>;
  firmFlexDeparture?: Maybe<Scalars['Boolean']>;
  creationType?: Maybe<CreationType>;
  /** @deprecated use elac field, remove after OP-1985 */
  fuelSurcharge?: Maybe<Scalars['BigDecimal']>;
  /** @deprecated use elac field, remove after OP-1985 */
  basePrice?: Maybe<Scalars['BigDecimal']>;
  elacPriceInfo?: Maybe<ElacPriceInfo>;
  efficiencyCosts?: Maybe<Array<Maybe<EfficiencyCost>>>;
}

/** @deprecated #TODO remove. User PurchaseOfferGaAcceptInput or PurchaseOfferNgaAcceptInput */
export interface PurchaseOfferAcceptInput {
  operatorPurchaseOfferId: Scalars['String'];
  aircraftId: Scalars['AircraftIdInput'];
  offerPrice?: Maybe<Scalars['BigDecimal']>;
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  legs?: Maybe<Array<Maybe<PurchaseOfferLegInput>>>;
  quoteReferenceId?: Maybe<Scalars['String']>;
  operatorComment?: Maybe<Scalars['String']>;
  ownerAircraftApprovalAcquired?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
}

export interface PurchaseOfferCreationError {
  sourcingRequest?: Maybe<SourcingRequest>;
  aircraft?: Maybe<Aircraft>;
  operator?: Maybe<Operator>;
  aircraftCategory?: Maybe<AircraftCategory>;
  errorMessage?: Maybe<Scalars['String']>;
}

export interface PurchaseOfferFilterInput {
  operatorPurchaseOfferId?: Maybe<ModelStringFilterInput>;
  sourcingRequestId?: Maybe<ModelStringFilterInput>;
  expirationDate?: Maybe<ModelIsoDateTimeFilterInput>;
  flightPrice?: Maybe<ModelBigDecimalFilterInput>;
  offerPrice?: Maybe<ModelBigDecimalFilterInput>;
  commission?: Maybe<ModelBigDecimalFilterInput>;
  aircraftCategories?: Maybe<AircraftCategoryFilterInput>;
  aircraftTypes?: Maybe<AircraftTypeFilterInput>;
  status?: Maybe<PurchaseOfferStatusFilterInput>;
  priceType?: Maybe<PurchaseOfferPriceTypeFilterInput>;
  operator?: Maybe<OperatorFilterInput>;
  aircraft?: Maybe<AircraftFilterInput>;
  customerRequirements?: Maybe<CustomerRequirementsFilterInput>;
  createdBy?: Maybe<UserFilterInput>;
  createdDate?: Maybe<ModelIsoDateTimeFilterInput>;
  acceptedBy?: Maybe<UserFilterInput>;
  acceptedDate?: Maybe<ModelIsoDateTimeFilterInput>;
  declinedBy?: Maybe<UserFilterInput>;
  declinedDate?: Maybe<ModelIsoDateTimeFilterInput>;
  lastModifiedBy?: Maybe<UserFilterInput>;
  lastModifiedDate?: Maybe<ModelIsoDateTimeFilterInput>;
  deferredDateTill?: Maybe<ModelIsoDateTimeFilterInput>;
  type?: Maybe<GuaranteedTypeFilterInput>;
  quoteReferenceId?: Maybe<ModelStringFilterInput>;
  creationType?: Maybe<CreationTypeFilterInput>;
}

export interface PurchaseOfferGaAcceptInput {
  operatorPurchaseOfferId: Scalars['String'];
  aircraftId: Scalars['AircraftIdInput'];
  legs?: Maybe<Array<Maybe<PurchaseOfferLegInput>>>;
  ownerAircraftApprovalAcquired?: Maybe<Scalars['Boolean']>;
}

export interface PurchaseOfferInput {
  operatorPurchaseOfferId?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['IsoDateTime']>;
  aircraftCategoryIds: Array<Scalars['Int']>;
  aircraftTypeIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  sourcingRequestId?: Maybe<Scalars['String']>;
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  offerPrice?: Maybe<Scalars['BigDecimal']>;
  commission?: Maybe<Scalars['BigDecimal']>;
  status: PurchaseOfferStatusEnum;
  operatorId: Scalars['Int'];
  customerRequirements?: Maybe<CustomerRequirementsInput>;
}

export interface PurchaseOfferLegInput {
  departureTimeUTC: Scalars['IsoDateTime'];
  departureAirportCode: Scalars['String'];
  departureFboId?: Maybe<Scalars['Int']>;
  arrivalAirportCode: Scalars['String'];
  arrivalFboId?: Maybe<Scalars['Int']>;
  eft?: Maybe<Scalars['Int']>;
  newDepartureTime?: Maybe<Scalars['IsoDateTime']>;
  newArrivalAirportCode?: Maybe<Scalars['String']>;
  newDepartureAirportCode?: Maybe<Scalars['String']>;
  toIsTechStop?: Maybe<Scalars['Boolean']>;
}

export interface PurchaseOfferNgaAcceptInput {
  operatorPurchaseOfferId: Scalars['String'];
  aircraftId: Scalars['AircraftIdInput'];
  offerPrice?: Maybe<Scalars['BigDecimal']>;
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  legs?: Maybe<Array<Maybe<PurchaseOfferLegInput>>>;
  quoteReferenceId?: Maybe<Scalars['String']>;
  operatorComment?: Maybe<Scalars['String']>;
  ownerAircraftApprovalAcquired?: Maybe<Scalars['Boolean']>;
  currency?: Maybe<Currency>;
  firmFlexDepartureFee?: Maybe<Scalars['BigDecimal']>;
  firmFlexDeparture?: Maybe<Scalars['Boolean']>;
  elacPriceInfo?: Maybe<ElacPriceInfoInput>;
}

export interface PurchaseOfferPagination {
  purchaseOffers: Array<PurchaseOffer>;
  pagination?: Maybe<Pagination>;
}

export interface PurchaseOfferPrice {
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  offerPrice?: Maybe<Scalars['BigDecimal']>;
  commission?: Maybe<Scalars['BigDecimal']>;
  effective?: Maybe<Scalars['IsoDateTime']>;
  superseded?: Maybe<Scalars['IsoDateTime']>;
}

export enum PurchaseOfferPriceTypeEnum {
  Fixed = 'FIXED',
  Dynamic = 'DYNAMIC'
}

export interface PurchaseOfferPriceTypeFilterInput {
  ne?: Maybe<PurchaseOfferPriceTypeEnum>;
  eq?: Maybe<PurchaseOfferPriceTypeEnum>;
}

export interface PurchaseOfferSortingInput {
  legs?: Maybe<SourcingRequesSortingLegInput>;
  offerPrice?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Boolean']>;
  quoteReferenceId?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['Boolean']>;
}

export enum PurchaseOfferStatusEnum {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Accepted = 'ACCEPTED',
  Declined = 'DECLINED',
  Expired = 'EXPIRED',
  Bid = 'BID',
  BidApproved = 'BID_APPROVED',
  Deferred = 'DEFERRED',
  TakenOnFleet = 'TAKEN_ON_FLEET'
}

export interface PurchaseOfferStatusFilterInput {
  ne?: Maybe<PurchaseOfferStatusEnum>;
  eq?: Maybe<PurchaseOfferStatusEnum>;
  in?: Maybe<Array<Maybe<PurchaseOfferStatusEnum>>>;
}

export interface Query {
  calculateSharedCharterPrice?: Maybe<ShuttlePricing>;
  calculatePrivateCharterPrice?: Maybe<CharterPricing>;
  getAircraft?: Maybe<Aircraft>;
  getAirport?: Maybe<Airport>;
  getAvailableFlight?: Maybe<AvailableFlight>;
  getCurrentUser: CurrentUser;
  getOperator?: Maybe<Operator>;
  getEmailConfig?: Maybe<EmailConfig>;
  /** Get the currently effective template */
  getTemplate?: Maybe<Template>;
  getUser?: Maybe<User>;
  listAircrafts: AircraftPagination;
  listAirports: AirportPagination;
  listAvailableFlights: AvailableFlightPagination;
  listAuditLogEvents?: Maybe<AuditLogEventPagination>;
  listEmailConfigs: EmailConfigPagination;
  listOperators: OperatorPagination;
  listTemplates: TemplatePagination;
  estimateFlight?: Maybe<FlightEstimate>;
  listUsers: UserPagination;
  listUserActivities: UserActivityPagination;
  getPurchaseOffer?: Maybe<PurchaseOffer>;
  getPriceBreakdown?: Maybe<PriceBreakdown>;
  listPurchaseOffers: PurchaseOfferPagination;
  getClientMembershipStatus?: Maybe<ClientMembershipStatus>;
  listCurrencyRates?: Maybe<Array<Maybe<CurrencyRate>>>;
  listSourcingRequests: SourcingRequestPagination;
  getSourcingRequest?: Maybe<SourcingRequest>;
  findMarketplaceOptions?: Maybe<MarketplaceOptions>;
  createNgaOfferValidationResults: OfferValidationResults;
  createGaOfferValidationResults: OfferValidationResults;
  getFlightRequest?: Maybe<FlightRequest>;
  listFlightRequests: FlightRequestPagination;
  getLeadOwnerAgent?: Maybe<ContactDetails>;
  listLeadOwnerAgents?: Maybe<ContactDetailsPagination>;
  listSpecialRequirements?: Maybe<Array<Maybe<SpecialRequirement>>>;
  listTravelReasons?: Maybe<Array<Maybe<TravelReason>>>;
  getOperatorCancellationTermsFull?: Maybe<CancellationTerms>;
  getOfferAcceptOperatorCancellationTerms?: Maybe<AcceptOperatorCancellationTerms>;
  findCamberOptions?: Maybe<CamberOptions>;
}


export interface QueryCalculateSharedCharterPriceArgs {
  input: PriceDetailsInput;
}


export interface QueryCalculatePrivateCharterPriceArgs {
  input: PriceDetailsInput;
}


export interface QueryGetAircraftArgs {
  aircraftId: Scalars['Int'];
}


export interface QueryGetAirportArgs {
  airportId: Scalars['String'];
}


export interface QueryGetAvailableFlightArgs {
  availableFlightId: Scalars['String'];
}


export interface QueryGetOperatorArgs {
  operatorId?: Maybe<Scalars['Int']>;
  operatorUuid?: Maybe<Scalars['String']>;
}


export interface QueryGetEmailConfigArgs {
  name: Scalars['String'];
}


export interface QueryGetTemplateArgs {
  name: Scalars['String'];
}


export interface QueryGetUserArgs {
  userId: Scalars['Int'];
}


export interface QueryListAircraftsArgs {
  filter?: Maybe<AircraftFilterInput>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<AircraftSortingInput>;
}


export interface QueryListAirportsArgs {
  filter?: Maybe<AirportFilterInput>;
  pagination?: Maybe<PaginationInput>;
}


export interface QueryListAvailableFlightsArgs {
  filter?: Maybe<AvailableFlightFilterInput>;
  sorting?: Maybe<AvailableFlightSortInput>;
  pagination?: Maybe<PaginationInput>;
}


export interface QueryListAuditLogEventsArgs {
  filter?: Maybe<AuditLogEventFilterInput>;
  pagination?: Maybe<PaginationInput>;
}


export interface QueryListEmailConfigsArgs {
  filter?: Maybe<EmailConfigFilterInput>;
  pagination?: Maybe<PaginationInput>;
}


export interface QueryListOperatorsArgs {
  filter?: Maybe<OperatorFilterInput>;
  pagination?: Maybe<PaginationInput>;
}


export interface QueryListTemplatesArgs {
  filter?: Maybe<TemplateFilterInput>;
  pagination?: Maybe<PaginationInput>;
}


export interface QueryEstimateFlightArgs {
  input: FlightEstimateInput;
}


export interface QueryListUsersArgs {
  filter?: Maybe<UserFilterInput>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<UserSortInput>;
}


export interface QueryListUserActivitiesArgs {
  filter?: Maybe<UserActivityFilterInput>;
  sorting?: Maybe<UserActivitySortInput>;
  pagination?: Maybe<PaginationInput>;
}


export interface QueryGetPurchaseOfferArgs {
  purchaseOfferId: Scalars['String'];
}


export interface QueryGetPriceBreakdownArgs {
  bidPrice?: Maybe<Scalars['BigDecimal']>;
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  operatorUuid?: Maybe<Scalars['String']>;
  priceType?: Maybe<PurchaseOfferPriceTypeEnum>;
}


export interface QueryListPurchaseOffersArgs {
  filter?: Maybe<PurchaseOfferFilterInput>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<PurchaseOfferSortingInput>;
}


export interface QueryGetClientMembershipStatusArgs {
  clientId: Scalars['Int'];
}


export interface QueryListSourcingRequestsArgs {
  filter?: Maybe<SourcingRequestFilterInput>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<SourcingRequestSortingInput>;
}


export interface QueryGetSourcingRequestArgs {
  sourcingRequestId: Scalars['String'];
}


export interface QueryFindMarketplaceOptionsArgs {
  sourcingRequestId: Scalars['String'];
}


export interface QueryCreateNgaOfferValidationResultsArgs {
  input: NgaPurchaseOfferCreateInput;
}


export interface QueryCreateGaOfferValidationResultsArgs {
  input: GaPurchaseOfferCreateInput;
}


export interface QueryGetFlightRequestArgs {
  flightRequestId: Scalars['Int'];
}


export interface QueryListFlightRequestsArgs {
  filter?: Maybe<FlightRequestFilterInput>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<FlightRequestSortInput>;
}


export interface QueryGetLeadOwnerAgentArgs {
  ownerName: Scalars['String'];
}


export interface QueryListLeadOwnerAgentsArgs {
  filter?: Maybe<EqualContainsContactDetailsFilterInput>;
  pagination: PaginationInput;
  sorting?: Maybe<ContactDetailsSortInput>;
}


export interface QueryGetOperatorCancellationTermsFullArgs {
  operatorUuid: Scalars['String'];
}


export interface QueryGetOfferAcceptOperatorCancellationTermsArgs {
  offerId: Scalars['String'];
}


export interface QueryFindCamberOptionsArgs {
  sourcingRequestId: Scalars['String'];
}

export interface Rating {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  expirationDate?: Maybe<Scalars['IsoDate']>;
}

export interface Region {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  acronym?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export interface RepositioningItinerary {
  flightRate?: Maybe<Scalars['BigDecimal']>;
  landingFee?: Maybe<Scalars['BigDecimal']>;
  flexibility: Scalars['Float'];
  repositioningPrice?: Maybe<Scalars['BigDecimal']>;
  densityFeePrice?: Maybe<Scalars['BigDecimal']>;
  landingFeePrice?: Maybe<Scalars['BigDecimal']>;
  /**
   * The planned route for the
   * repositioning
   */
  segments?: Maybe<Array<Maybe<ItinerarySegment>>>;
}

export interface RepositioningItineraryFilterInput {
  flightRate?: Maybe<ModelBigDecimalFilterInput>;
  landingFee?: Maybe<ModelBigDecimalFilterInput>;
  flexibility?: Maybe<ModelFloatFilterInput>;
  repositioningPrice?: Maybe<ModelBigDecimalFilterInput>;
  densityFeePrice?: Maybe<ModelBigDecimalFilterInput>;
  landingFeePrice?: Maybe<ModelBigDecimalFilterInput>;
  /**
   * The planned route for the
   * repositioning
   */
  segments?: Maybe<ItinerarySegmentFilterInput>;
}

export interface RepositioningItineraryInput {
  repositioningRate?: Maybe<Scalars['BigDecimal']>;
  landingFee?: Maybe<Scalars['BigDecimal']>;
  flexibility: Scalars['Float'];
}

export interface RequestAirport {
  from?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
}

export interface RequestLeg {
  pax?: Maybe<Scalars['Int']>;
  departure?: Maybe<Scalars['String']>;
  airports?: Maybe<Array<Maybe<RequestAirport>>>;
  toIsTechStop?: Maybe<Scalars['Boolean']>;
}

export interface SalesAdvisor {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
}

export interface SalesAdvisorFilterInput {
  id?: Maybe<ModelIntFilterInput>;
  name?: Maybe<ModelStringFilterInput>;
  email?: Maybe<ModelStringFilterInput>;
}

export interface SalesAdvisorInput {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['EmailAddress']>;
}

export interface SearchResultOption {
  operator?: Maybe<Operator>;
  price?: Maybe<Scalars['BigDecimal']>;
  aircraftCategory?: Maybe<AircraftCategory>;
  aircraftModel?: Maybe<Scalars['String']>;
  vendorPrice?: Maybe<Scalars['BigDecimal']>;
  tailNumber?: Maybe<Scalars['String']>;
  fuelStops?: Maybe<Scalars['Int']>;
}

export interface SearchResultOptionInput {
  operatorUuid?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['BigDecimal']>;
  aircraftCategoryId?: Maybe<Scalars['Int']>;
  aircraftModel?: Maybe<Scalars['String']>;
  vendorPrice?: Maybe<Scalars['BigDecimal']>;
  tailNumber?: Maybe<Scalars['String']>;
  fuelStops?: Maybe<Scalars['Int']>;
}

export enum SegmentTypeEnum {
  /**
   * The aircraft is flying without
   * paying customers
   */
  EmptyFlight = 'EMPTY_FLIGHT',
  /**
   * A private charter is being
   * flown
   */
  PrivateCharter = 'PRIVATE_CHARTER',
  /**
   * A shared charter is being
   * flown
   */
  SharedCharter = 'SHARED_CHARTER'
}

export interface SegmentTypeFilterInput {
  ne?: Maybe<SegmentTypeEnum>;
  eq?: Maybe<SegmentTypeEnum>;
}

export interface SelectedOffer {
  offerId?: Maybe<Scalars['String']>;
  recommended?: Maybe<Scalars['Boolean']>;
  flightPrice?: Maybe<Scalars['BigDecimal']>;
  offerPrice?: Maybe<Scalars['BigDecimal']>;
  offerComment?: Maybe<Scalars['String']>;
  legs?: Maybe<Array<Maybe<SelectedOfferLeg>>>;
}

export interface SelectedOfferLeg {
  departureTimeUTC?: Maybe<Scalars['IsoDateTime']>;
  departureAirportCode?: Maybe<Scalars['String']>;
  arrivalAirportCode?: Maybe<Scalars['String']>;
  departureFboId?: Maybe<Scalars['Int']>;
  arrivalFboId?: Maybe<Scalars['Int']>;
}

export interface SendApprovedOffersInput {
  sourcingRequestId: Scalars['String'];
  offers?: Maybe<Array<Maybe<ModeratedOfferInput>>>;
  approverComment?: Maybe<Scalars['String']>;
  showYor?: Maybe<Scalars['Boolean']>;
  showYom?: Maybe<Scalars['Boolean']>;
}

export interface ServiceArea {
  aircraftCategory?: Maybe<OperatorAircraftCategory>;
  regions?: Maybe<Array<Maybe<Region>>>;
}

export interface ShuttleFlightEstimate {
  absoluteMinimum: ShuttlePricing;
  absoluteMaximum: ShuttlePricing;
  suggestedMinimum: ShuttlePricing;
  suggestedMaximum: ShuttlePricing;
}

export interface ShuttlePricing {
  /** Indicates if the charter can be created or why it's disallowed. */
  permitted?: Maybe<CharterPermissionsEnum>;
  /** The price offered to the customer for a seat. */
  seatPrice: Scalars['BigDecimal'];
  /** The number of seats being offered */
  seatsOffered?: Maybe<Scalars['Int']>;
  /** The amount paid to the operator (operator share + federal tax cost + segment fee) */
  operatorRevenue: Scalars['BigDecimal'];
  /** The amount paid to the broker ( broker share + credit card fee ) */
  brokerRevenue: Scalars['BigDecimal'];
  /** The price paid by the customer (seat cost + segment fee + federal tax + credit card cost). */
  customerPrice?: Maybe<Scalars['BigDecimal']>;
  /** The rate charged by the broker */
  brokerRate?: Maybe<Scalars['BigDecimal']>;
  /** The portion of the seat price collected by the broker (seat price * broker share ) */
  brokerShare: Scalars['Float'];
  /** The portion of the seat price collected by the operator (seat price - broker share ) */
  operatorShare: Scalars['BigDecimal'];
  /** The credit card transaction cost charged to the customer */
  creditCardCost?: Maybe<Scalars['BigDecimal']>;
  /** Taxes paid to the Federal government */
  federalTaxCost?: Maybe<Scalars['BigDecimal']>;
  /** Per seat cost for segment fee */
  segmentFeeCost?: Maybe<Scalars['BigDecimal']>;
  /** The date time that this price become in effect. */
  effective?: Maybe<Scalars['IsoDateTime']>;
  /** The date time that this price was superseded with another price. */
  superseded?: Maybe<Scalars['IsoDateTime']>;
  /** The user that set this price */
  user?: Maybe<User>;
  totalTaxes?: Maybe<Array<Maybe<TaxSnapshot>>>;
}

export interface ShuttlePricingFilterInput {
  seatPrice?: Maybe<ModelBigDecimalFilterInput>;
  operatorRevenue?: Maybe<ModelBigDecimalFilterInput>;
  brokerRevenue?: Maybe<ModelBigDecimalFilterInput>;
  customerPrice?: Maybe<ModelBigDecimalFilterInput>;
  brokerShare?: Maybe<ModelFloatFilterInput>;
  operatorShare?: Maybe<ModelBigDecimalFilterInput>;
  creditCardCost?: Maybe<ModelBigDecimalFilterInput>;
  federalTaxCost?: Maybe<ModelBigDecimalFilterInput>;
  segmentFeeCost?: Maybe<ModelBigDecimalFilterInput>;
  effective?: Maybe<ModelIsoDateTimeFilterInput>;
  superseded?: Maybe<ModelIsoDateTimeFilterInput>;
  user?: Maybe<UserFilterInput>;
}

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface SourcingRequesSortingLegInput {
  departureTimeUTC?: Maybe<Scalars['Boolean']>;
}

/** A request for one or more flights */
export interface SourcingRequest {
  sourcingRequestId: Scalars['String'];
  status: SourcingRequestStatusEnum;
  flightRequestId?: Maybe<Scalars['Int']>;
  aircraftCategories: Array<AircraftCategory>;
  aircraftTypes?: Maybe<Array<Maybe<AircraftType>>>;
  maxPrice?: Maybe<Scalars['BigDecimal']>;
  maxPriceInCurrency?: Maybe<Scalars['BigDecimal']>;
  priceType?: Maybe<SourcingRequestPriceTypeEnum>;
  dba: Scalars['String'];
  automated: Scalars['Boolean'];
  tmc: Scalars['Boolean'];
  opportunityId?: Maybe<Scalars['String']>;
  legs: Array<SourcingRequestLeg>;
  purchaseOffers?: Maybe<Array<PurchaseOffer>>;
  customerRequirements?: Maybe<CustomerRequirements>;
  /** @deprecated Field no longer supported */
  pod?: Maybe<Scalars['String']>;
  /** TODO remove in 2.20 release */
  clientDetails?: Maybe<ClientDetails>;
  podContact?: Maybe<ContactDetails>;
  ownerContact?: Maybe<ContactDetails>;
  salesAdvisor?: Maybe<SalesAdvisor>;
  createdUser?: Maybe<AuditUser>;
  createdDate?: Maybe<Scalars['IsoDateTime']>;
  acceptedByUser?: Maybe<AuditUser>;
  acceptedDate?: Maybe<Scalars['IsoDateTime']>;
  cancelledByUser?: Maybe<AuditUser>;
  cancelledDate?: Maybe<Scalars['IsoDateTime']>;
  lastModifiedBy?: Maybe<AuditUser>;
  lastModifiedDate?: Maybe<Scalars['IsoDateTime']>;
  offersRequireStaffApproval?: Maybe<Scalars['Boolean']>;
  sentModeratedOffersHistory?: Maybe<Array<Maybe<ModeratedOffer>>>;
  type?: Maybe<GuaranteedTypeEnum>;
  sendToXOMarketplace?: Maybe<Scalars['Boolean']>;
  showXODedicatedFleet?: Maybe<Scalars['Boolean']>;
  elacProForma?: Maybe<Scalars['Boolean']>;
  petOnBoard?: Maybe<Scalars['Boolean']>;
  petNote?: Maybe<Scalars['String']>;
  elacPricingSnapshot?: Maybe<Array<Maybe<EalcPricing>>>;
  xoDedicatedPricingSnapshot?: Maybe<Array<Maybe<XoDedicatedPricing>>>;
  sfOpportunityId?: Maybe<Scalars['String']>;
  currencyDetails?: Maybe<CurrencyDetails>;
  travelReason?: Maybe<TravelReason>;
  specialRequirements?: Maybe<Array<Maybe<SpecialRequirement>>>;
  allowFirmDeparture?: Maybe<Scalars['Boolean']>;
  searchResultOptions?: Maybe<Array<Maybe<SearchResultOption>>>;
}


/** A request for one or more flights */
export interface SourcingRequestPurchaseOffersArgs {
  filter?: Maybe<PurchaseOfferFilterInput>;
  sorting?: Maybe<PurchaseOfferSortingInput>;
}

export interface SourcingRequestFilterInput {
  id?: Maybe<ModelIdFilterInput>;
  status?: Maybe<SourcingRequestStatusFilterInput>;
  flightRequestId?: Maybe<ModelIntFilterInput>;
  aircraftCategories?: Maybe<Array<AircraftCategoryFilterInput>>;
  aircraftTypes?: Maybe<Array<AircraftTypeFilterInput>>;
  maxPrice?: Maybe<ModelBigDecimalFilterInput>;
  opportunityId?: Maybe<ModelStringFilterInput>;
  priceType?: Maybe<SourcingRequestPriceTypeFilterInput>;
  dba?: Maybe<ModelStringFilterInput>;
  automated?: Maybe<ModelBooleanFilterInput>;
  tmc?: Maybe<ModelBooleanFilterInput>;
  legs?: Maybe<SourcingRequestLegFilterInput>;
  customerRequirements?: Maybe<CustomerRequirementsFilterInput>;
  podContact?: Maybe<ContactDetailsFilterInput>;
  ownerContact?: Maybe<ContactDetailsFilterInput>;
  clientDetails?: Maybe<ClientDetailsFilterInput>;
  salesAdvisor?: Maybe<SalesAdvisorFilterInput>;
  pod?: Maybe<ModelStringFilterInput>;
  type?: Maybe<GuaranteedTypeFilterInput>;
  createdDate?: Maybe<ModelIsoDateTimeFilterInput>;
  sendToXOMarketplace?: Maybe<ModelBooleanFilterInput>;
  showXODedicatedFleet?: Maybe<ModelBooleanFilterInput>;
  elacProForma?: Maybe<ModelBooleanFilterInput>;
  createdUser?: Maybe<UserFilterInput>;
  sfOpportunityId?: Maybe<ModelStringFilterInput>;
}

export interface SourcingRequestInput {
  flightRequestId?: Maybe<Scalars['Int']>;
  aircraftCategoryIds?: Maybe<Array<Scalars['Int']>>;
  aircraftTypeIds?: Maybe<Array<Scalars['Int']>>;
  maxPrice?: Maybe<Scalars['BigDecimal']>;
  priceType?: Maybe<SourcingRequestPriceTypeEnum>;
  dba?: Maybe<Scalars['String']>;
  automated: Scalars['Boolean'];
  tmc?: Maybe<Scalars['Boolean']>;
  opportunityId?: Maybe<Scalars['String']>;
  legs: Array<SourcingRequestLegInput>;
  customerRequirements?: Maybe<CustomerRequirementsInput>;
  podContact?: Maybe<ContactDetailsInput>;
  ownerContact?: Maybe<ContactDetailsInput>;
  clientDetails?: Maybe<ClientDetailsInput>;
  salesAdvisor?: Maybe<SalesAdvisorInput>;
  offersRequireStaffApproval?: Maybe<Scalars['Boolean']>;
  sendToXOMarketplace?: Maybe<Scalars['Boolean']>;
  showXODedicatedFleet?: Maybe<Scalars['Boolean']>;
  elacProForma?: Maybe<Scalars['Boolean']>;
  petOnBoard?: Maybe<Scalars['Boolean']>;
  petNote?: Maybe<Scalars['String']>;
  sfOpportunityId?: Maybe<Scalars['String']>;
  currency?: Maybe<Currency>;
  travelReasonId?: Maybe<Scalars['String']>;
  specialRequirementIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  allowFirmDeparture?: Maybe<Scalars['Boolean']>;
  searchResultOptions?: Maybe<Array<Maybe<SearchResultOptionInput>>>;
}

export interface SourcingRequestLeg {
  departureTime?: Maybe<Scalars['IsoDateTime']>;
  departureTimeUTC?: Maybe<Scalars['IsoDateTime']>;
  departureTimeTBD?: Maybe<Scalars['Boolean']>;
  departureAirport?: Maybe<Airport>;
  arrivalAirport?: Maybe<Airport>;
  pax: Scalars['Int'];
  eft?: Maybe<Scalars['Int']>;
  departureFboId?: Maybe<Scalars['Int']>;
  arrivalFboId?: Maybe<Scalars['Int']>;
  departureFbo?: Maybe<Fbo>;
  arrivalFbo?: Maybe<Fbo>;
  toIsTechStop?: Maybe<Scalars['Boolean']>;
}

export interface SourcingRequestLegFilterInput {
  departureTime?: Maybe<ModelIsoDateTimeFilterInput>;
  departureTimeUTC?: Maybe<ModelIsoDateTimeFilterInput>;
  departureTimeTBD?: Maybe<ModelBooleanFilterInput>;
  departureAirportCode?: Maybe<ModelStringFilterInput>;
  arrivalAirportCode?: Maybe<ModelStringFilterInput>;
  pax?: Maybe<ModelIntFilterInput>;
  exactArrayElementMatch?: Maybe<Scalars['Boolean']>;
  toIsTechStop?: Maybe<Scalars['Boolean']>;
}

export interface SourcingRequestLegInput {
  departureTime?: Maybe<Scalars['IsoDateTime']>;
  departureTimeUTC?: Maybe<Scalars['IsoDateTime']>;
  departureTimeTBD: Scalars['Boolean'];
  departureAirportCode: Scalars['AirportCodeInput'];
  arrivalAirportCode: Scalars['AirportCodeInput'];
  pax: Scalars['Int'];
  departureFboId?: Maybe<Scalars['Int']>;
  arrivalFboId?: Maybe<Scalars['Int']>;
  toIsTechStop?: Maybe<Scalars['Boolean']>;
}

export interface SourcingRequestPagination {
  sourcingRequests: Array<SourcingRequest>;
  pagination?: Maybe<Pagination>;
}

export enum SourcingRequestPriceTypeEnum {
  Fixed = 'FIXED',
  Dynamic = 'DYNAMIC'
}

export interface SourcingRequestPriceTypeFilterInput {
  ne?: Maybe<SourcingRequestPriceTypeEnum>;
  eq?: Maybe<SourcingRequestPriceTypeEnum>;
}

export interface SourcingRequestSortingInput {
  lastModifiedDate?: Maybe<Scalars['Boolean']>;
  createdDate?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Boolean']>;
}

export enum SourcingRequestStatusEnum {
  Incoming = 'INCOMING',
  Fulfilled = 'FULFILLED',
  Cancelled = 'CANCELLED',
  Deferred = 'DEFERRED'
}

export interface SourcingRequestStatusFilterInput {
  ne?: Maybe<SourcingRequestStatusEnum>;
  eq?: Maybe<SourcingRequestStatusEnum>;
}

export interface SpecialRequirement {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

export interface StompNotificationFilterInput {
  id?: Maybe<ModelIdFilterInput>;
  sent?: Maybe<ModelIsoDateTimeFilterInput>;
}

export interface SuggestedSeatPrice {
  absoluteMinimum: Scalars['Float'];
  absoluteMaximum: Scalars['Float'];
  suggestedMinimum: Scalars['Float'];
  suggestedMaximum: Scalars['Float'];
}

export interface Surcharge {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['BigDecimal']>;
}

export interface SurchargeInput {
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['BigDecimal']>;
}

export interface TaxSnapshot {
  name?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['BigDecimal']>;
}

export interface Template {
  name: Scalars['String'];
  version: Scalars['String'];
  /** The Base64 encoded content of the template.  */
  content: Scalars['String'];
  /** The media type of the template */
  mediaType: Scalars['String'];
  effective: Scalars['IsoDateTime'];
  /**
   * The time that this template was superseded
   * by another version.  If null then it is the
   * currently effective template
   */
  superseded?: Maybe<Scalars['IsoDateTime']>;
  /** The user that installed the template */
  createdBy: User;
}

export interface TemplateFilterInput {
  name?: Maybe<ModelStringFilterInput>;
  version?: Maybe<ModelStringFilterInput>;
  mediaType?: Maybe<ModelStringFilterInput>;
  effective?: Maybe<ModelIsoDateTimeFilterInput>;
  superseded?: Maybe<ModelIsoDateTimeFilterInput>;
  createdBy?: Maybe<UserFilterInput>;
}

export interface TemplateInput {
  name: Scalars['String'];
  version: Scalars['String'];
  content: Scalars['String'];
  mediaType: Scalars['String'];
}

export interface TemplatePagination {
  templates: Array<Maybe<Template>>;
  pagination?: Maybe<Pagination>;
}

export interface TravelReason {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** Update an existing available flight */
export interface UpdateAvailableFlightInput {
  availableFlightId: Scalars['ID'];
  status?: Maybe<AvailableFlightStatus>;
  operatorShare?: Maybe<Scalars['BigDecimal']>;
  seatPrice?: Maybe<Scalars['BigDecimal']>;
  seatsOffered?: Maybe<Scalars['Int']>;
  arrivalFboId?: Maybe<Scalars['Int']>;
  departureFboId?: Maybe<Scalars['Int']>;
}

export interface User {
  /** The current user ID */
  userId?: Maybe<Scalars['Int']>;
  /** The user type ID */
  userTypeId?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['Int']>;
  organizationUuid?: Maybe<Scalars['String']>;
  /** The users first name */
  firstName?: Maybe<Scalars['String']>;
  /** The users last name */
  lastName?: Maybe<Scalars['String']>;
  /** The users email address */
  emailAddress?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  activated?: Maybe<Scalars['Boolean']>;
  superuser?: Maybe<Scalars['Boolean']>;
  eulaAccepted?: Maybe<Scalars['Boolean']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  operator?: Maybe<Operator>;
  lastLoginDate?: Maybe<Scalars['IsoDateTime']>;
}

export interface UserActivity {
  user?: Maybe<User>;
  action?: Maybe<UserActivityAction>;
  token?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['IsoDateTime']>;
}

export enum UserActivityAction {
  Login = 'LOGIN'
}

export interface UserActivityActionFilterInput {
  eq?: Maybe<UserActivityAction>;
  ne?: Maybe<UserActivityAction>;
}

export interface UserActivityFilterInput {
  userId?: Maybe<ModelIntFilterInput>;
  action?: Maybe<UserActivityActionFilterInput>;
  token?: Maybe<ModelStringFilterInput>;
  time?: Maybe<ModelIsoDateTimeFilterInput>;
}

export interface UserActivityPagination {
  userActivities: Array<UserActivity>;
  pagination?: Maybe<Pagination>;
}

export interface UserActivitySortInput {
  userId?: Maybe<Scalars['Boolean']>;
  token?: Maybe<Scalars['Boolean']>;
  time?: Maybe<Scalars['Boolean']>;
}

export interface UserFilterInput {
  /** The current user ID */
  userId?: Maybe<ModelIntFilterInput>;
  /** The user type ID */
  userTypeId?: Maybe<ModelIntFilterInput>;
  organizationId?: Maybe<ModelIntFilterInput>;
  organizationUuid?: Maybe<ModelStringFilterInput>;
  /** The users first name */
  firstName?: Maybe<ModelStringFilterInput>;
  /** The users last name */
  lastName?: Maybe<ModelStringFilterInput>;
  /** The users email address */
  emailAddress?: Maybe<EmailAddressFilterInput>;
  phoneNumber?: Maybe<Scalars['String']>;
  active?: Maybe<ModelBooleanFilterInput>;
  activated?: Maybe<ModelBooleanFilterInput>;
  superuser?: Maybe<ModelBooleanFilterInput>;
  eulaAccepted?: Maybe<ModelBooleanFilterInput>;
}

export interface UserInput {
  /** The current user ID */
  userId?: Maybe<Scalars['Int']>;
  /** The user type ID */
  userTypeId?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['Int']>;
  /** The users first name */
  firstName?: Maybe<Scalars['String']>;
  /** The users last name */
  lastName?: Maybe<Scalars['String']>;
  /** The users email address */
  emailAddress?: Maybe<Scalars['EmailAddress']>;
  phoneNumber?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  activated?: Maybe<Scalars['Boolean']>;
  superuser?: Maybe<Scalars['Boolean']>;
  eulaAccepted?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
}

export interface UserPagination {
  users: Array<User>;
  pagination?: Maybe<Pagination>;
}

export interface UserSortInput {
  userId?: Maybe<Scalars['Boolean']>;
  active?: Maybe<Scalars['Boolean']>;
  superUser?: Maybe<Scalars['Boolean']>;
  eulaAccepted?: Maybe<Scalars['Boolean']>;
  organizationId?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['Boolean']>;
  emailAddress?: Maybe<Scalars['Boolean']>;
}

export interface UserToken {
  token?: Maybe<Scalars['String']>;
  createTime?: Maybe<Scalars['IsoDateTime']>;
}

export interface UserTokenInput {
  userId?: Maybe<Scalars['Int']>;
  token?: Maybe<Scalars['String']>;
}

export enum ValidationCodeEnum {
  NoAircraftWithCategory = 'NO_AIRCRAFT_WITH_CATEGORY',
  CabotageViolation = 'CABOTAGE_VIOLATION',
  SourcingRequestNotFound = 'SOURCING_REQUEST_NOT_FOUND',
  OperatorNotFound = 'OPERATOR_NOT_FOUND',
  OperatorNotActive = 'OPERATOR_NOT_ACTIVE',
  NoAircraftCompleted = 'NO_AIRCRAFT_COMPLETED',
  AircraftDeleted = 'AIRCRAFT_DELETED',
  NoInsuranceRestrictionApprover = 'NO_INSURANCE_RESTRICTION_APPROVER',
  NoAircraft = 'NO_AIRCRAFT',
  AircraftNotFound = 'AIRCRAFT_NOT_FOUND',
  NoOperator = 'NO_OPERATOR',
  OperatorRegionCategoryMissmatch = 'OPERATOR_REGION_CATEGORY_MISSMATCH',
  OperatorAircraftCategoryMissmatch = 'OPERATOR_AIRCRAFT_CATEGORY_MISSMATCH',
  ExpiredOperatorApproval = 'EXPIRED_OPERATOR_APPROVAL',
  ExpiredInsuranceAircraft = 'EXPIRED_INSURANCE_AIRCRAFT',
  DuplicateOffer = 'DUPLICATE_OFFER'
}

export interface XoDedicatedPricing {
  price?: Maybe<Scalars['BigDecimal']>;
  aircraftId?: Maybe<Scalars['Int']>;
  categoryId?: Maybe<Scalars['Int']>;
}

export type AcceptUserAgreementMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptUserAgreementMutation = { acceptUserAgreement?: Maybe<Pick<User, 'eulaAccepted'>> };

export type AcceptOperatorAgreementMutationVariables = Exact<{ [key: string]: never; }>;


export type AcceptOperatorAgreementMutation = { acceptOperatorAgreement?: Maybe<Pick<Operator, 'eulaAccepted'>> };

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { getCurrentUser: (
    Pick<CurrentUser, 'firstName' | 'lastName' | 'organizationId' | 'organizationUuid' | 'userEulaAccepted' | 'operatorEulaAccepted' | 'organizationLegalName' | 'superuser' | 'userId' | 'emailAddress'>
    & { permissions?: Maybe<Array<Maybe<Pick<Permission, 'name' | 'enabled'>>>> }
  ) };

export type ListAircraftsFleetQueryVariables = Exact<{
  filter?: Maybe<AircraftFilterInput>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<AircraftSortingInput>;
}>;


export type ListAircraftsFleetQuery = { listAircrafts: { aircrafts: Array<Pick<Aircraft, 'modelName' | 'aircraftId' | 'maxPax' | 'tailNumber' | 'operatorApprovalExpirationDate' | 'deleted' | 'insuranceApproved' | 'offFleetOperatorNames' | 'completed' | 'categoryName' | 'insuranceRestrictionApprover' | 'yom' | 'homeBase'>>, pagination?: Maybe<Pick<Pagination, 'count'>> } };

export type GetAircraftQueryVariables = Exact<{
  aircraftId: Scalars['Int'];
}>;


export type GetAircraftQuery = { getAircraft?: Maybe<(
    Pick<Aircraft, 'modelName' | 'aircraftId' | 'maxPax' | 'tailNumber' | 'operatorApprovalExpirationDate' | 'insuranceExpirationDate' | 'deleted' | 'insuranceApproved' | 'completed' | 'categoryId' | 'categoryName' | 'requiresOwnerApproval' | 'insuranceAmount' | 'homeBase' | 'yom' | 'yor' | 'offFleetOperatorNames'>
    & { amenities?: Maybe<Array<Pick<AircraftAmenity, 'name' | 'id'>>>, equipments?: Maybe<Array<Pick<AircraftEquipment, 'id' | 'name'>>>, images?: Maybe<Array<Pick<AircraftImage, 'url' | 'type'>>>, operator?: Maybe<{ argusRating?: Maybe<Pick<Rating, 'name' | 'id'>>, wyvernRating?: Maybe<Pick<Rating, 'name' | 'id'>>, isBaoRating?: Maybe<Pick<Rating, 'name' | 'id'>>, levelFlightRating?: Maybe<Pick<Rating, 'name' | 'id'>> }> }
  )> };

export type ListAircraftsFfQueryVariables = Exact<{
  filter?: Maybe<AircraftFilterInput>;
}>;


export type ListAircraftsFfQuery = { listAircrafts: { aircrafts: Array<Pick<Aircraft, 'modelName' | 'aircraftId' | 'maxPax' | 'tailNumber' | 'operatorApprovalExpirationDate' | 'deleted' | 'insuranceApproved' | 'requiresOwnerApproval' | 'completed' | 'categoryId' | 'categoryName' | 'insuranceRestrictionApprover'>> } };

export type GetAirportFfQueryVariables = Exact<{
  code?: Maybe<AirportFilterInput>;
}>;


export type GetAirportFfQuery = { listAirports: { airports: Array<Maybe<Pick<Airport, 'name' | 'code' | 'timezoneName'>>> } };

export type ListCurrencyRatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListCurrencyRatesQuery = { listCurrencyRates?: Maybe<Array<Maybe<Pick<CurrencyRate, 'code' | 'rate'>>>> };

export type GetAirportFboQueryVariables = Exact<{
  code: Scalars['String'];
}>;


export type GetAirportFboQuery = { getAirport?: Maybe<{ fbos?: Maybe<Array<(
      Pick<Fbo, 'name' | 'fboId'>
      & { address?: Maybe<Pick<Address, 'state' | 'country' | 'city'>> }
    )>> }> };

export type ListPurchaseOffersQueryVariables = Exact<{
  filter?: Maybe<PurchaseOfferFilterInput>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<PurchaseOfferSortingInput>;
}>;


export type ListPurchaseOffersQuery = { listPurchaseOffers: { purchaseOffers: Array<(
      Pick<PurchaseOffer, 'createdDate' | 'sourcingRequestId' | 'expirationDate' | 'commission' | 'offerPrice' | 'offerPriceInCurrency' | 'flightPrice' | 'operatorPurchaseOfferId' | 'status' | 'dba' | 'priceType' | 'lastModifiedDate' | 'acceptedDate' | 'offerCount'>
      & { createdBy?: Maybe<Pick<AuditUser, 'lastName' | 'firstName' | 'emailAddress' | 'userId'>>, legs: Array<(
        Pick<SourcingRequestLeg, 'departureTime' | 'departureTimeTBD' | 'pax' | 'toIsTechStop'>
        & { arrivalAirport?: Maybe<Pick<Airport, 'code'>>, departureAirport?: Maybe<Pick<Airport, 'code'>> }
      )>, lastModifiedBy?: Maybe<Pick<AuditUser, 'lastName' | 'firstName' | 'emailAddress' | 'userId'>>, aircraftCategories: Array<Pick<AircraftCategory, 'name' | 'aircraftCategoryId'>>, operator?: Maybe<Pick<Operator, 'operatorUuid' | 'name'>>, acceptedBy?: Maybe<Pick<AuditUser, 'lastName' | 'firstName' | 'emailAddress' | 'userId'>>, aircraft?: Maybe<Pick<Aircraft, 'aircraftId' | 'modelName' | 'categoryName'>>, currencyDetails?: Maybe<Pick<CurrencyDetails, 'currency'>> }
    )>, pagination?: Maybe<Pick<Pagination, 'count'>> } };

export type GetOfferDetailsQueryVariables = Exact<{
  purchaseOfferId: Scalars['String'];
}>;


export type GetOfferDetailsQuery = { getPurchaseOffer?: Maybe<(
    Pick<PurchaseOffer, 'createdDate' | 'sourcingRequestId' | 'expirationDate' | 'commission' | 'offerPrice' | 'offerPriceInCurrency' | 'flightPrice' | 'operatorPurchaseOfferId' | 'status' | 'dba' | 'priceType' | 'lastModifiedDate' | 'acceptedDate' | 'quoteReferenceId' | 'operatorComment' | 'ownerAircraftApprovalAcquired' | 'firmFlexDeparture' | 'firmFlexDepartureFee'>
    & { createdBy?: Maybe<Pick<AuditUser, 'lastName' | 'firstName' | 'emailAddress' | 'userId'>>, legs: Array<(
      Pick<SourcingRequestLeg, 'departureTime' | 'departureTimeTBD' | 'departureTimeUTC' | 'pax' | 'toIsTechStop'>
      & { arrivalAirport?: Maybe<Pick<Airport, 'code'>>, departureAirport?: Maybe<Pick<Airport, 'code'>>, arrivalFbo?: Maybe<Pick<Fbo, 'fboId' | 'name'>>, departureFbo?: Maybe<Pick<Fbo, 'fboId' | 'name'>> }
    )>, lastModifiedBy?: Maybe<Pick<AuditUser, 'lastName' | 'firstName' | 'emailAddress' | 'userId'>>, aircraftCategories: Array<Pick<AircraftCategory, 'name' | 'aircraftCategoryId'>>, operator?: Maybe<Pick<Operator, 'operatorUuid' | 'name' | 'dedicatedFleet'>>, acceptedBy?: Maybe<Pick<AuditUser, 'lastName' | 'firstName' | 'emailAddress' | 'userId'>>, aircraft?: Maybe<Pick<Aircraft, 'aircraftId' | 'modelName' | 'categoryName'>>, bidHistory?: Maybe<Array<Maybe<(
      Pick<BidHistory, 'offerPrice' | 'effective' | 'offerPriceInCurrency'>
      & { currencyDetails?: Maybe<Pick<CurrencyDetails, 'currency'>> }
    )>>>, competitorInsights?: Maybe<Array<Maybe<Pick<CompetitorInsight, 'aircraftCategoryName' | 'aircraftModelName' | 'offerPrice'>>>>, customerRequirements?: Maybe<Pick<CustomerRequirements, 'additionalInstructions'>>, sourcingRequest?: Maybe<(
      Pick<SourcingRequest, 'petOnBoard' | 'petNote' | 'flightRequestId'>
      & { travelReason?: Maybe<Pick<TravelReason, 'name'>>, specialRequirements?: Maybe<Array<Maybe<Pick<SpecialRequirement, 'name'>>>> }
    )>, currencyDetails?: Maybe<Pick<CurrencyDetails, 'currency'>> }
  )> };

export type AcceptNgaPurchaseOfferMutationVariables = Exact<{
  input: PurchaseOfferNgaAcceptInput;
}>;


export type AcceptNgaPurchaseOfferMutation = { acceptNgaPurchaseOffer: Pick<PurchaseOffer, 'status'> };

export type AcceptGaPurchaseOfferMutationVariables = Exact<{
  input: PurchaseOfferGaAcceptInput;
}>;


export type AcceptGaPurchaseOfferMutation = { acceptGaPurchaseOffer: (
    Pick<PurchaseOffer, 'status'>
    & { aircraft?: Maybe<Pick<Aircraft, 'tailNumber'>> }
  ) };

export type DeclinePurchaseOfferMutationVariables = Exact<{
  purchaseOfferId: Scalars['String'];
}>;


export type DeclinePurchaseOfferMutation = { declinePurchaseOffer: Pick<PurchaseOffer, 'status'> };

export type GetOperatorInfoQueryVariables = Exact<{
  operatorId: Scalars['Int'];
}>;


export type GetOperatorInfoQuery = { getOperator?: Maybe<(
    Pick<Operator, 'name' | 'legalName' | 'phoneNumber1' | 'phoneNumber2' | 'operatorId' | 'cancellationTerms' | 'draftCancellationTerms' | 'externalNote'>
    & { users?: Maybe<Array<Maybe<Pick<User, 'firstName'>>>>, argusRating?: Maybe<Pick<Rating, 'name' | 'id' | 'expirationDate'>>, wyvernRating?: Maybe<Pick<Rating, 'name' | 'id' | 'expirationDate'>>, isBaoRating?: Maybe<Pick<Rating, 'name' | 'id' | 'expirationDate'>>, levelFlightRating?: Maybe<Pick<Rating, 'name' | 'id' | 'expirationDate'>>, prospectus?: Maybe<Pick<Prospectus, 'prospectusNumber' | 'approved' | 'aocNumber' | 'approvalDate' | 'expirationDate'>>, serviceAreas?: Maybe<Array<Maybe<{ aircraftCategory?: Maybe<Pick<OperatorAircraftCategory, 'aircraftCategoryId' | 'name'>>, regions?: Maybe<Array<Maybe<Pick<Region, 'acronym' | 'name' | 'id' | 'description'>>>> }>>>, documents?: Maybe<Array<Maybe<Pick<OperatorDocument, 'fileName' | 'url' | 'status' | 'docTypeId' | 'operatorDocumentId'>>>>, contact?: Maybe<Pick<Contact, 'firstName' | 'lastName' | 'email' | 'title' | 'phoneNumber' | 'quoteRequestEmail'>>, aircraftCategories?: Maybe<Array<Maybe<Pick<AircraftCategory, 'aircraftCategoryId' | 'name'>>>> }
  )> };

export type ListUsersQueryVariables = Exact<{
  filter?: Maybe<UserFilterInput>;
  pagination?: Maybe<PaginationInput>;
  sorting?: Maybe<UserSortInput>;
}>;


export type ListUsersQuery = { listUsers: { users: Array<Pick<User, 'firstName' | 'lastName' | 'emailAddress' | 'phoneNumber' | 'superuser' | 'active' | 'userId' | 'userTypeId' | 'organizationId' | 'activated' | 'eulaAccepted' | 'lastLoginDate'>>, pagination?: Maybe<Pick<Pagination, 'count'>> } };

export type ListAircraftsPublishQueryVariables = Exact<{
  filter?: Maybe<AircraftFilterInput>;
  from: Scalars['IsoDateTime'];
  to?: Maybe<Scalars['IsoDateTime']>;
  includeField: Scalars['Boolean'];
}>;


export type ListAircraftsPublishQuery = { listAircrafts: { aircrafts: Array<MakeOptional<Pick<Aircraft, 'modelName' | 'aircraftId' | 'maxPax' | 'tailNumber' | 'operatorApprovalExpirationDate' | 'deleted' | 'insuranceApproved' | 'completed' | 'insuranceRestrictionApprover' | 'reservationAvailability'>, 'reservationAvailability'>> } };

export type GetAirportPublishQueryVariables = Exact<{
  code?: Maybe<AirportFilterInput>;
}>;


export type GetAirportPublishQuery = { listAirports: { airports: Array<Maybe<Pick<Airport, 'name' | 'code' | 'timezoneName'>>> } };

export type CreateAvailableFlightMutationVariables = Exact<{
  input?: Maybe<CreateAvailableFlightInput>;
}>;


export type CreateAvailableFlightMutation = { createAvailableFlight?: Maybe<Pick<AvailableFlight, 'legacyLegId'>> };

export type GetFlightDetailsByLegIdQueryVariables = Exact<{
  legId?: Maybe<AvailableFlightFilterInput>;
}>;


export type GetFlightDetailsByLegIdQuery = { listAvailableFlights: { availableFlights: Array<(
      Pick<AvailableFlight, 'availableFlightId' | 'contractType' | 'status' | 'legacyLegId' | 'seatsOffered' | 'departureTime' | 'createdDepartureTime' | 'externalId' | 'arrivalTime' | 'eft' | 'priceType' | 'actualDepartureTime' | 'actualArrivalTime' | 'expirationOffset' | 'expirationTime'>
      & { aircraft?: Maybe<Pick<Aircraft, 'tailNumber' | 'modelName' | 'maxPax'>>, shuttlePricing?: Maybe<(
        Pick<ShuttlePricing, 'seatsOffered' | 'customerPrice' | 'operatorShare' | 'brokerShare' | 'brokerRate' | 'creditCardCost' | 'federalTaxCost' | 'segmentFeeCost' | 'seatPrice' | 'effective'>
        & { totalTaxes?: Maybe<Array<Maybe<Pick<TaxSnapshot, 'name' | 'amount'>>>>, user?: Maybe<Pick<User, 'firstName' | 'lastName'>> }
      )>, charterPricing?: Maybe<(
        Pick<CharterPricing, 'customerPrice' | 'brokerShare' | 'brokerRate' | 'operatorShare' | 'federalTaxCost' | 'segmentFeeCost' | 'flightPrice' | 'effective' | 'repositioningPrice' | 'densityFeePrice' | 'landingFeePrice'>
        & { totalTaxes?: Maybe<Array<Maybe<Pick<TaxSnapshot, 'name' | 'amount'>>>>, user: Pick<User, 'firstName' | 'lastName'> }
      )>, bookings?: Maybe<Array<(
        Pick<Booking, 'lastUpdated' | 'passengerCount'>
        & { price?: Maybe<Pick<ShuttlePricing, 'customerPrice'>> }
      )>>, passengers?: Maybe<Array<Pick<Passenger, 'legalName' | 'dateOfBirth' | 'weight'>>>, departureFbo?: Maybe<(
        Pick<Fbo, 'fboId' | 'phoneNumber' | 'name'>
        & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
      )>, arrivalFbo?: Maybe<(
        Pick<Fbo, 'fboId' | 'phoneNumber' | 'name'>
        & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
      )>, departureAirport?: Maybe<(
        Pick<Airport, 'code'>
        & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
      )>, arrivalAirport?: Maybe<(
        Pick<Airport, 'code'>
        & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
      )>, shuttlePriceHistory?: Maybe<Array<(
        Pick<ShuttlePricing, 'seatPrice' | 'effective' | 'seatsOffered'>
        & { user?: Maybe<Pick<User, 'firstName' | 'lastName'>> }
      )>>, charterPriceHistory?: Maybe<Array<(
        Pick<CharterPricing, 'effective' | 'flightPrice'>
        & { user: Pick<User, 'firstName' | 'lastName'> }
      )>>, repositioningItinerary?: Maybe<(
        Pick<RepositioningItinerary, 'flightRate' | 'landingFee' | 'flexibility'>
        & { segments?: Maybe<Array<Maybe<(
          Pick<ItinerarySegment, 'type' | 'eft'>
          & { departureAirport?: Maybe<Pick<Airport, 'code'>>, arrivalAirport?: Maybe<Pick<Airport, 'code'>>, flight?: Maybe<(
            Pick<AvailableFlight, 'actualDepartureTime' | 'departureTime' | 'createdDepartureTime' | 'actualArrivalTime' | 'legacyLegId' | 'status'>
            & { bookings?: Maybe<Array<(
              Pick<Booking, 'lastUpdated' | 'passengerCount'>
              & { price?: Maybe<Pick<ShuttlePricing, 'customerPrice'>> }
            )>>, passengers?: Maybe<Array<Pick<Passenger, 'legalName' | 'dateOfBirth' | 'weight'>>>, departureFbo?: Maybe<(
              Pick<Fbo, 'fboId' | 'phoneNumber' | 'name'>
              & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
            )>, arrivalFbo?: Maybe<(
              Pick<Fbo, 'fboId' | 'phoneNumber' | 'name'>
              & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
            )>, departureAirport?: Maybe<(
              Pick<Airport, 'code'>
              & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
            )>, arrivalAirport?: Maybe<(
              Pick<Airport, 'code'>
              & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
            )> }
          )> }
        )>>> }
      )> }
    )> } };

export type ValidateEftQueryVariables = Exact<{
  input: FlightEstimateInput;
}>;


export type ValidateEftQuery = { estimateFlight?: Maybe<Pick<FlightEstimate, 'minimumEft' | 'maximumEft'>> };

export type ValidatePriceQueryVariables = Exact<{
  input: FlightEstimateInput;
}>;


export type ValidatePriceQuery = { estimateFlight?: Maybe<{ shuttlePricing?: Maybe<{ absoluteMinimum: Pick<ShuttlePricing, 'seatPrice'>, absoluteMaximum: Pick<ShuttlePricing, 'seatPrice'> }>, charterPricing?: Maybe<{ absoluteMinimum: Pick<CharterPricing, 'flightPrice'>, absoluteMaximum: Pick<CharterPricing, 'flightPrice'> }> }> };

export type FilteredFlightsQueryVariables = Exact<{
  filter?: Maybe<AvailableFlightFilterInput>;
  sorting?: Maybe<AvailableFlightSortInput>;
  pagination?: Maybe<PaginationInput>;
}>;


export type FilteredFlightsQuery = { listAvailableFlights: { availableFlights: Array<(
      Pick<AvailableFlight, 'availableFlightId' | 'contractType' | 'status' | 'legacyLegId' | 'seatsOffered' | 'departureTime' | 'createdDepartureTime' | 'externalId' | 'arrivalTime' | 'actualDepartureTime' | 'creationTime'>
      & { aircraft?: Maybe<Pick<Aircraft, 'tailNumber' | 'modelName'>>, shuttlePricing?: Maybe<Pick<ShuttlePricing, 'seatPrice'>>, charterPricing?: Maybe<Pick<CharterPricing, 'flightPrice' | 'repositioningPrice' | 'densityFeePrice' | 'landingFeePrice'>>, passengers?: Maybe<Array<Pick<Passenger, 'weight'>>>, departureFbo?: Maybe<Pick<Fbo, 'fboId' | 'name'>>, arrivalFbo?: Maybe<Pick<Fbo, 'fboId' | 'name'>>, departureAirport?: Maybe<Pick<Airport, 'code'>>, arrivalAirport?: Maybe<Pick<Airport, 'code'>>, repositioningItinerary?: Maybe<(
        Pick<RepositioningItinerary, 'flexibility'>
        & { segments?: Maybe<Array<Maybe<(
          Pick<ItinerarySegment, 'type'>
          & { departureAirport?: Maybe<Pick<Airport, 'code'>>, arrivalAirport?: Maybe<Pick<Airport, 'code'>>, flight?: Maybe<(
            Pick<AvailableFlight, 'actualDepartureTime' | 'departureTime' | 'createdDepartureTime' | 'status'>
            & { passengers?: Maybe<Array<Pick<Passenger, 'weight'>>>, departureAirport?: Maybe<(
              Pick<Airport, 'code'>
              & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
            )>, arrivalAirport?: Maybe<(
              Pick<Airport, 'code'>
              & { address?: Maybe<Pick<Address, 'line1' | 'line2' | 'city' | 'state' | 'country'>> }
            )> }
          )> }
        )>>> }
      )> }
    )>, pagination?: Maybe<Pick<Pagination, 'count'>> } };

export type GetAutoCompleteOptionsQueryVariables = Exact<{
  filter?: Maybe<AvailableFlightFilterInput>;
}>;


export type GetAutoCompleteOptionsQuery = { listAvailableFlights: { availableFlights: Array<(
      Pick<AvailableFlight, 'externalId'>
      & { aircraft?: Maybe<Pick<Aircraft, 'tailNumber'>> }
    )> } };

export type UpdateFlightMutationVariables = Exact<{
  input: UpdateAvailableFlightInput;
}>;


export type UpdateFlightMutation = { updateAvailableFlight: Pick<AvailableFlight, 'availableFlightId'> };

export type GetShuttlePriceDetailsQueryVariables = Exact<{
  input: PriceDetailsInput;
}>;


export type GetShuttlePriceDetailsQuery = { calculateSharedCharterPrice?: Maybe<(
    Pick<ShuttlePricing, 'permitted' | 'customerPrice' | 'operatorShare' | 'brokerShare' | 'brokerRate' | 'creditCardCost' | 'federalTaxCost' | 'segmentFeeCost' | 'seatPrice'>
    & { totalTaxes?: Maybe<Array<Maybe<Pick<TaxSnapshot, 'name' | 'amount'>>>> }
  )> };

export type GetCharterPriceDetailsQueryVariables = Exact<{
  input: PriceDetailsInput;
}>;


export type GetCharterPriceDetailsQuery = { calculatePrivateCharterPrice?: Maybe<(
    Pick<CharterPricing, 'customerPrice' | 'operatorShare' | 'brokerShare' | 'brokerRate' | 'federalTaxCost' | 'segmentFeeCost' | 'flightPrice'>
    & { totalTaxes?: Maybe<Array<Maybe<Pick<TaxSnapshot, 'name' | 'amount'>>>> }
  )> };

export type GetAirportSharedQueryVariables = Exact<{
  filter?: Maybe<AirportFilterInput>;
}>;


export type GetAirportSharedQuery = { listAirports: { airports: Array<Maybe<(
      Pick<Airport, 'code' | 'name' | 'timezoneName'>
      & { address?: Maybe<Pick<Address, 'city'>> }
    )>> } };
