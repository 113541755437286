import { formatDate } from '@angular/common';

export class Util {
  static orderStatusCssClasses = [
    { id: 1, cssClass: 'text-black' },
    { id: 2, cssClass: 'text-danger' },
    { id: 3, cssClass: 'text-master-light' },
    { id: 4, cssClass: 'text-warning-dark' },
    { id: 5, cssClass: 'text-complete' },
    { id: 6, cssClass: 'text-warning' },
    { id: 7, cssClass: 'text-success' },
    { id: 8, cssClass: 'bg-danger-light' },
    { id: 9, cssClass: 'text-danger' }
  ];

  static nvlToNumber(value: any): null | number {
    if (value == null) {
      return null;
    } else {
      return Number(value);
    }
  }

  static isDateLessThan24hrs(date: Date) {
    const today = new Date();
    const nextDay = new Date().setDate(today.getDate() + 1);

    return (new Date(date) <= new Date(nextDay));
  }

  static formatDateExt(value: Date, format = 'EEE MMM dd, YY'): string {
    return formatDate(value, format, 'en-US');
  }
}
