import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import * as actions from '../actions/airport';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as airportContracts from '../services/airport/contracts';

@Injectable()
export class AirportEffects {

  constructor(private store$: Store<any>,
              private actions$: Actions,
              @Inject(airportContracts.SERVICE_TOKEN)
              private airportService: airportContracts.IAirportService) {
  }

  getAirportList$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<actions.GetAirportListAction>(actions.GET_AIRPORT_LIST),
      switchMap((action) => {
        return this.airportService.getAirportList(action.payload)
          .pipe(
            map(svcResp => {
              return new actions.GetAirportListSuccessAction(svcResp);
            }),
            catchError(() => of(new actions.GetAirportListFailAction()))
          );
      })
    ));
}
