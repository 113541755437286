import { User } from '../../domain/models';
import { Action } from '@ngrx/store';

export const LOAD_USER_LIST = '[Operator Profile] Load User List';
export const LOAD_USER_LIST_SUCCESS = '[Operator Profile] Load User List Success';
export const LOAD_USER_LIST_FAIL = '[Operator Profile] Load User List Fail';

export const SAVE_USER = '[Operator Profile User] Save';
export const SAVE_USER_SUCCESS = '[Operator Profile User] Save Success';
export const SAVE_USER_FAIL = '[Operator Profile User] Save Fail';

export const SYNC_USER = '[Operator Profile User] Sync';
export const SYNC_USER_SUCCESS = '[Operator Profile User] Sync Success';
export const SYNC_USER_FAIL = '[Operator Profile User] Sync Fail';

export const CHANGE_PAGE_ACTION = '[Operator Profile User] Change page action';

export class LoadUserListSuccessPayload {
  entities: Array<User>;
  total: number;
}

export class LoadUserList implements Action {
  readonly type = LOAD_USER_LIST;

}

export class LoadUserListSuccess implements Action {
  readonly type = LOAD_USER_LIST_SUCCESS;

  constructor(public payload: LoadUserListSuccessPayload) {
  }
}

export class LoadUserListFail implements Action {
  readonly type = LOAD_USER_LIST_FAIL;
}

export class SaveUserPayload {
  constructor(public item: User) {}
}

export class SaveUserAction implements Action {
  readonly type = SAVE_USER;

  constructor(public payload: SaveUserPayload) {}
}

export class SaveUserSuccessAction implements Action {
  readonly type = SAVE_USER_SUCCESS;
}

export class SaveUserFailAction implements Action {
  readonly type = SAVE_USER_FAIL;
}

export class SyncUserAction implements Action {
  readonly type = SYNC_USER;

  constructor(public payload: number) {
  }
}

export class SyncUserFailAction implements Action {
  readonly type = SYNC_USER_SUCCESS;
}

export class SyncUserSuccessAction implements Action {
  readonly type = SYNC_USER_FAIL;
}

export class ChangePageUserListAction implements Action {
  readonly type = CHANGE_PAGE_ACTION;

  constructor(public payload: { skip: number, take: number }) {
  }
}

export type Actions =
    SaveUserAction
  | SaveUserSuccessAction
  | SaveUserFailAction
  | LoadUserList
  | LoadUserListSuccess
  | LoadUserListFail
  | SyncUserAction
  | SyncUserSuccessAction
  | SyncUserFailAction
  | ChangePageUserListAction;
