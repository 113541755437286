<div class="container-fixed-header">
  <ul class="list-inline">
    <li *ngIf="(viewPermissions$ | async)?.fulfillFlights">
      <a routerLink="/fulfill-flights"
         href="javascript:void(0)">Fulfill Flights</a></li>
    <li *ngIf="(viewPermissions$ | async)?.publishedFlights">
      <a routerLink="/flights/publish"
         href="javascript:void(0)">Publish Flights</a></li>
  </ul>
</div>
<div class="container-xs-height full-height">
  <div class="row-xs-height">
    <div class="col-xs-height col-middle">
      <div class="error-container text-center">
        <h4 class="semi-bold">An error occurred and your request couldn't be completed. Please try again.</h4>
      </div>
    </div>
  </div>
</div>
