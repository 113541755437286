import { InjectionToken } from '@angular/core';
import { AnalyticsEvent } from '../../models';

export interface IAnalyticsService {
  logEvent(event: AnalyticsEvent): void;

  logUser(event: any): void;

  incrementProp(propertyName: string, propertyValue: number): void;
}

export const ANALYTICS_SERVICE_TOKEN = new InjectionToken('Shared.AnalyticsService');
