import * as actions from '../actions/user';
import * as models from '../models';

export interface State {
  user: models.User;
  account: models.UserAccount;
}

const initialState: State = {
  user: null,
  account: null
};

export function reducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_SUCCESS: {
      const user = action.payload.user;
      const account = action.payload.account;
      return  {
        ...state,
        user,
        account
      };
    }
    case actions.CHANGE_ACCOUNT: {
      const account = action.payload.account;
      return {
        ...state,
        account
      };
    }
    default:
      return state;
  }
}

export const getUser = (state: State) => state.user;
export const getUserAccount = (state: State) => state.account;
export const getUserOrgLegalName = (state: State) => state.account.operator?.legalName;
export const getUserPermissions = (state: any) => state.user.viewPermissions;
export const getUserPermissionsFulfillFlights = (state: any) => {
  return state.user.viewPermissions.fulfillFlights;
};
export const getUserPermissionsPublishedFlights = (state: any) => {
  return state.user.viewPermissions.publishedFlights;
};
