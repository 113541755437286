import { Injectable } from '@angular/core';
import { AnalyticsEvent } from '../../models';
import { IAnalyticsService } from './contracts';

const windowScope = window as any;

@Injectable()
export class AnalyticsService implements IAnalyticsService {
  logEvent(event: AnalyticsEvent): void {
    if (!event.properties) {
      event.properties = {};
    }
    windowScope.mixpanel.track(event.eventName, {
      hostname: location.origin,
      path: location.pathname,
      query: location.search,
      screen: this.getScreenName(location.pathname),
      ...event.properties
    });
  }

  logUser(userEvent: { userId: number }): void {
    windowScope.mixpanel.identify(userEvent.userId);
    windowScope.mixpanel.people.set({...userEvent});
  }

  incrementProp(propertyName: string, propertyValue: number): void {
    windowScope.mixpanel.people.increment(propertyName, propertyValue);
  }

  private getScreenName(path: string): string {
    const segments = path.split('/');
    switch (segments[1]) {
      case('flights'):
        if (segments[2] && segments[2] === 'publish') {
          return 'publish';
        } else {
          return 'manage';
        }
      case('fulfill-flights'):
        return 'fulfill';
      case('profile'):
        return 'profile';
      case('fleet'):
        return 'fleet';
      case(''):
        return 'xo';
      default:
        return '';
    }
  }
}
