import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse
} from '@angular/common/http';
import { EMPTY, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import * as configServiceContracts from '../../../core/services/config/contracts';
import { ConfigKeysEnum } from '../../../core/services/config/contracts';
import { IUserService, USER_SERVICE_TOKEN } from '../../../core/services';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(@Inject(configServiceContracts.SERVICE_TOKEN) private configService: configServiceContracts.IConfigService,
              @Inject(USER_SERVICE_TOKEN) private userService: IUserService) {
  }

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let modified = httpRequest;
    if (!httpRequest.headers.get('No-Authorization')) {
      modified = httpRequest.clone({ setHeaders: { 'Authorization': `Bearer ${this.configService.get(ConfigKeysEnum.AUTHORIZATION_TOKEN)}` } });
    }
    return next.handle(modified).pipe(map(event => event), catchError((err: HttpErrorResponse) => {
      if (err.status === 401) {
        location.href = `${this.configService.get(ConfigKeysEnum.IDENTITY_PROVIDER_URL)}/login/?application=OpertorPortal&tcs=${location.origin}/login&return_url=${encodeURIComponent(location.href)}`;
        this.userService.signOut({});
        return EMPTY;
      } else {
        return throwError(err);
      }
    }));
  }
}
