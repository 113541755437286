import * as fromOperator from './operator';
import { ActionReducer, ActionReducerMap, combineReducers, createSelector } from '@ngrx/store';
import * as fromUsers from './users';
import * as fromCountries from './country-collection-list';

export interface State {
  operator: fromOperator.State;
  users: fromUsers.State;
  countries: fromCountries.State;
}

const reducers: ActionReducerMap<State> = {
  operator: fromOperator.reducer,
  users: fromUsers.reducer,
  countries: fromCountries.reducer
};

const combinedReducers: ActionReducer<State> = combineReducers(reducers);

export function reducer(state: any, action: any) {
  return combinedReducers(state, action);
}

export const getOperatorState = state => state.profile.operator;
export const getOperatorInfo = createSelector(getOperatorState, fromOperator.getOperatorInfo);
export const getOperatorInfoLoading = createSelector(getOperatorState, fromOperator.getIsOperatorInfoLoading);
export const getOperatorInfoLoaded = createSelector(getOperatorState, fromOperator.getIsOperatorInfoLoaded);
export const getRegions = createSelector(getOperatorState, fromOperator.getRegions);
export const getIsRegionsLoading = createSelector(getOperatorState, fromOperator.getIsRegionsLoading);
export const getIsRegionsLoaded = createSelector(getOperatorState, fromOperator.getIsRegionsLoaded);

export const getUsersState = state => state.profile.users;
export const getOperatorUserList = createSelector(getUsersState, fromUsers.getUserList);
export const getOperatorUserListLoaded = createSelector(getUsersState, fromUsers.getIsUserListLoaded);
export const getOperatorUserListLoading = createSelector(getUsersState, fromUsers.getIsUserListLoading);
export const getUserSaveSuccessStatus = createSelector(getUsersState, fromUsers.getSaveSuccessStatus);
export const getOperatorUserListPerPage = createSelector(getUsersState, fromUsers.getItemsPerPage);
export const getOperatorUserListTotalCount = createSelector(getUsersState, fromUsers.getItemsTotalCount);
export const getOperatorUserListSkip = createSelector(getUsersState, fromUsers.getSkip);

export const getCountriesState = state => state.profile.countries;
export const getCountriesCollection = createSelector(getCountriesState, fromCountries.getCountriesCollection);
export const getCountriesCollectionLoaded = createSelector(getCountriesState, fromCountries.getIsLoaded);
export const getCountriesCollectionLoading = createSelector(getCountriesState, fromCountries.getIsLoading);