import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Event as RouterEvent, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router
} from '@angular/router';
import { Store, select } from '@ngrx/store';

import * as sharedTypes from '../../../shared/types';
import * as layoutActions from '../../actions/layout';
import * as layoutReducers from '../../reducers/layout';
import * as reducers from '../../../core/reducers';
import * as models from '../../models';

import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'shared-wireframe-root',
  templateUrl: './wireframe.component.html',
  styleUrls: ['./wireframe.component.scss']
})
export class WireframeComponent implements OnDestroy, OnInit {
  browserNowSupported$: Observable<boolean>;
  debugSubscription: Subscription;
  environment = environment.environment;
  errorIsVisible$: Observable<boolean>;
  isDebug$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  version$: Observable<string>;

  LayoutType = models.LayoutType;
  layoutType = models.LayoutType.Simple;
  layoutType$: Observable<models.LayoutType>;

  private notificationService: sharedTypes.INotificationService;
  private layoutStateSubscription: Subscription;

  constructor(
    public store$: Store<any>,
    private router: Router) {

    this.browserNowSupported$ = this.store$.pipe(
      select(reducers.getBrowserSupported),
      map(supported => supported === false));
    this.isDebug$ = this.store$.pipe(select(reducers.getSystemIsDebug));
    this.layoutType$ = this.store$.pipe(select(reducers.getLayoutType));
    this.version$ = this.store$.pipe(select(reducers.getSystemVersion));
   
    router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event);
    });

    this.layoutStateSubscription = this.store$
      .pipe(select(reducers.getLayoutState))
      .subscribe((layoutState: layoutReducers.State) => {
        this.layoutType = layoutState.layoutType;
      });
  }

  
  ngOnDestroy(): void {
    if (this.debugSubscription) {
      this.debugSubscription.unsubscribe();
    }

    if (this.layoutStateSubscription) {
      this.layoutStateSubscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.layoutStateSubscription = this.store$
      .pipe(select(reducers.getLayoutState))
      .subscribe((layoutState: layoutReducers.State) => {
        this.layoutType = layoutState.layoutType;
      });
  }

  private navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.store$.dispatch(new layoutActions.ShowSpinnerAction());
    }

    if (event instanceof NavigationEnd) {
      this.store$.dispatch(new layoutActions.HideSpinnerAction());
    }

    if (event instanceof NavigationCancel) {
      this.store$.dispatch(new layoutActions.HideSpinnerAction());
    }

    if (event instanceof NavigationError) {
      this.store$.dispatch(new layoutActions.HideSpinnerAction());
    }
  }
}
