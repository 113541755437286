import { CookieService } from 'ngx-cookie';
import { Injectable } from '@angular/core';

import { IConfigService, ConfigKeysEnum } from './contracts';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ConfigService implements IConfigService {
  private config: Object = {};

  constructor(private cookieService: CookieService) {
  }

  get(key: string): string {
    return this.config[key] || '';
  }

  load(): Promise<any> {
    this.config[ConfigKeysEnum.AUTHORIZATION_TOKEN] = this.cookieService.get('token');
    this.config[ConfigKeysEnum.OPERATOR_API_ENDPOINT] = environment.operatorApiEndpoint;
    this.config[ConfigKeysEnum.SOURCING_SERVICE_ENDPOINT] = environment.sourcingServiceEndpoint;
    this.config[ConfigKeysEnum.AIRCRAFT_SERVICE_ENDPOINT] = environment.aircraftServiceEndpoint;
    this.config[ConfigKeysEnum.TEST_MODE] = environment.testMode;
    this.config[ConfigKeysEnum.JET_STUDIO_SITE_URL] = environment.jetStudioSiteUrl;
    this.config[ConfigKeysEnum.CONSOLE_SITE_URL] = environment.consoleSiteUrl;
    this.config[ConfigKeysEnum.IDENTITY_PROVIDER_URL] = environment.identityProviderUrl;
    this.config[ConfigKeysEnum.BOTTOM_PRICE_SERVICE_ENDPOINT] = environment.bottomPriceServiceEndpoint;
    this.config[ConfigKeysEnum.ROUTE_MANAGEMENT_SERVICE_ENDPOINT] = environment.routeManagementServiceEndpoint;

    return Promise.resolve();
  }
}
