import { PriceType } from './price-type.enum';
import { CharterPermissionsEnum, TaxSnapshot } from '../../../../shared/graphql-types';

export class PriceDetail {
  pricingStrategyId: number;
  price: number;
  creditCardFee: number;
  operatorShare: number;
  jetsmarterShare: number;
  internal: boolean;
  seatsCount: number;
  percentageOfSalesPerSeat: number;
  basePrice: number;
  type: PriceType;
  permitted: CharterPermissionsEnum;
  repositioningPrice?: number;
  densityFeePrice?: number;
  landingFeePrice?: number;
  totalTaxes: Array<TaxSnapshot>;
}
